<section class="container">

    <div class="d-flex align-items-center flex-wrap ml-4 mt-4">
        <h1 class="title ">Saalve Bag</h1> <span class="subTitle ml-2">Entregador Detalhe</span>
    </div>

    <!--Informações pessoais-->
    <div class="d-flex align-items-center justify-content-between flex-wrap ml-4 mt-4">
        <h4 class="subTitle">
            Informações Pessoais
            <small class="badge pt-2 pb-1 pl-2 pr-2"
                   [ngClass]="{'badge-success': modelUser.status === 'active',
                    'badge-danger': modelUser.status === 'fail'||modelUser.status === 'blocked',
                    'badge-info': modelUser.status === 'await',
                    'badge-warning': modelUser.status === 'pending'}">
                <b *ngIf="modelUser.status === 'active'">Aprovado</b>
                <b *ngIf="modelUser.status === 'fail'">Reprovado</b>
                <b *ngIf="modelUser.status === 'blocked'">Bloqueado</b>
                <b *ngIf="modelUser.status === 'await'">Aguardando Aprovação</b>
                <b *ngIf="modelUser.status === 'pending'">Pendente</b>
            </small>
        </h4>
        <a href="javascript:void('Motoristas');" [routerLink]="['/drivers/']" class="btn-primary back_button">VOLTAR</a>
    </div>
    <div class="card mt-3 bg-white mx-2 p-3">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12 col-lg-12">
                    <div class="card-box">
                        <div class="row">

                            <div class="col-lg-7 col-md-12 mb-4">
                                <div class="card-box widget-user">
                                    <div class="media">
                                        <div class="avatar-xxl mr-3">
                                            <img src="{{isNullOrUndefined(modelUser.photo) ? 'assets/images/no-photo-available.png' : modelUser.photo}}"
                                                 class="img-fluid img-driver rounded-circle" alt="user">
                                        </div>
                                        <div class="media-body overflow-hidden">
                                            <h5 class="mt-2 mb-1">{{modelUser.name}}</h5>
                                            <p class="text-muted mb-2 font-13 text-truncate"><strong>CPF:</strong> <span
                                                    class="ml-2">{{modelUser.document | mask: '000.000.000-00'}}</span>
                                            </p>
                                            <p class="text-muted mb-2 font-13 text-truncate"><strong>Telefone:</strong>
                                                <span class="ml-2">{{modelUser.phone | mask: '(00) 0000-0000||(00) 0 0000-0000'}}</span>
                                            </p>
                                            <p class="text-muted mb-2 font-13 text-truncate"><strong>E-mail:</strong>
                                                <span class="ml-2">{{modelUser.email}}</span></p>
                                            <hr/>
                                            <p class="text-muted font-13"><strong>Criado em (Data/Hora):</strong> <span
                                                    class="ml-2">{{modelUser.createdAt | date: 'dd/MM/yyyy HH:mm'}}</span>
                                            </p>
                                            <p class="text-muted font-13"><strong>Última atualização de
                                                posição:</strong><span
                                                    class="ml-2">{{modelUser.updatedAt | date: 'dd/MM/yyyy HH:mm'}}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="text-left col-lg-5 col-md-12">
                                <div class="card bg-quaternary p-2 mb-3">
                                    <h2 class="subTitle text-white mt-2 mb-2 pl-2">Avaliação</h2>
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12">
                                            <div class="card-box widget-user">
                                                <div class="text-center">
                                                    <h4 class="text-white">{{modelUser.rating}}</h4>
                                                    <p class="text-white mb-0">Nota</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-10 offset-md-1 col-sm-12 mb-2 mt-0">
                                            <hr class="bg-white"/>
                                        </div>
                                        <div class="col-md-12 col-sm-12">
                                            <div class="card-box widget-user">
                                                <div class="text-center">
                                                    <h4 class="text-white">{{handlerRatingCount(modelUser.ratingCount)}}</h4>
                                                    <p class="text-white">Qtde Avaliações</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

<!--                            <div class="text-left col-lg-4 col-md-12 mt-4">-->
<!--                                <div class="card-box project-box">-->
<!--                                    <h5 class="mt-0">Envio de SMS</h5>-->
<!--                                    <hr>-->
<!--                                    <ul class="list-inline">-->
<!--                                        <li class="list-inline-item mr-4">-->
<!--                                            <h4 class="mb-0">{{modelUser.sms}}</h4>-->
<!--                                            <p class="text-muted">Qtde Tentativas</p>-->
<!--                                        </li>-->
<!--                                        <li class="list-inline-item">-->
<!--                                            <h4 class="mb-0">{{modelUser.smsCode}}</h4>-->
<!--                                            <p class="text-muted">último código</p>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </div>-->
<!--                            </div>-->

                            <div class="text-left col-lg-4 col-md-12 mt-4">
                                <div class="card-box project-box">
                                    <h5 class="mt-0">Dados do Aparelho</h5>
                                    <hr>
                                    <ul class="list-inline">
                                        <li class="list-inline-item mr-4">
                                            <h4 class="mb-0">{{modelUser.platform}}</h4>
                                            <p class="text-muted">SO</p>
                                        </li>
                                        <li class="list-inline-item">
                                            <h4 class="mb-0">{{modelUser.device}}</h4>
                                            <p class="text-muted">Aparelho</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="text-left col-lg-4 col-md-12 mt-4">
                                <div class="card-box project-box">
                                    <h5 class="mt-0">Dados do Aplicativo</h5>
                                    <hr>
                                    <ul class="list-inline">
                                        <li class="list-inline-item mr-4">
                                            <h4 class="mb-0">{{modelUser.version}}</h4>
                                            <p class="text-muted">Versão</p>
                                        </li>
                                        <li class="list-inline-item">
                                            <h4 class="mb-0">{{modelUser.build}}</h4>
                                            <p class="text-muted">Compilação</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="py-4 px-2">
        <hr/>
    </div>

    <!--Documentos-->
    <div class="d-flex align-items-center flex-wrap ml-4 mt-0">
        <h4 class="subTitle">
            Documentos
            <!--<small class="badge badge-warning pt-2 pb-1 pl-2 pr-2"><b>Pendente</b></small>-->
        </h4>
    </div>
    <div class="card mt-3 bg-white mx-2 p-3">
        <div class="card-body">
            <div class="row" *ngIf="isNullOrUndefined(modelUser.documents)">
                <div class="col-md-12 col-lg-12">
                    <p class="pt-5 pb-5 text-center">Nenhum documento cadastrado.</p>
                </div>
            </div>

            <div class="row" *ngIf="!isNullOrUndefined(modelUser.documents)">
                <div class="col-xl-4 col-md-6 mb-3 d-none">
                    <div class="card">
                        <img class="card-img-top img-fluid document-img"
                             src="{{handlerImageDocument(modelUser.documents, 'license-check')}}" alt=""/>
                        <div class="card-body h-100-px d-flex justify-content-center align-items-center text-center">
                            <h5 class="card-title mb-0">Comprovante de endereço</h5>
                            <!--<a href="javascript:void('');" class="btn btn-sm btn-danger pt-2 pb-1 pl-2 pr-2">Remover
                                foto</a>-->
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-6 mb-3">
                    <div class="card">
                        <img class="card-img-top img-fluid document-img"
                             src="{{handlerImageDocument(modelUser.documents, 'license-driver')}}" alt=""/>
                        <div class="card-body h-100-px d-flex justify-content-center align-items-center text-center">
                            <h5 class="card-title mb-0">Habilitação</h5>
                            <!--<a href="javascript:void('');" class="btn btn-sm btn-info pt-2 pb-1 pl-2 pr-2">Enviar
                                foto</a>-->
                        </div>
                    </div>
                </div>
            <!--    <div class="col-xl-4 col-md-6 mb-3">
                    <div class="card">
                        <a href="{{handlerImageDocument(modelUser.documents, 'criminal-check')}}" target="_blank">
                            <img class="card-img-top img-fluid document-img" src="assets/images/pdf.png" alt=""/>
                        </a>
                        <div class="card-body h-100-px d-flex justify-content-center align-items-center text-center">
                            <h5 class="card-title mb-0">Antecedente criminal</h5>
                            &lt;!&ndash;<a href="javascript:void('');" class="btn btn-sm btn-info pt-2 pb-1 pl-2 pr-2">Enviar
                                foto</a>&ndash;&gt;
                        </div>
                    </div>
                </div>-->
                <div class="col-xl-4 col-md-6 mb-3">
                    <div class="card">
                        <img class="card-img-top img-fluid document-img"
                             src="{{handlerImageDocument(modelUser.documents, 'license-check')}}" alt=""/>
                        <div class="card-body h-100-px d-flex justify-content-center align-items-center text-center">
                            <h5 class="card-title mb-0">Selfie com documento</h5>
                            <!--<a href="javascript:void('');" class="btn btn-sm btn-info pt-2 pb-1 pl-2 pr-2">Enviar
                                foto</a>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="py-4 px-2">
        <hr/>
    </div>

    <!--Dados Bancarios-->
    <div class="d-flex align-items-center flex-wrap ml-4 mt-0">
        <h4 class="subTitle">
            Dados Bancários
            <!--<small class="badge badge-warning pt-2 pb-1 pl-2 pr-2"><b>Pendente</b></small>-->
        </h4>
    </div>
    <div class="card mt-3 bg-white mx-2 p-3">
        <div class="card-body">
            <div class="row" *ngIf="isNullOrUndefined(modelUser.bankAccount)">
                <div class="col-md-12 col-lg-12">
                    <p class="pt-5 pb-5 text-center">Nenhum dado bancário cadastrado.</p>
                </div>
            </div>
            <div class="row" *ngIf="!isNullOrUndefined(modelUser.bankAccount)">
                <div class="col-12 col-md-6 d-grid align-items-center">
                    <!--<span class="badge badge-success mb-4 pt-2 pb-1 px-2"
                          [ngClass]="{'badge-success': modelUser.status === 'approve',
                           'badge-danger': modelUser.status !== 'approve'}">
                           {{modelUser.status === 'approve' ? 'Aprovado' : 'Reprovado'}}
                    </span>-->
                    <div class="text-left">
                        <p class="text-muted mb-1"><strong>Nome completo:</strong> <span
                                class="ml-2">{{modelUser.bankAccount?.name}}</span>
                        </p>
                        <p class="text-muted mb-1"><strong>CPF/CNPJ:</strong>
                            <span class="ml-2">{{modelUser.bankAccount?.document | mask: '000.000.000-00'}}</span>
                        </p>
                        <p class="text-muted mb-1"><strong>Data Nascimento:</strong> <span
                                class="ml-2">{{modelUser.bankAccount?.birthday | date: 'dd/MM/yyyy'}}</span>
                        </p>
                        <p class="text-muted mb-1"><strong>Renda Mensal ou Receita:</strong> <span
                                class="ml-2">{{modelUser.bankAccount?.monthlyIncomeOrRevenue | currency: 'R$ '}}</span>
                        </p>
                    </div>
                    <hr>
                    <div class="text-left">
                        <h6 class="font-weight-bold text-primary">Juno</h6>
                        <p class="text-muted mb-1"><strong>Saldo:</strong> <span
                                class="ml-2">{{modelUser.balance | currency: 'R$ '}}</span>
                        </p>
                        <p class="text-muted mb-1"><strong>Saldo a Liberar:</strong>
                            <span class="ml-2">{{modelUser.withHeldBalance | currency: 'R$ '}}</span>
                        </p>
                        <p class="text-muted mb-1"><strong>Saldo Disponivel:</strong> <span
                                class="ml-2">{{modelUser.transferableBalance | currency: 'R$ '}}</span>
                        </p>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="widget-detail-2 text-right">
                        <h4 class="mt-0 mb-3">{{modelUser.bankAccount?.bank}}</h4>
                        <h3 class="font-weight-normal mb-1">C/C: {{modelUser.bankAccount?.account}}
                            - {{modelUser.bankAccount?.accountDigit}}</h3>
                        <p class="text-muted size-lg mb-3">Ag: {{modelUser.bankAccount?.agency}}</p>
                    </div>
                    <div class="text-left text-lg-right">
                        <p class="text-muted mb-1"><strong>Endereço:</strong>
                            <span class="ml-2">{{modelUser.bankAccount?.address}},
                                {{modelUser.bankAccount?.addressNumber}} - {{modelUser.bankAccount?.complement}}</span>
                        </p>
                        <p class="text-muted mb-1">
                            {{modelUser.bankAccount?.district}},
                            {{modelUser.bankAccount?.postalCode | mask: '00000-000'}} |
                            {{modelUser.bankAccount?.city}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="py-4 px-2">
        <hr/>
    </div>

    <!--Raio-->
    <div class="d-flex align-items-center flex-wrap ml-4 mt-0">
        <h4 class="subTitle">
            Taxa de Entrega
        </h4>
    </div>

    <div class="card mt-3 bg-white mx-2 mb-5 p-3">
        <div class="card-body">
            <div class="col-md-12 col-lg-12" *ngIf="isNullOrUndefined('dd')">
                <p class="pt-5 pb-5 text-center">Nenhuma taxa cadastrada.</p>
            </div>
            <div class="col-md-12 col-lg-12">
                <div class="row">
                    <div class="col-md-3">
                        <div class="input-material mb-0">
                            <input id="initial" class="form-control" type="number" [(ngModel)]="modelTax.from"
                                   min="0" oninput="this.value = Math.abs(this.value)" required/>
                            <label for="initial">Inicio</label>
                        </div>
                    </div>
                    <div class="col-md-1 my-3 my-md-0 px-0 d-flex align-items-center justify-content-center">
                        <div class=" text-center">à</div>
                    </div>
                    <div class="col-md-3">
                        <div class="input-material mb-0">
                            <input id="finish" class="form-control" type="number" [(ngModel)]="modelTax.to"
                                   min="0" oninput="this.value = Math.abs(this.value)" required/>
                            <label for="finish">Fim</label>
                        </div>
                    </div>
                    <div class="col-md-3 my-3 my-md-0">
                        <div class="input-material mb-0">
                            <input id="value" class="form-control" type="text" currencyMask
                                   [options]="{prefix: 'R$', thousands: '.', decimal: ',', align: 'left'}"
                                   [(ngModel)]="modelTax.value" required/>
                            <label for="value">Valor</label>
                        </div>
                    </div>
                    <div class="col-md-2 d-flex align-items-center">
                        <a href="javascript:void('');" class="btn btn-ico btn-primary mb-3 mb-xs-0"
                           (click)="actionCreateTax()">
                            Adicionar
                        </a>
                    </div>
                </div>
                <hr>

                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead class="text-muted">
                        <tr>
                            <th class="text-truncate">Inicio</th>
                            <th class="text-truncate">Fim</th>
                            <th class="text-truncate text-center">Valor</th>
                            <th class="text-truncate text-center w-10">Excluir</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="text-muted" *ngFor="let p of listTax; let i = index"
                            [ngClass]="{'bg-secondary': handlerParImpar(i)}">
                            <td class="text-truncate">{{p.from}} Km</td>
                            <td class="text-truncate">{{p.to}} km</td>
                            <td class="text-truncate text-center">{{p.value | currency :'BRL': true: '1.2-2': 'pt-BR'}}</td>
                            <td class="text-truncate text-center">
                                <a href="javascript:void('actionRemoveTax');"
                                   class="btn btn-ico btn-block btn-primary mb-3 mb-xs-0"
                                   (click)="actionRemoveTax(p.id)">
                                    <svg-icon class="svg ml-1" src="assets/svg/trash.svg"></svg-icon>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 mt-5 mb-4 d-flex" style="max-width: 588px; margin: auto;"
         *ngIf="modelUser.status === 'pending' || modelUser.status === 'pending-license' ||
         modelUser.status === 'pending-license-check' || modelUser.status === 'pending-criminal' ||
         modelUser.status === 'pending-bank'">
        <a href="javascript:void(0);" (click)="actionRemoveUser()"
           class="btn btn-danger col-12" type="button">Excluir Usuário</a>
    </div>
    <div class="col-12 mt-5 mb-4 row justify-content-around" style="max-width: 588px; margin: auto;"
         *ngIf="!(modelUser.status === 'pending' || modelUser.status === 'pending-license' ||
         modelUser.status === 'pending-license-check' || modelUser.status === 'pending-criminal' ||
         modelUser.status === 'pending-bank')">
        <a href="javascript:void(0);" (click)="handlerStatusDriver('active')"
           [ngClass]="{'col-12 ml-0': modelUser.status !== 'await'}"
           class="btn btn-success col-5 ml-2" *ngIf="modelUser.status !== 'active'" type="button">Aprovar Usuário</a>
        <a href="javascript:void(0);" (click)="handlerStatusDriver('fail')"
           [ngClass]="{'col-12 ml-0': modelUser.status !== 'await'}"
           class="btn btn-danger col-5 ml-2" *ngIf="modelUser.status === 'active' || modelUser.status === 'await'"
           type="button">Reprovar Usuário</a>
        <a href="javascript:void(0);" (click)="handlerStatusDriver('blocked')"
           [ngClass]="{'col-12 ml-0': modelUser.status !== 'await'}"
           class="btn mt-3 btn-danger col-5 ml-2"
           *ngIf="modelUser.status != 'blocked'"
           type="button">Bloquear Usuário</a>

    </div>

    <div class="py-4 px-2">
        <hr/>
    </div>

    <!--Veiculos-->
    <div class="d-flex align-items-center flex-wrap ml-4 mt-0">
        <h4 class="subTitle">
            Veículos
            <!--<small class="badge badge-warning pt-2 pb-1 pl-2 pr-2"><b>Pendente</b></small>-->
        </h4>
    </div>

    <div class="card mt-3 bg-white mx-2 p-3">
        <div class="row mx-0">

            <div class="col-md-12 col-lg-12" *ngIf="isNullOrUndefined(modelUser.vehicles)">
                <p class="pt-5 pb-5 text-center">Nenhum veículo cadastrado.</p>
            </div>

            <div class="col-md-6" *ngFor="let vehicle of modelUser.vehicles">
                <div class="card p-3">
                    <div class="card-box widget-user">
                        <div class="media align-items-center">
                            <div class="col-5 text-center">
                                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                    <ol class="carousel-indicators">
                                        <li data-target="#carouselExampleIndicators" *ngFor="let d of vehicle.documents"
                                            [attr.data-slide-to]="d.id"></li>
                                    </ol>
                                    <div class="carousel-inner">
                                        <div class="carousel-item active cursor-pointer"
                                             *ngFor="let d of vehicle.documents; let i = index"
                                             (click)="actionModalImages(vehicle.documents, d.extension)"
                                             [ngClass]="{'active': i == 0}">
                                            <img class="d-block w-100 object-fit-cover" height="250"
                                                 src="{{d.extension == '.jpg' ? d.path : 'assets/images/pdf.png'}}"
                                                 [attr.alt]="d.id">
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                                       data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button"
                                       data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                            <div class="media-body overflow-hidden col-7">
                                <h5 class="mt-0 mb-1">{{vehicle.model}}</h5>
                                <p class="mb-1">
                                    <small class="badge mb-2 pt-2 pb-1 pl-2 pr-2"
                                           [ngClass]="{'badge-success': vehicle.status === 'active',
                                           'badge-danger': vehicle.status === 'fail',
                                           'badge-warning': vehicle.status === 'pending'}">
                                        <b *ngIf="vehicle.status === 'active'">Aprovado</b>
                                        <b *ngIf="vehicle.status === 'fail'">Reprovado</b>
                                        <b *ngIf="vehicle.status === 'pending'">Pendente</b>
                                    </small>
                                </p>
                                <p class="text-muted mb-2 font-13 text-truncate">{{vehicle.color}}
                                    | {{vehicle.board}}</p>
                                <p class="text-muted mb-2 font-13 text-truncate">{{vehicle.year}}</p>

                                <div class="col-12 p-0 my-2">
                                    <a href="javascript:void(0);" *ngIf="vehicle.status !== 'active'"
                                       (click)="handlerStatusVehicle(vehicle, 'active')"
                                       class="btn btn-block btn-success" type="button">Aprovar Veículo</a>
                                    <a href="javascript:void(0);" (click)="handlerStatusVehicle(vehicle, 'fail')"
                                       *ngIf="vehicle.status === 'active' || vehicle.status === 'pending'"
                                       class="btn btn-block btn-danger" type="button">Reprovar Veículo</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--<div class="col-lg-4 col-md-6">
                <div class="card-box widget-user">
                    <div class="media">
                        <div class="avatar-lg mr-3">
                            <img src="assets/images/no-photo-available.png" class="img-fluid rounded-circle"
                                 alt="user">
                        </div>
                        <div class="media-body overflow-hidden">
                            <h5 class="mt-0 mb-1">CG 150</h5>
                            <p class="mb-1"><small
                                    class="badge badge-warning mb-2 pt-2 pb-1 pl-2 pr-2"><b>Pendente</b></small></p>
                            <p class="text-muted mb-2 font-13 text-truncate">Prata | DUK-2021</p>
                            <p class="text-muted mb-2 font-13 text-truncate">2020/2021</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="card-box widget-user">
                    <div class="media">
                        <div class="avatar-lg mr-3">
                            <img src="assets/images/no-photo-available.png" class="img-fluid rounded-circle"
                                 alt="user">
                        </div>
                        <div class="media-body overflow-hidden">
                            <h5 class="mt-0 mb-1">CG 150</h5>
                            <p class="mb-1"><small
                                    class="badge badge-warning mb-2 pt-2 pb-1 pl-2 pr-2"><b>Pendente</b></small></p>
                            <p class="text-muted mb-2 font-13 text-truncate">Prata | DUK-2021</p>
                            <p class="text-muted mb-2 font-13 text-truncate">2020/2021</p>
                        </div>
                    </div>
                </div>
            </div>-->
        </div>
    </div>

</section>

<!--Modal-->
<div class="modal-custom modal" id="modalApprove" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalApproveLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header bg-primary text-white">
                <h4 *ngIf="updateStatusType == 'vehicle'">REPROVAÇÃO DE VEÍCULO</h4>
                <h4 *ngIf="updateStatusType !== 'vehicle'">REPROVAÇÃO DE USUÁRIO</h4>
                <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row mt-5">
                    <!--<div class="col-md-12 col-lg-8 offset-lg-2">
                        <div class="register__checkbox">
                            <div class="custom-control custom-switch" style="transform: scale(1.5);">
                                <input type="checkbox" id="terms1" class="custom-control-input"/>
                                <label for="terms1" class="custom-control-label">Dados do usuário</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-8 offset-lg-2">
                        <div class="register__checkbox">
                            <div class="custom-control custom-switch" style="transform: scale(1.5);">
                                <input type="checkbox" id="terms2" class="custom-control-input"/>
                                <label for="terms2" class="custom-control-label">Documentos</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-8 offset-lg-2">
                        <div class="register__checkbox">
                            <div class="custom-control custom-switch" style="transform: scale(1.5);">
                                <input type="checkbox" id="terms3" class="custom-control-input"/>
                                <label for="terms3" class="custom-control-label">Veículos</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-8 offset-lg-2">
                        <div class="register__checkbox">
                            <div class="custom-control custom-switch" style="transform: scale(1.5);">
                                <input type="checkbox" id="terms4" class="custom-control-input"/>
                                <label for="terms4" class="custom-control-label">Dados bancários</label>
                            </div>
                        </div>
                    </div>-->
                    <div class="col-md-12 col-lg-12">
                        <div class="input-material">
                            <textarea id="comments" class="form-control" rows="3"
                                      [(ngModel)]="modelUpdateStatus.justification"></textarea>
                            <label for="comments">Justificação:</label>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <div class="mx-auto">
                            <a href="javascript:void(0)"
                               class="btn btn-block btn-success mt-4 px-5" type="button" (click)="handlerStatus()">Enviar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Modal Images-->
<div class="modal-custom modal" id="modalImages" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalApproveLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header bg-primary text-white">
                <h3>Images</h3>
                <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" (click)="modalImages = []">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div id="carouselModal" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#carouselModal" *ngFor="let d of modalImages" [attr.data-slide-to]="d.id"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item active" *ngFor="let d of modalImages; let i = index"
                             [ngClass]="{'active': i == 0}">
                            <img class="d-block w-100 object-fit-cover" height="500"
                                 (click)="handlerRedirectPdf(d.path, d.extension)"
                                 src="{{d.extension == '.jpg' ? d.path : 'assets/images/pdf.png'}}" [attr.alt]="d.id">
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselModal" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselModal" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>



