<header class="header d-flex justify-content-between align-items-center">
  <span class="d-flex d-xl-none d-flex justify-content-center align-items-center" (click)="actionNavToggle()">
    <div class="hamburger d-flex d-xl-none">
      <span class="bar"></span>
    </div>
  </span>
  <img src="assets/images/logo_saalve.png" alt="logo" class="logo-default" style="height: 40px"/>
  <div class="d-none d-lg-flex">
    <a href="#" class="d-none d-xl-flex align-items-center justify-content-center header__user dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <span>{{'headerSalutation' | translate}}, <strong>{{user.name}}</strong></span>
      <!--<img width="30" height="30" style="object-fit: cover" src="{{!isNullOrUndefined(user.photo) ? user.photo : 'https://via.placeholder.com/30'}}">-->
    </a>

    <div class="dropdown-menu dropdown-menu-right mt-3">
      <button class="dropdown-item" type="button" (click)="handlerChangePassword()">Alterar Senha</button>
    </div>

    <div class="header__btn">
      <app-notification></app-notification>
    </div>
    <!--<div class="header__btn">
      <font class="header_font pl-3 pr-3">Idioma:</font>
      <a class="header_font" href="javascript:void('');" [ngClass]="{'active': translate.currentLang === 'pt'}" (click)="switchLanguage('pt')">PT</a>
      <span class="pipe-divisor"></span>
      <a class="header_font" href="javascript:void('');" [ngClass]="{'active': translate.currentLang === 'es'}" (click)="switchLanguage('es')">ES</a>
    </div>-->
    <div class="header__btn">
      <a href="javascript:void('sair');" (click)="onLogout()" class="d-none d-lg-flex header__user__outbtn">
        <strong style="padding: 3px 15px 3px 3px;">Sair</strong>
      </a>
    </div>
  </div>
</header>
