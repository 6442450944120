import {Component, OnInit} from '@angular/core';
import {Term} from '../../model/term';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {TermService} from '../../service/term.service';

declare var $: any;

@Component({
    selector: 'app-term',
    templateUrl: './term.component.html',
    styleUrls: ['./term.component.scss']
})
export class TermComponent extends BaseComponent implements OnInit {

    model: Term = new Term();
    editorConfig = {
        editable: true,
        spellcheck: true,
        height: 'auto',
        minHeight: '16rem',
        placeholder: '',
        translate: 'no',
    };

    listTerms: Term[] = [];

    request = {
        term: '',
        country: null,
        type: null,
    };
    config: any;
    labels: any = {
        previousLabel: ' ',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public termService: TermService) {
        super(router, loading, translate);
    }

    ngOnInit() {
        this.loading.hide();
        this.getList();
        this.config = {
            itemsPerPage: 7,
            currentPage: 1
        };
    }

    pageChanged(event) {
        this.config.currentPage = event;
    }

    getList() {
        this.loading.show();
        this.termService.get().subscribe({
            next: data => {
                this.listTerms = data as Term[];
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    onSave() {
        const arrValidateFields = [
            {value: this.model.type, text: 'Tipo*, '},
            {value: this.model.content, text: 'Email*.'},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        if (this.isNullOrUndefined(this.model.id)) {
            this.register();
        } else {
            this.update();
        }
    }

    register() {
        this.loading.show();
        this.termService.create(this.model).subscribe({
            next: data => {
                this.model = new Term();
                $('#register').modal('hide');
                this.getList();
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    update() {
        this.loading.show();
        this.termService.update(this.model).subscribe({
            next: data => {
                this.model = new Term();
                $('#register').modal('hide');
                this.getList();
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    onEdit(model) {
        this.model = Object.assign({}, model);
        $('#register').modal('show');
    }

    modalRegister() {
        $('#register').modal('show');
    }

    oncloseModal() {
        this.model = new Term();
        $('#register').modal('hide');
    }

    onRemove(id) {
        super.confirmMessage('Atenção',
            'Tem certeza que deseja remover este termo?', () => {
                this.loading.show();
                this.termService.delete(id).subscribe({
                    next: data => {
                        this.loading.hide();
                        this.getList();
                    }, error: (err) => super.onError(err)
                });
            });
    }
}
