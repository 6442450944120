export class validateText {
    static allUppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    static allLowercase = 'abcdefghijklmnopqrstuvwxyz';
    static allNumbers = '0123456789';
    // static allSpecials = '@#\$&?;:.,\\|/"!%';

    static validatePassword(text: string) {
        let contains = 0;
        if (text.length > 7) contains += 1;

        for (let i = 0; i < text.length; i++) {
            if (this.allUppercase.indexOf(text[i]) > -1) {
                contains += 1;
                break;
            }
        }

        for (let i = 0; i < text.length; i++) {
            if (this.allLowercase.indexOf(text[i]) > -1) {
                contains += 1;
                break;
            }
        }

        for (let i = 0; i < text.length; i++) {
            if (this.allNumbers.indexOf(text[i]) > -1) {
                contains += 1;
                break;
            }
        }

        // for (let i = 0; i < text.length; i++) {
        //   if (this.allSpecials.indexOf(text[i]) > -1) {
        //     contains += 1;
        //     break;
        //   }
        // }

        return contains == 4;
    }
}
