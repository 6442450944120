import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {PaymentService} from '../../service/payment.service';
import {FilterPayment} from '../../model/payment/filter-payment';
import {Payment} from '../../model/payment/payment';
import {environment} from '../../../environments/environment';
import {UserService} from '../../service/user.service';
import {User} from '../../model/user/user';

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-driver-payment',
    templateUrl: './driver-payment.component.html',
    styleUrls: ['./driver-payment.component.scss']
})
export class DriverPaymentComponent extends BaseComponent implements OnInit {

    modelFilter: FilterPayment = new FilterPayment();
    modelList: Payment[] = [];
    modelBalance: User = new User();
    listPayment = [];
    value = 0;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public paymentService: PaymentService,
                public userService: UserService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.setDatePicker();
        this.actionSearch();
    }

    actionSearch() {
        this.loading.show();
        this.paymentService.search(this.modelFilter).subscribe({
            next: data => {
                this.modelList = data as Payment[];
                this.actionBalance();
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    actionPayment() {
        if (this.isNullOrUndefined(this.listPayment)) {
            super.showMessage('Atenção', 'Selecione pelo menos uma entrega.', 'warning');
            return;
        }

        this.loading.show();
        this.paymentService.payment(this.listPayment).subscribe({
            next: data => {
                this.actionSearch();
                this.listPayment = [];
                this.value = 0;
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    setDatePicker() {
        const data = moment().subtract(15, 'days');
        this.modelFilter.start = data;
        this.modelFilter.end = moment();
        $('.js-daterangepicker').daterangepicker({
            showDropdowns: true,
            startDate: data,
            locale: {
                format: 'DD/MM/YYYY',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                daysOfWeek: [
                    'Dom',
                    'Seg',
                    'Ter',
                    'Qua',
                    'Qui',
                    'Sex',
                    'Sab'
                ],
                monthNames: [
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                ],
                firstDay: 1
            }
        }, (start, end, label) => {
            this.modelFilter.start = start.utcOffset(0, true).format();
            this.modelFilter.end = end.utcOffset(0, true).format();
        });
    }

    handlerSelectPayment(id) {
        if (this.isNullOrUndefined(id)) {
            if (this.isNullOrUndefined(this.listPayment)) {
                this.modelList.forEach(x => {
                    if (x.paymentStatus === 'pending') {
                        this.listPayment.push(x.id);
                    }
                });
            } else {
                this.listPayment = [];
            }
        } else {
            const filter = this.listPayment.filter(x => {
                return x === id;
            });
            if (this.isNullOrUndefined(filter)) {
                this.listPayment.push(id);
            } else {
                this.listPayment.splice(this.listPayment.indexOf(id), 1);
            }
        }
    }

    handlerValueSelect() {
        if (!this.isNullOrUndefined(this.listPayment)) {
            let value = 0;
            this.listPayment.forEach(x => {
                const filter = this.modelList.filter(y => y.id === x);
                value += filter[0].commission;
            });
            return value;
        }
        return 0;
    }

    handlerPaymentCheckedAll() {
        const filtered = this.modelList.filter(x => x.paymentStatus === 'pending');
        return this.listPayment.length === filtered.length;
    }

    handlerPaymentChecked(id) {
        const filtered = this.modelList.filter(x => x.id === id);
        const filter = this.listPayment.filter(x => x === id);
        if (filter[0] === filtered[0].id) {
            return filtered[0].paymentStatus === 'pending';
        }
        return false;
    }

    handlerPaymentDisabled(id) {
        const filtered = this.modelList.filter(x => x.id === id);
        return filtered[0].paymentStatus !== 'pending';
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;
        const start = moment(this.modelFilter.start).format('YYYY-MM-DD');
        const end = moment(this.modelFilter.end).format('YYYY-MM-DD');

        let url = `${environment.base_url}/payment/search/excel?token=${token}`;

        if (!super.isNullOrUndefined(this.modelFilter.name)) {
            url += ('&name=' + this.modelFilter.name);
        }

        if (!super.isNullOrUndefined(this.modelFilter.status)) {
            url += ('&status=' + this.modelFilter.status);
        }

        if (!super.isNullOrUndefined(start)) {
            url += ('&start=' + start);
        }

        if (!super.isNullOrUndefined(end)) {
            url += ('&end=' + end);
        }

        // console.log(encodeURI(url));

        window.open(encodeURI(url));
    }

    actionBalance() {
        this.loading.show();
        this.userService.getBalance().subscribe({
            next: data => {
                this.modelBalance = data as User;
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }
}
