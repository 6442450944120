import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';

@Injectable({
  providedIn: 'root'
})
export class MapsPlaceService extends ServiceBase {

  constructor(http: HttpClient) {
    super(http);
  }

  get(input) {
    const url = `${this.BASE_URL}/google-place/autocomplete?input=${input}`;
    return this.http.get(url);
  }

  getPlaceId(placeId) {
    const url = `${this.BASE_URL}/google-place/place/${placeId}`;
    return this.http.get(url);
  }

  listDrivers() {
    const url = `${this.BASE_URL}/route/list-drivers`;
    return this.http.post(url, {});
  }
}
