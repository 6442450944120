export class Tax {

  id: string;
  from: number;
  to: number;
  value: number;

  constructor() {
  }
}
