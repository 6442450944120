import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {User} from '../../model/user/user';
import {StatusUpdate} from '../../model/statusUpdate';
import {VehicleService} from '../../service/vehicle.service';
import {Tax} from '../../model/tax';

declare var $: any;

@Component({
    selector: 'app-drivers-detail',
    templateUrl: './drivers-detail.component.html',
    styleUrls: ['./drivers-detail.component.scss']
})
export class DriversDetailComponent extends BaseComponent implements OnInit, OnDestroy {

    modelUser: User = new User();

    modelUpdateStatus: StatusUpdate = new StatusUpdate();

    updateStatusType: string;

    modalImages = [];

    modelTax: Tax = new Tax();
    listTax: Tax[] = [];

    constructor(public router: Router,
                public route2: ActivatedRoute,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public userService: UserService,
                public vehicleService: VehicleService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.route2.params.subscribe(data => {
            if (!this.isNullOrUndefined(data.id)) {
                this.actionDetail(data.id);
            }
        });
    }

    ngOnDestroy(): void {
        const elModalRemoveApprove = $('#modalApprove');
        if (elModalRemoveApprove[0]) {
            elModalRemoveApprove.remove();
        }
        const elModalRemoveImg = $('#modalImages');
        if (elModalRemoveImg[0]) {
            elModalRemoveImg.remove();
        }
    }

    actionDetail(id) {
        this.loading.show();
        this.userService.getById(id).subscribe({
            next: data => {
                this.modelUser = data as User;
                this.actionTax();
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    handlerRatingCount(x) {
        if (!this.isNullOrUndefined(x)) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        }
        return 0;
    }

    handlerImageDocument(documents, type) {
        const filter = documents.filter(x => {
            return x.type === type;
        });
        if (!this.isNullOrUndefined(filter)) {
            return filter[0].path;
        }
        return 'assets/images/no-photo-available.png';
    }

    actionModalImages(documents, extension) {
        if (extension === '.jpg') {
            this.modalImages = documents;
            $('#modalImages').modal('show');
        } else {
            const filter = documents.filter(x => {
                return x.extension === extension;
            });
            this.handlerRedirectPdf(filter[0].path, '.pdf');
        }
    }

    handlerRedirectPdf(url, type) {
        if (type === '.pdf') {
            window.open(url, '_blank');
        }
    }

    handlerStatus() {
        if (this.updateStatusType === 'vehicle') {
            this.actionStatusUpdateVehicle();
        } else {
            this.actionStatusUpdateDriver();
        }
    }

    handlerStatusDriver(status) {
        this.modelUpdateStatus.id = this.modelUser.id;
        if (status === 'blocked') {
            super.confirmMessage('Atenção', 'Deseja realmente Bloquear este usuário?', () => {
                this.actionStatusBlockedDriver();
            });
        } else if (status === 'fail') {
            this.updateStatusType = 'driver';
            this.modelUpdateStatus.status = 'fail';
            $('#modalApprove').modal('show');
        } else {
            this.modelUpdateStatus.status = 'active';
            super.confirmMessage('Atenção', 'Deseja realmente Aprovar este usuário?', () => {
                this.actionStatusUpdateDriver();
            });
        }
    }

    actionStatusBlockedDriver(){
        this.loading.show();
        this.userService.blockedTax(this.modelUser.id).subscribe({
            next: data => {
                this.actionDetail(this.modelUser.id);
                // this.modelUpdateStatus = new StatusUpdate();
                super.showMessage('Sucesso', 'Usuário Bloqueado com Sucesso!', 'success');
                $('#modalApprove').modal('hide');
                this.loading.hide();
            }, error: err => super.onError(err)
        })
    }

    actionStatusUpdateDriver() {
        if (this.modelUpdateStatus.status !== 'active') {
            if (super.isNullOrUndefined(this.modelUpdateStatus.justification)) {
                this.showMessage('Atenção', `Preencha o campo <strong>Justificação</strong> corretamente.`, 'warning');
                return;
            }
        }

        this.loading.show();
        this.userService.statusUpdate(this.modelUpdateStatus).subscribe({
            next: data => {
                this.actionDetail(this.modelUser.id);
                this.modelUpdateStatus = new StatusUpdate();
                super.showMessage('Sucesso', 'Usuário atualizado com Sucesso!', 'success');
                $('#modalApprove').modal('hide');
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    handlerStatusVehicle(model, status) {
        this.modelUpdateStatus.id = model.id;
        if (status === 'fail') {
            this.updateStatusType = 'vehicle';
            this.modelUpdateStatus.status = 'fail';
            $('#modalApprove').modal('show');
        } else {
            this.modelUpdateStatus.status = 'active';
            super.confirmMessage('Atenção', 'Deseja realmente Aprovar este veículo?', () => {
                this.actionStatusUpdateVehicle();
            });
        }
    }

    actionStatusUpdateVehicle() {
        if (this.modelUpdateStatus.status !== 'active') {
            if (super.isNullOrUndefined(this.modelUpdateStatus.justification)) {
                this.showMessage('Atenção', `Preencha o campo <strong>Justificação</strong> corretamente.`, 'warning');
                return;
            }
        }

        this.loading.show();
        this.vehicleService.statusUpdate(this.modelUpdateStatus).subscribe({
            next: data => {
                this.modelUpdateStatus = new StatusUpdate();
                this.actionDetail(this.modelUser.id);
                super.showMessage('Sucesso', 'Veículo atualizado com Sucesso!', 'success');
                $('#modalApprove').modal('hide');
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    actionRemoveUser() {
        super.confirmMessage('Atenção', 'Deseja realmente escluir este usuário?', () => {
            this.loading.show();
            this.userService.delete(this.modelUser.id).subscribe({
                next: data => {
                    this.loading.hide();
                    super.showMessage('Sucesso', 'Usuário excluido com sucesso!', 'success');
                }, error: err => super.onError(err)
            });
        });
    }

    // Tax

    handlerParImpar(i) {
        return i % 2 !== 0;
    }

    actionTax() {
        this.loading.show();
        this.userService.getTax(this.modelUser.id).subscribe({
            next: data => {
                this.listTax = data as Tax[];
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    actionCreateTax() {
        const arrValidateFields = [
            {value: this.modelTax.from, text: 'Inicio*, '},
            {value: this.modelTax.to, text: ' Fim*, '},
            {value: this.modelTax.value, text: ' Valor*.'},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        const list = [];
        this.listTax.forEach(x => {
            list.push([x.from, x.to]);
        });

        if (!this.isNullOrUndefined(super.intersection([[this.modelTax.from, this.modelTax.to]], list))) {
            super.showMessage('Atenção', `Faixa selecionada está interseccionada em outra`,
                'warning');
            return;
        }

        this.loading.show();
        this.userService.createTax(this.modelUser.id, this.modelTax).subscribe({
            next: data => {
                this.actionDetail(this.modelUser.id);
                this.modelTax = new Tax();
                this.loading.hide();
                super.showMessage('Sucesso', 'Taxa cadastrada com sucesso!', 'success');
            }, error: err => super.onError(err)
        });
    }

    actionRemoveTax(taxId) {
        super.confirmMessage('Atenção', 'Deseja realmente excluir esta taxa?', () => {
            this.loading.show();
            this.userService.deleteTax(this.modelUser.id, taxId).subscribe({
                next: data => {
                    this.actionTax();
                    this.loading.hide();
                    super.showMessage('Sucesso', 'Taxa excluida com sucesso!', 'success');
                }, error: err => super.onError(err)
            });
        });
    }
}
