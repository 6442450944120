import {Component, OnInit} from '@angular/core';
import {Util} from '../../util/util';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {MapsPlaceService} from '../../service/maps-place.service';
import {Map} from '../../model/map/map';
import {UserService} from '../../service/user.service';

declare var $: any;

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent extends BaseComponent implements OnInit {

    modelMap: Map = new Map();
    selectedDriveId: string;
    filter: string;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public mapsService: MapsPlaceService,
                public userService: UserService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.getMapRoutes();
    }

    getMapRoutes() {
        this.loading.show();
        this.mapsService.listDrivers().subscribe({
            next: (data) => {
                this.modelMap = data as Map;
                this.loading.hide();

                if (!this.isNullOrUndefined(this.modelMap.store)) {
                    const center = Util.computeCentroidStore(this.modelMap.store);

                    // @ts-ignore
                    MapsG.initMap(center.lat, center.lng);

                    for (const s of this.modelMap.store) {
                        // @ts-ignore
                        MapsG.markation(s.lat, s.lng, s);
                    }
                    for (const d of this.modelMap.drive) {
                        // @ts-ignore
                        MapsG.markationDiver(d.lat, d.lng, d);
                    }
                }
            }, error: (error) => super.onError(error)
        });
    }

    selectedDriver(model) {
        this.selectedDriveId = model.id;
        // @ts-ignore
        MapsG.setZoom(model.lat, model.lng);
    }

    filterFunction(listArr) {
        const str = this.filter;
        if (str !== '' && str !== undefined) {
            return listArr.filter(
                i => ((i.name.toLowerCase().indexOf(str.toLowerCase()) >= 0))
                // (i.email.toLowerCase().indexOf(str.toLowerCase()) >= 0))
            );
        } else {
            return listArr;
        }
    }

    actionAvailability(model) {
        if (model.availability !== 'offline') {
            super.confirmMessage('Atenção', 'Deseja realmente alterar o status deste usuário?', () => {
                this.loading.show();
                this.userService.availableDriverStatus(model.id).subscribe({
                    next: data => {
                        this.getMapRoutes();
                        super.showMessage('Sucesso', 'Status alterado com sucesso!', 'success');
                        this.loading.hide();
                    }, error: err => super.onError(err)
                });
            });
        }
    }
}
