<section class="container">

    <div class="d-flex align-items-center ml-4 mt-4">
        <h1 class="title ">Saalve Bag<span class="subTitle ml-2"> Fale conosco</span></h1>
    </div>

    <div class="card mt-3 bg-white mx-2 p-3">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-12 col-lg-6 col-xl-3">
                    <div class="input-material">
                        <input id="period" class="form-control js-daterangepicker" type="text" required/>
                        <label for="period">Período</label>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-3">
                    <div class="input-material">
                        <input id="name" class="form-control" type="text" required [(ngModel)]="modelFilter.name"/>
                        <label for="name">Nome</label>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-3">
                    <div class="input-material">
                        <input id="email" class="form-control" type="text" required [(ngModel)]="modelFilter.email"/>
                        <label for="email">E-mail</label>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-3">
                    <div class="input-material">
                        <select id="unity" class="form-control" name="type" [(ngModel)]="modelFilter.status">
                            <option [ngValue]="''">Todos</option>
                            <option [ngValue]="'answered'">Respondido</option>
                            <option [ngValue]="'pending'">Aguardando</option>
                        </select>
                        <label for="unity" class="mb-0 mr-3">Status</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-sm-12 text-right">
                    <a href="javascript:void('');" class="btn btn-ico btn-sm btn-success mr-xs-4 mb-3 mb-xs-0" (click)="getExcel()">
                        <svg-icon class="svg" src="assets/svg/excel.svg"></svg-icon>
                        Exportar Excel
                    </a>
                    <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mb-3 mb-xs-0"
                       (click)="actionSearch()">
                        <svg-icon class="svg" src="assets/svg/search.svg"></svg-icon>
                        Buscar
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="card mt-3 mx-2 bg-white p-3">
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th class="align-middle w-10" scope="col">Data/Hora</th>
                    <th class="align-middle" scope="col">Nome</th>
                    <th class="align-middle" scope="col">E-mail</th>
                    <th class="align-middle" scope="col">Telefone</th>
                    <th class="align-middle" scope="col">Mensagem</th>
                    <th class="align-middle w-10" scope="col">Status</th>
                    <th class="align-middle w-10" scope="col">Editar</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(modelResult.results)">
                    <td colspan="7">
                        <p class="pt-5 pb-5 text-center">
                            Nenhum registro encontrado.
                        </p>
                    </td>
                </tr>
                <tr *ngFor="let r of modelResult.results |
                    paginate: {
                     itemsPerPage: modelResult.pageSize,
                     currentPage: modelResult.currentPage,
                     totalItems: modelResult.totalCount}">
                    <td>{{r.createdAt | date: 'dd/MM/yyyy HH:mm'}}</td>
                    <td>{{r.createdBy.name}}</td>
                    <td>{{r.createdBy.email}}</td>
                    <td>{{r.createdBy.phone}}</td>
                    <td>{{r.message}}</td>
                    <td>
                        <div class="badge badge-warning size-md" *ngIf="r.status === 'pending'">Aguardando</div>
                        <div class="badge badge-success size-md" *ngIf="r.status === 'answered'">Respondido</div>
                    </td>
                    <td class="text-center">
                        <a href="javascript:void('Detalhes');" class="btn btn-sm btn-primary"
                           (click)="toggleModalContactDetail(r)">
                            <svg-icon class="svg-secondary" src="assets/svg/eyes.svg"></svg-icon>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-center">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</section>

<!--Modal-->
<div class="modal-custom modal" id="modalContactDetail" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalContactDetail"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-0">
            <div class="modal-header bg-primary text-white">
                <h3>FALE CONOSCO</h3>
                <a href="javascript:void('Fechar');" class="close text-white" data-dismiss="modal"
                   aria-label="Close" (click)="toggleModalContactDetail({})">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row mt-1 text-right">
                    <div class="col-md-12 col-lg-10 offset-lg-1">
                        <div class="badge badge-warning size-md" *ngIf="model.status === 'pending'">Aguardando</div>
                        <div class="badge badge-success size-md" *ngIf="model.status === 'answered'">Respondido</div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-12 col-lg-5 offset-lg-1">
                        <p class="text-muted size-md"><strong>Nome:</strong><span
                                class="ml-2">{{model.createdBy?.name}}</span></p>
                        <p class="text-muted size-md"><strong>Email:</strong> <span
                                class="ml-2">{{model.createdBy?.email}}</span></p>
                    </div>

                    <div class="col-md-12 col-lg-6">
                        <p class="text-muted size-md"><strong>Data/Hora:</strong>
                            <span class="ml-2">{{model.createdAt | date: 'dd/MM/yyyy HH:mm'}}</span></p>
                        <p class="text-muted size-md"><strong>Telefone:</strong><span
                                class="ml-2">{{model.createdBy?.phone}}</span></p>
                    </div>

                    <div class="col-md-12 col-lg-10 offset-lg-1">
                        <p class="text-muted size-md"><strong>Mensagem:</strong><span
                                class="ml-2">{{model.message}}</span></p>
                    </div>

                    <div class="col-md-12 col-lg-10 offset-lg-1">
                        <hr/>
                    </div>

                    <div class="col-md-12 col-lg-10 offset-lg-1 text-right">
                        <div class="text-left mb-2">
                            <p class="text-muted size-md"><strong>Resposta:</strong></p>
                        </div>
                        <textarea class=" input-material" id="description" style="width: inherit; resize: none"
                                  rows="4" [(ngModel)]="model.answer"
                                  [disabled]="model.status == 'answered'" required></textarea>
                    </div>
                    <div class="col-md-12 col-lg-8 offset-lg-2" *ngIf="model.status !== 'answered'">
                        <div class="mx-auto">
                            <a href="javascript:void(0)" class="btn btn-block btn-warning mt-4 mb-5 px-5"
                               type="button" (click)="actionAnswer()">Enviar</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
