// @ts-ignore
import {ServiceBase} from '../base/service.base';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StoreService extends ServiceBase {
    constructor(http: HttpClient) {
        super(http);
    }

    search(filter, page) {
        const url = `${this.BASE_URL}/store/search/${page}`;
        return this.http.post(url, filter);
    }

    delete(id) {
        const url = `${this.BASE_URL}/store/${id}`;
        return this.http.delete(url);
    }

    create(model) {
        const url = `${this.BASE_URL}/store`;
        return this.http.post(url, model);
    }

    update(model) {
        const url = `${this.BASE_URL}/store`;
        return this.http.put(url, model);
    }
    list(model){
        const url = `${this.BASE_URL}/store/store-list`;
        return this.http.post(url, model);
    }
}
