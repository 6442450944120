<section class="container">

    <div class="d-flex align-items-center ml-4 mt-4">
        <h1 class="title ">Saalve Bag<span class="subTitle ml-2"> Delivery</span></h1>
    </div>

    <div class="card mt-3 bg-white mx-2 p-3">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-12 col-lg-6 col-xl-6 col-xxl-3">
                    <div class="input-material">
                        <input id="period" class="form-control js-daterangepicker" type="text" required/>
                        <label for="period">Período</label>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-6 col-xxl-3">
                    <div class="input-material">
                        <input id="name" class="form-control" type="text" required [(ngModel)]="modelFilter.driver"/>
                        <label for="name">Nome do Entregador</label>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-6 col-xxl-3">
                    <div class="input-material">
                        <select id="store" class="form-control" name="type" [(ngModel)]="modelFilter.storeId">
                            <option [ngValue]="undefined">Todos</option>
                            <option *ngFor="let i of this.modelStoreList" [value]=i.id>{{i.name}}</option>
                        </select>
                        <label for="unity" class="mb-0 mr-3">Lojas</label>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-6 col-xxl-3">
                    <div class="input-material">
                        <input id="number" class="form-control" type="number" required
                               [(ngModel)]="modelFilter.orderNumber"/>
                        <label for="number">Número do Pedido</label>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-6 col-xxl-3">
                    <div class="input-material">
                        <select id="unity" class="form-control" name="type" [(ngModel)]="modelFilter.status">
                            <option [ngValue]="''">Todos</option>
                            <option [value]="'pending'">Aguardando motoboy</option>
                            <option [value]="'accept'">Aceito</option>
                            <option [value]="'await'">Em preparo</option>
                            <option [value]="'route'">Rota</option>
                            <option [value]="'delivered'">Entregue</option>
                            <option [value]="'fail'">Cancelado</option>
                        </select>
                        <label for="unity" class="mb-0 mr-3">Status</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-sm-12 text-right">
                    <a href="javascript:void('');" class="btn btn-ico btn-sm btn-sim mr-xs-4 mb-3 mb-xs-0"
                       *ngIf="!production"
                       (click)="handlerSimulator()">
                        <svg-icon class="svg" src="assets/svg/simulator.svg"></svg-icon>
                        Simular Pedido
                    </a>
                    <a href="javascript:void('');"
                       [ngClass]="['btn', 'btn-ico', 'btn-sm', 'mr-xs-4', 'mb-3', 'mb-xs-0', isAutoRefresh ? 'btn-danger': 'btn-success']"
                       (click)="autoRefresh()">
                        <svg-icon class="svg" src="assets/svg/sync-alt-solid.svg"></svg-icon>
                        {{isAutoRefresh ? 'Desligar atualização automática' : 'Atualizar Automaticamente'}}
                    </a>
                    <a href="javascript:void('');" class="btn btn-ico btn-sm btn-success mr-xs-4 mb-3 mb-xs-0"
                       (click)="getExcel()">
                        <svg-icon class="svg" src="assets/svg/excel.svg"></svg-icon>
                        Exportar Excel
                    </a>
                    <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mb-3 mb-xs-0"
                       (click)="actionSearch()">
                        <svg-icon class="svg" src="assets/svg/search.svg"></svg-icon>
                        Buscar
                    </a>
                </div>
            </div>

        </div>
    </div>
    <div class="row mx-0 pt-4">
        <div class="col-12 mb-3" *ngIf="isNullOrUndefined(modelResult.results)">
            <p class="pt-5 pb-5 text-center">
                Nenhum registro encontrado.
            </p>
        </div>
        <div class="col-xl-6 col-xxxl-4 mb-4" *ngFor="let r of modelResult.results |
                    paginate: {
                     itemsPerPage: modelResult.pageSize,
                     currentPage: modelResult.currentPage,
                     totalItems: modelResult.totalCount}">
            <div class="card">
                <div class="card-box project-box">
                    <div class="row mx-0 py-3 bg-primary rounded-top align-items-center">
                        <div class="col-8">
                            <h6 class="text-secondary mb-0 px-0 col-12 text-truncate">{{r.store?.name}}</h6>
                            <p class="text-secondary my-2 px-0 col-12 text-truncate">
                                Nº do Pedido: <span class="text-white"> {{ r.orderCode}}</span>
                            </p>
                            <p class="text-secondary my-2 px-0 col-12 text-truncate"> Distância do
                                cliente à loja: <span class="text-white"> {{ r.deliveryDistance}} km</span></p>
                            <p class="text-secondary my-0 px-0 col-12 text-truncate">
                                {{r.name}} <span
                                    class="text-white"> - {{r.phoneCode | mask: '(00)'}} {{r.phoneNumber | mask: '0 0000-0000||0000-0000'}}</span>
                            </p>
                        </div>
                        <div class="col-4 d-flex justify-content-end">
                            <a *ngIf="r.redirect" href="javascript:void('');"
                               (click)="actionDelivery(r.id)"
                               class="btn btn-sm btn-secondary py-2 mr-2 ">
                                <svg-icon src="assets/svg/redirect.svg"></svg-icon>
                            </a>
                            <a *ngIf="r.redirect" href="javascript:void('Motoristas');"
                               (click)="handlerModalDriver(r.id)"
                               class="btn btn-sm btn-secondary py-2 mr-2 ">
                                <svg-icon src="assets/svg/redirect.svg"></svg-icon>
                            </a>
                            <a href="javascript:void('Detalhes');" [routerLink]="['/delivery-detail/' + r.id]"
                               class="btn btn-sm btn-secondary py-2">
                                <svg-icon src="assets/svg/eyes.svg"></svg-icon>
                            </a>
                        </div>
                    </div>
                    <div class="p-3">
                        <!--DATA E STATUS-->
                        <div class="row mx-0 mb-2">
                            <p class="text-uppercase text-muted col-6 mb-0 p-0">
                                <strong>{{r.dateTime | date: 'dd/MM/yyyy HH:mm'}}</strong>
                            </p>
                            <div class="col-6">
                                <div class="badge float-right px-1 pt-1" [ngClass]="{'badge-quaternary': r.status === 'accept',
                                        'badge-success': r.status === 'delivered',
                                        'badge-green-variant': r.status === 'route',
                                        'badge-danger': r.status === 'fail',
                                        'badge-info': r.status === 'await',
                                        'badge-warning': r.status === 'pending'}">
                                    <b *ngIf="r.status === 'accept'">Aceito</b>
                                    <b *ngIf="r.status === 'delivered'">Entregue</b>
                                    <b *ngIf="r.status === 'route'">Saiu para entrega</b>
                                    <b *ngIf="r.status === 'fail'">Cancelado</b>
                                    <b *ngIf="r.status === 'await'">Em preparo</b>
                                    <b *ngIf="r.status === 'pending'">Aguardando motoboy</b>
                                </div>
                            </div>
                        </div>

                        <!--ENDEREÇO-->
                        <p class="text-muted font-13" style="height: 40px; max-height: 40px;">
                            {{r.address}}, {{r.number}} - {{r.region}} - {{r.city}}/{{r.state}}
                            <span *ngIf="!isNullOrUndefined(r.observation)"> - {{r.observation}}</span>
                            <span *ngIf="!isNullOrUndefined(r.referencePoint)"> - {{r.referencePoint}}</span></p>

                        <!--PAGAMENTO-->
                        <div class="mb-2">
                            <div class="d-flex">
                                <h6 class="font-weight-bold text-muted mb-0 d-flex align-items-center">Pagamento </h6>
                                <hr class="ml-2 w-100">
                            </div>

                            <div class="mb-2">
                                <p class="text-muted mb-1">Total</p>
                                <h5 class="mb-0 font-weight-bold col-12 p-0 text-truncate">
                                    {{r.total | currency :'BRL': true: '1.2-2': 'pt-BR'}}
                                </h5>
                            </div>
                            <div>
                                <p class="text-muted mb-1">Forma de Pagamento</p>
                                <h5 class="mb-0 font-weight-bold col-12 p-0 text-truncate">{{r.payment}}</h5>
                            </div>
                        </div>

                        <!--ENTREGADORES-->
                        <div style="height: 125px">
                            <div class="d-flex">
                                <h6 class="font-weight-bold text-muted mb-0 d-flex align-items-center">
                                    Entregadores </h6>
                                <hr class="ml-2 w-100">
                            </div>
                            <ng-container *ngIf="isNullOrUndefined(r.delivery)">
                                <p class="my-3 text-center w-100">Nenhum entregador encontrado no momento!</p>

                            </ng-container>
                            <ng-container *ngIf="!isNullOrUndefined(r.delivery)">
                                <ul class="list-inline d-flex flex-wrap mb-2"
                                    *ngFor="let d of r.delivery | slice: 0 : 2; let i = index;"
                                    [ngClass]="{'border-bottom' : i == 0}">
                                    <li class="list-inline-item col-xs-5 text-truncate m-0 px-1">
                                        <p class="text-muted mb-0">Nome</p>
                                        <h6 class="mb-0 font-weight-bold col-12 p-0 text-truncate">{{d.driver?.name}}</h6>
                                    </li>
                                    <li class="list-inline-item col-xxs-6 col-xs-4 px-1 m-0">
                                        <p class="text-muted mb-0">Veículo</p>
                                        <h6 class="mb-0 font-weight-bold col-12 p-0 text-truncate">{{d.vehicle?.model}}</h6>
                                    </li>

                                    <li class="list-inline-item col-xxs-6 col-xs-3 align-middle m-0 py-2 px-1 text-right">
                                        <a *ngIf="r.redirect" href="javascript:void('Status');"
                                           (click)="handlerRedirect(d.id)"
                                           class="btn btn-sm btn-secondary py-1 mr-2 ">
                                            <svg-icon src="assets/svg/refresh.svg"></svg-icon>
                                        </a>
                                        <div class="badge float-right px-2 pt-2" [ngClass]="{
                                        'badge-quaternary': d.status === 'accept',
                                        'badge-danger': d.status === 'deny',
                                        'badge-warning': d.status === 'pending',
                                        'badge-green-variant': d.status === 'route',
                                        'badge-orange': d.status === 'fail',
                                        'badge-success': d.status === 'delivered',
                                        'badge-info': d.status === 'await'}">
                                            <b *ngIf="d.status === 'pending'">Aguardando motoboy</b>
                                            <b *ngIf="d.status === 'accept'">Aceito</b>
                                            <b *ngIf="d.status === 'route'">Em Rota</b>
                                            <b *ngIf="d.status === 'deny'">Cancelado</b>
                                            <b *ngIf="d.status === 'fail'">Não <br>entregue</b>
                                            <b *ngIf="d.status === 'delivered'">Entregue</b>
                                            <b *ngIf="d.status === 'await'">Em preparo</b>
                                        </div>
                                    </li>
                                </ul>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="text-center my-4">
        <pagination-controls
                [previousLabel]="labels.previousLabel"
                [nextLabel]="labels.nextLabel"
                [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                [screenReaderPageLabel]="labels.screenReaderPageLabel"
                [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                (pageChange)="pageChanged($event)"></pagination-controls>
    </div>
</section>


<div class="modal fade bs-modal-sm in" id="simulator" tabindex="-1" role="dialog" aria-hidden="true"
     data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header bg-primary text-white">
                <h4 class="modal-title">Simular Pedido</h4>
                <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body card-header">
                <form autocomplete="off">
                    <div class="input-material">
                        <input type="text" class="form-control" name="orderNumber" id="orderNumber"
                               [(ngModel)]="orderNumber" required/>
                        <label for="orderNumber">Número do Pedido</label>
                    </div>
                    <div class="input-material">
                        <input type="text" class="form-control" name="externalId" id="externalId"
                               [(ngModel)]="externalId" required/>
                        <label for="orderNumber">External Id da Loja</label>
                    </div>
                </form>
                <hr>
                <div class="col-md-12 col-lg-12 p-0">
                    <div class="mx-auto">
                        <a href="javascript:void(0)" class="btn btn-block btn-sim mt-4 px-5" type="button"
                           (click)="getSimulator()">Simular</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Modal list driver-->
<div class="modal-custom modal" id="modalListDriver" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-0">
            <div class="modal-header bg-primary text-white">
                <h3>ENTREGADORES DISPONIVEIS</h3>
                <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="input-material">
                    <select id="drivers" class="form-control" name="type" [(ngModel)]="selectedDriverId">
                        <option [ngValue]="undefined">Selecione</option>
                        <option *ngFor="let i of modelListDrivers" [ngValue]="i.id">{{i.name}}</option>
                    </select>
                    <label>Entregador</label>
                </div>
                <a href="javascript:void('0');" class="btn btn-primary btn-block" (click)="actionSelectedDriver()">
                    Salvar
                </a>
            </div>
        </div>
    </div>
</div>
<!--Modal Delivery Log-->]
<div class="modal custom-modal" id="modalDeliveryLog">
    <div class="modal-dialog modal-dialog-centered modal-xl " role="document">
        <div class="modal-content p-0 radius">
            <div class="modal-header bg-primary text-white radius ">
                <h3 class="ml-3">Delivery LOG</h3>
                <a href="javascript:void('Fechar');" class="close mr-3" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body">
                <div class="row mx-3">
                    <div class="col-6 justify-content-between">
                        <p class="mt-2">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sed velit lacus. In vel venenatis quam, ac ultricies ligula. Ut accumsan
                        </p>
                        <div class="card mb-3">
                            <div class="card-body col-12">
                                <div class="row justify-content-between">
                                    <div class="text-center ml-5">
                                        {{modelDelivery.attempt}} <br>
                                        Attempt
                                    </div>
                                    |
                                    <div class="text-center">
                                        {{modelDelivery.bikes}} <br>
                                        Motos
                                    </div>
                                    |
                                    <div class="text-center mr-5">
                                        {{modelDelivery.time}}<br>
                                        Tempo total
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 class="pt-4">Critérios de aprovação</h4>
                        <div class="card mb-4 mt-1">
                            <div class="card-body p-0">
                                <div class="row">
                                    <div class="col-6 d-flex align-items-center">
                                        <span class="dot"></span>
                                        <span class="ml-3">
                                          Estar online
                                      </span>
                                    </div>
                                    <div class="col-6 d-flex align-items-center">
                                        <span class="ml-3">
                                        <img src="assets/images/img_01.png"
                                             class="text-right img-config">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card my-4">
                            <div class="card-body p-0">
                                <div class="row">
                                    <div class="col-6 d-flex align-items-center">
                                        <span class="dot"></span>
                                        <span class="ml-3">
                                          Pertencer ao raio de entrega
                                      </span>
                                    </div>
                                    <div class="col-6 d-flex align-items-end">
                                        <span class="ml-3">
                                        <img src="assets/images/img_02.png"
                                             class="text-right img-config">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card my-4">
                            <div class="card-body p-0">
                                <div class="row">
                                    <div class="col-6 d-flex align-items-center">
                                        <span class="dot"></span>
                                        <span class="ml-3">
                                         Ter aceito o pedido no tempo programado
                                      </span>
                                    </div>
                                    <div class="col-6">
                                        <span class="ml-3">
                                        <img src="assets/images/img_03.png"
                                             class="text-right img-config">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-center mt-3">
                            <a href="javascript:void('');" class="btn bg-quaternary text-white" (click)="getLogs()">Atualizar</a>
                        </div>
                    </div>
                    <div class="col-6 radius scroll-content">
                        <div class="table-responsive">
                            <table class="table table-striped radius">
                                <thead class="bg-primary text-white">
                                <tr class="col-12">
                                    <th class="bg-transparent sticky-row w-80">Attempt</th>
                                    <th class="bg-transparent sticky-row">Tempo</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let t of modelDelivery.results" class="scroll-content">
                                    <td>{{t.log}}</td>
                                    <td>{{t.createdAt | date: 'HH:mm:ss'}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

