<section class="container">

    <div class="d-flex align-items-center ml-4 mt-4">
        <h1 class="title ">Saalve Bag<span class="subTitle ml-2"> Dashboard</span></h1>
    </div>

    <div class="dashboard">
        <hr>

        <div class="row">
            <div class="col-xl-6 col-md-12">
                <div class="col-md-12">
                    <div class="card shadow p-3 mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between mb-3">
                            <h6 class="m-0 font-weight-bold text-primary">Atendimento</h6>
                        </div>
                        <div class="row">
                            <div class="col-xl-4 col-md-4 col-sm-12">
                                <div class="card-box widget-user">
                                    <div class="text-center">
                                        <h2 data-plugin="counterup"
                                            class="font-weight-normal text-danger">{{modelTotalizer.supports.notAnswered}}</h2>
                                        <p class="mb-0">Não Respondido</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-4 col-sm-12">
                                <div class="card-box widget-user">
                                    <div class="text-center">
                                        <h2 data-plugin="counterup"
                                            class="font-weight-normal text-success">{{modelTotalizer.supports.answered}}</h2>
                                        <p class="mb-0">Respondido</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-4 col-sm-12">
                                <div class="card-box widget-user">
                                    <div class="text-center">
                                        <h2 data-plugin="counterup"
                                            class="font-weight-normal text-info">{{modelTotalizer.supports.total}}</h2>
                                        <p class="mb-0">Total</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card shadow p-3 mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between mb-3">
                            <h6 class="m-0 font-weight-bold text-primary">Chats</h6>
                        </div>
                        <div class="row">
                            <div class="col-xl-4 col-md-4 col-sm-12">
                                <div class="card-box widget-user">
                                    <div class="text-center">
                                        <h2 data-plugin="counterup"
                                            class="font-weight-normal text-danger">{{modelTotalizer.chats.notAnswered}}</h2>
                                        <p class="mb-0">Não Respondido</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-4 col-sm-12">
                                <div class="card-box widget-user">
                                    <div class="text-center">
                                        <h2 data-plugin="counterup"
                                            class="font-weight-normal text-success">{{modelTotalizer.chats.answered}}</h2>
                                        <p class="mb-0">Respondido</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-4 col-sm-12">
                                <div class="card-box widget-user">
                                    <div class="text-center">
                                        <h2 data-plugin="counterup"
                                            class="font-weight-normal text-info">{{modelTotalizer.chats.total}}</h2>
                                        <p class="mb-0">Total</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card shadow p-3 mb-4">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between mb-3">
                            <h6 class="m-0 font-weight-bold text-primary">Entregadores</h6>
                        </div>
                        <div class="row">
                            <div class="col-xl-3 col-md-3 col-sm-12">
                                <div class="card-box widget-user">
                                    <div class="text-center">
                                        <h2 data-plugin="counterup"
                                            class="font-weight-normal text-info">{{modelTotalizer.drivers.active}}</h2>
                                        <p class="mb-0">Ativos</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-3 col-sm-12">
                                <div class="card-box widget-user">
                                    <div class="text-center">
                                        <h2 data-plugin="counterup"
                                            class="font-weight-normal text-info">{{modelTotalizer.drivers.blocked}}</h2>
                                        <p class="mb-0">Bloqueado</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-3 col-sm-12">
                                <div class="card-box widget-user">
                                    <div class="text-center">
                                        <h2 data-plugin="counterup"
                                            class="font-weight-normal text-info">{{modelTotalizer.drivers.pending}}</h2>
                                        <p class="mb-0">Pendências no cadastro</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-3 col-sm-12">
                                <div class="card-box widget-user">
                                    <div class="text-center">
                                        <h2 data-plugin="counterup"
                                            class="font-weight-normal text-info">{{modelTotalizer.drivers.waitingAccept}}</h2>
                                        <p class="mb-0">Aguardando aprovação</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-md-12">
                <div class="card shadow p-3 mb-4">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between mb-3">
                        <h6 class="m-0 font-weight-bold text-primary">Avaliações
                            ({{modelTotalizer.ratings._5 +
                            modelTotalizer.ratings._4 +
                            modelTotalizer.ratings._3 +
                            modelTotalizer.ratings._2 +
                            modelTotalizer.ratings._1}}
                            no total)
                        </h6>
                    </div>
                    <div class="row">
                        <div class="col-12">

                            <div class="card-box mb-3">
                                <div class="widget-box-2">
                                    <div class="widget-detail-2 text-right">
                                        <span class="d-inline float-left mt-3">
                                          <svg-icon class="svg" src="assets/svg/star.svg"></svg-icon>
                                          <svg-icon class="svg" src="assets/svg/star.svg"></svg-icon>
                                          <svg-icon class="svg" src="assets/svg/star.svg"></svg-icon>
                                          <svg-icon class="svg" src="assets/svg/star.svg"></svg-icon>
                                          <svg-icon class="svg" src="assets/svg/star.svg"></svg-icon>
                                        </span>
                                        <h2 class="font-weight-normal mb-1"> {{modelTotalizer.ratings._5}}</h2>
                                        <p class="text-muted mb-3">5 Estrelas</p>
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div class="card-box mb-3">
                                <div class="widget-box-2">
                                    <div class="widget-detail-2 text-right">
                                        <span class="d-inline float-left mt-3">
                                          <svg-icon class="svg" src="assets/svg/star.svg"></svg-icon>
                                          <svg-icon class="svg" src="assets/svg/star.svg"></svg-icon>
                                          <svg-icon class="svg" src="assets/svg/star.svg"></svg-icon>
                                          <svg-icon class="svg" src="assets/svg/star.svg"></svg-icon>
                                          <svg-icon class="svg" src="assets/svg/star-thin.svg"></svg-icon>
                                        </span>
                                        <h2 class="font-weight-normal mb-1"> {{modelTotalizer.ratings._4}} </h2>
                                        <p class="text-muted mb-3">4 Estrelas</p>
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div class="card-box mb-3">
                                <div class="widget-box-2">
                                    <div class="widget-detail-2 text-right">
                                        <span class="d-inline float-left mt-3">
                                          <svg-icon class="svg" src="assets/svg/star.svg"></svg-icon>
                                          <svg-icon class="svg" src="assets/svg/star.svg"></svg-icon>
                                          <svg-icon class="svg" src="assets/svg/star.svg"></svg-icon>
                                          <svg-icon class="svg" src="assets/svg/star-thin.svg"></svg-icon>
                                          <svg-icon class="svg" src="assets/svg/star-thin.svg"></svg-icon>
                                        </span>
                                        <h2 class="font-weight-normal mb-1"> {{modelTotalizer.ratings._3}} </h2>
                                        <p class="text-muted mb-3">3 Estrelas</p>
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div class="card-box mb-3">
                                <div class="widget-box-2">
                                    <div class="widget-detail-2 text-right">
                    <span class="d-inline float-left mt-3">
                      <svg-icon class="svg" src="assets/svg/star.svg"></svg-icon>
                      <svg-icon class="svg" src="assets/svg/star.svg"></svg-icon>
                      <svg-icon class="svg" src="assets/svg/star-thin.svg"></svg-icon>
                      <svg-icon class="svg" src="assets/svg/star-thin.svg"></svg-icon>
                      <svg-icon class="svg" src="assets/svg/star-thin.svg"></svg-icon>
                    </span>
                                        <h2 class="font-weight-normal mb-1"> {{modelTotalizer.ratings._2}} </h2>
                                        <p class="text-muted mb-3">2 Estrelas</p>
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div class="card-box">
                                <div class="widget-box-2">
                                    <div class="widget-detail-2 text-right">
                    <span class="d-inline float-left mt-3">
                      <svg-icon class="svg" src="assets/svg/star.svg"></svg-icon>
                      <svg-icon class="svg" src="assets/svg/star-thin.svg"></svg-icon>
                      <svg-icon class="svg" src="assets/svg/star-thin.svg"></svg-icon>
                      <svg-icon class="svg" src="assets/svg/star-thin.svg"></svg-icon>
                      <svg-icon class="svg" src="assets/svg/star-thin.svg"></svg-icon>
                    </span>
                                        <h2 class="font-weight-normal mb-1"> {{modelTotalizer.ratings._1}} </h2>
                                        <p class="text-muted mb-3">1 Estrela</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-md-12 col-lg-4">
                <div class="input-material">
                    <input id="period" class="form-control js-daterangepicker" type="text" required/>
                    <label for="period">Período</label>
                </div>
            </div>
            <div class="col-md-12 col-lg-3 mb-5 mb-lg-0">
                <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mt-2"
                   (click)="getDahsboardChart()"
                >
                    <svg-icon class="svg" src="assets/svg/search.svg"></svg-icon>
                    Buscar
                </a>
            </div>
        </div>

        <div class="row">

            <div class="col-12 col-xl-12">
                <div class="card-primary">
                    <div class="card-primary-box">
                        <div class="card-primary-header">
                            <P class="card-primary-header-title">Entregas realizadas/canceladas</P>
                            <hr>
                        </div>
                        <div *ngIf="isNullOrUndefined(modelChart.storeOrders)" class="card-primary-body">
                            Nenhum dado para exibir no momento, tente escolher outro filtro
                        </div>
                        <div *ngIf="!isNullOrUndefined(modelChart.deliveries)" class="card-primary-body">
                            <div class="card-primary-body-graph">
                                <apx-chart
                                        [series]="chartOptionsLine.series"
                                        [chart]="chartOptionsLine.chart"
                                        [xaxis]="chartOptionsLine.xAxis"
                                        [stroke]="chartOptionsLine.stroke"
                                        [colors]="chartOptionsLine.colors"
                                        [dataLabels]="chartOptionsLine.dataLabels"
                                        [legend]="chartOptionsLine.legend"
                                        [markers]="chartOptionsLine.markers"
                                        [grid]="chartOptionsLine.grid"
                                        [yaxis]="chartOptionsLine.yAxis"
                                        [title]="chartOptionsLine.title"
                                ></apx-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-12 col-xl-12">
                <div class="card-primary">
                    <div class="card-primary-box">
                        <div class="card-primary-header">
                            <p class="card-primary-header-title">Pedidos por <strong>Loja</strong></p>
                            <hr>
                        </div>
                        <div *ngIf="isNullOrUndefined(modelChart.storeOrders)" class="card-primary-body">
                            Nenhum dado para exibir no momento, tente escolher outro filtro
                        </div>
                        <div *ngIf="!isNullOrUndefined(modelChart.storeOrders)" class="card-primary-body">
                            <div class="card-primary-body-graph">
                                <apx-chart
                                        [series]="chartOptionsColumns.series"
                                        [chart]="chartOptionsColumns.chart"
                                        [dataLabels]="chartOptionsColumns.dataLabels"
                                        [plotOptions]="chartOptionsColumns.plotOptions"
                                        [yaxis]="chartOptionsColumns.yAxis"
                                        [legend]="chartOptionsColumns.legend"
                                        [fill]="chartOptionsColumns.fill"
                                        [stroke]="chartOptionsColumns.stroke"
                                        [tooltip]="chartOptionsColumns.tooltip"
                                        [xaxis]="chartOptionsColumns.xAxis"
                                ></apx-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</section>






