export class Store {
    id: string;
    name: string;
    address: string;
    lat: number;
    lng: number;
    status: string;
    externalId: string;
    bagLogin: string;
    bagPassword: string;
    createdAt: Date;
    updatedAt: Date;
}
