import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {Chat} from '../../model/chat/chat';
import {ResultChat} from '../../model/chat/result-chat';
import {ChatService} from '../../service/chat.service';
import {FilterChat} from '../../model/chat/filter-chat';
import moment from 'moment';
import {Messages} from '../../model/chat/messages';
import {HubService} from '../../service/hub.service';
import {ChatSocket} from '../../model/chat/chat-socket';

declare var $: any;

@Component({
    selector: 'app-driver-chat',
    templateUrl: './driver-chat.component.html',
    styleUrls: ['./driver-chat.component.scss']
})
export class DriverChatComponent extends BaseComponent implements OnInit, AfterViewInit {

    modelResult: ResultChat = new ResultChat();
    modelFilter: FilterChat = new FilterChat();
    model: Chat = new Chat();
    currentPage = 1;
    message = '';

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public hubService: HubService,
                public chatService: ChatService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.actionSearch();
        this.hubService.onNewMessage.subscribe({
            next: data => {
                const c = data as ChatSocket;
                if (this.model.id === c.id) {
                    const date = this.model.messages[0].createdAt.toString();
                    this.getMessages(c.id, 'after', date);
                } else {
                    this.actionSearch();
                }
            }
        });
    }

    ngAfterViewInit(): void {
    }

    actionSearch() {
        this.modelFilter.status = 'open';
        this.loading.show();
        this.chatService.search(this.modelFilter, this.currentPage).subscribe({
            next: data => {
                this.modelResult = data as ResultChat;
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    actionDetail(id) {
        this.loading.show();
        this.chatService.getId(id).subscribe({
            next: data => {
                this.model = data as Chat;
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    handlerMin(h) {
        return moment(h).startOf('minutes').fromNow();
    }

    getMessages(id: string, period: string, date: string) {
        this.chatService.messages(id, period, date).subscribe({
            next: data => {
                const messages = data as Messages[];
                messages.forEach(x => {
                    if (this.isNullOrUndefined(this.model.messages.filter(y => y.id === x.id))) {
                        this.model.messages.unshift(x);
                    }
                });
            }, error: err => super.onError(err)
        });
    }

    actionSend() {
        if (super.isNullOrUndefined(this.message)) {
            this.showMessage('Atenção', `Degite uma mensagem para prosseguir!`, 'warning');
            return;
        }

        // this.loading.show();
        this.chatService.send({id: null, message: this.message}, this.model.id).subscribe({
            next: (data: Messages) => {
                this.model.messages.unshift(data);
                this.message = '';
                // this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    actionCloseChat() {
        super.confirmMessage('Atenção', 'Deseja realmente encerrar este chat?', () => {
            this.loading.show();
            this.chatService.statusChat(this.model.id, 'closed').subscribe({
                next: data => {
                    this.actionSearch();
                    this.model = new Chat();
                    this.loading.hide();
                    super.showMessage('Sucesso', 'Chat encerrado com sucesso!', 'success');
                }, error: err => super.onError(err)
            });
        });
    }
}
