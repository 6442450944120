import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './views/main/main.component';
import {HeaderComponent} from './views/header/header.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {SidebarComponent} from './views/sidebar/sidebar.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FaqComponent} from './views/help/faq/faq.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {LogoutComponent} from './views/logout/logout.component';
import {LogService} from './service/log.service';
import {AppDataService} from './service/app_data.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import {RegisterComponent} from './views/register/register.component';
import {HomeComponent} from './views/home/home.component';
import {NotificationComponent} from './views/notification/notification.component';
import {UserAdminComponent} from './views/user-admin/user-admin.component';
import {DriversComponent} from './views/drivers/drivers.component';
import {DeliveryComponent} from './views/delivery/delivery.component';
import {ContactUsComponent} from './views/contact-us/contact-us.component';
import {DriverChatComponent} from './views/driver-chat/driver-chat.component';
import {DriversDetailComponent} from './views/drivers-detail/drivers-detail.component';
import {LoginComponent} from './views/login/login.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import {HeaderHttpInterceptor} from './base/header-interceptor';
import {NgxMaskModule} from 'ngx-mask';
import {RecoveryComponent} from './views/recovery/recovery.component';
import {DriverChatHistoryComponent} from './views/driver-chat-history/driver-chat-history.component';
import {DeliveryDetailComponent} from './views/delivery-detail/delivery-detail.component';
import {DriverPaymentComponent} from './views/driver-payment/driver-payment.component';
import {environment} from '../environments/environment';
import {ServiceWorkerModule} from '@angular/service-worker';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireModule} from '@angular/fire';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {HubService} from './service/hub.service';
import {StoreComponent} from './views/store/store.component';
import {TermComponent} from './views/term/term.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {AutocompleteComponent} from './util/autocomplete/autocomplete.component';
import { MapComponent } from './views/map/map.component';

registerLocaleData(localePt, 'pt-BR');

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        HeaderComponent,
        SidebarComponent,
        FaqComponent,
        LogoutComponent,
        RegisterComponent,
        HomeComponent,
        NotificationComponent,
        UserAdminComponent,
        DriversComponent,
        DeliveryComponent,
        ContactUsComponent,
        DriverChatComponent,
        DriversDetailComponent,
        LoginComponent,
        RecoveryComponent,
        DriverChatHistoryComponent,
        DeliveryDetailComponent,
        DriverPaymentComponent,
        StoreComponent,
        TermComponent,
        AutocompleteComponent,
        MapComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        HttpClientModule,
        AngularSvgIconModule.forRoot(),
        SlickCarouselModule,
        FormsModule,
        NgxSpinnerModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgxPaginationModule,
        NgApexchartsModule,
        NgxMaskModule.forRoot(),
        AngularEditorModule,
        CurrencyMaskModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        ServiceWorkerModule.register('ngsw-worker.js')
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderHttpInterceptor,
            multi: true
        },
        TranslateService,
        LogService,
        AppDataService,
        HubService,
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
