import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {OrderService} from '../../service/order.service';
import {ResultOrder} from '../../model/order/result-order';
import {FilterOrder} from '../../model/order/filter-order';
import {environment} from '../../../environments/environment';
import {StoreService} from '../../service/store.service';
import {Store} from '../../model/order/store';
import {UserService} from '../../service/user.service';
import {User} from '../../model/user/user';
import {DeliveryResult} from "../../model/deliveryResult";

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-delivery',
    templateUrl: './delivery.component.html',
    styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent extends BaseComponent implements OnInit, OnDestroy {

    modelFilter: FilterOrder = new FilterOrder();
    modelResult: ResultOrder = new ResultOrder();
    modelDelivery: DeliveryResult = new DeliveryResult();
    modelStoreList: Store[] = [];
    modelListDrivers: User[] = [];
    isAutoRefresh = false;

    currentPage = 1;
    labels: any = {
        previousLabel: ' ',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    production = environment.production;
    orderNumber: string;
    externalId = '59eef635-b53c-4615-b26b-39f569d1e636';

    selectedDriverId: string;
    orderId: string;
    selectedRouterId: string;


    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public userService: UserService,
                public storeService: StoreService,
                public orderService: OrderService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.setDatePicker();
        this.getStoreList();
        this.actionSearch();

        const parent = this;

        setInterval(() => {
            if (parent.isAutoRefresh) {
                parent.actionSearch();
            }
        }, 30000);
    }

    ngOnDestroy(): void {
        const elModalRemove = $('#simulator');
        if (elModalRemove[0]) {
            elModalRemove.remove();
        }
    }

    setDatePicker() {
        const data = moment().subtract(30, 'days');
        this.modelFilter.start = data.utcOffset(0, true).startOf('day');
        this.modelFilter.end = moment().utcOffset(0, true).endOf('day');
        $('.js-daterangepicker').daterangepicker({
            showDropdowns: true,
            startDate: data,
            locale: {
                format: 'DD/MM/YYYY',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                daysOfWeek: [
                    'Dom',
                    'Seg',
                    'Ter',
                    'Qua',
                    'Qui',
                    'Sex',
                    'Sab'
                ],
                monthNames: [
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                ],
                firstDay: 1
            },
        }, (start, end, label) => {
            this.modelFilter.start = start.utcOffset(0, true).format();
            this.modelFilter.end = end.utcOffset(0, true).format();
        });
    }

    pageChanged(event) {
        this.currentPage = event;
        this.actionSearch();
    }

    actionSearch() {
        this.loading.show();
        this.orderService.search(this.modelFilter, this.currentPage).subscribe({
            next: data => {
                this.modelResult = data as ResultOrder;
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;
        const start = moment(this.modelFilter.start).format('YYYY-MM-DD');
        const end = moment(this.modelFilter.end).format('YYYY-MM-DD');

        let url = `${environment.base_url}/order/search/excel?token=${token}`;

        if (!super.isNullOrUndefined(this.modelFilter.driver)) {
            url += ('&driver=' + this.modelFilter.driver);
        }

        if (!super.isNullOrUndefined(this.modelFilter.orderNumber)) {
            url += ('&orderNumber=' + this.modelFilter.orderNumber);
        }

        if (!super.isNullOrUndefined(this.modelFilter.status)) {
            url += ('&status=' + this.modelFilter.status);
        }

        if (!super.isNullOrUndefined(start)) {
            url += ('&start=' + start);
        }

        if (!super.isNullOrUndefined(end)) {
            url += ('&end=' + end);
        }
        // console.log(encodeURI(url));
        window.open(encodeURI(url));
    }

    handlerSimulator() {
        this.orderNumber = null;
        $('#simulator').modal('show');
    }

    getSimulator() {
        if (this.isNullOrUndefined(this.orderNumber)) {
            super.showMessage('Atenção', 'Digite o número do pedido para continuar a simulação', 'warning');
            return;
        }

        if (this.isNullOrUndefined(this.externalId)) {
            super.showMessage('Atenção', 'Digite o id externo da loja para continuar a simulação', 'warning');
            return;
        }

        this.loading.show();
        this.orderService.simulation(this.orderNumber, this.externalId).subscribe({
            next: data => {
                this.orderNumber = null;
                $('#simulator').modal('hide');
                this.actionSearch();
                this.loading.hide();
                super.showMessage('Sucesso', 'Pedido feito com sucesso!', 'success');
            }, error: err => super.onError(err)
        });
    }

    autoRefresh() {
        this.isAutoRefresh = !this.isAutoRefresh;
    }

    getStoreList() {
        this.storeService.list(this.modelFilter).subscribe({
            next: data => {
                this.modelStoreList = data as Store[];
            }, error: err => super.onError(err)
        });
    }

    handlerRedirect(id: string) {
        super.confirmMessage('Atenção', 'Deseja realmente mudar o status do entregador ?', () => {
            this.loading.show();
            this.orderService.resetOrder(id).subscribe({
                next: data => {
                    this.showMessage('Sucesso', 'Status do entregador alterado com sucesso !', 'success');
                    this.actionSearch();
                    this.loading.hide();
                }
            });
        });
    }

    handlerModalDriver(id) {
        this.loading.show();
        this.userService.availableDrivers(id).subscribe({
            next: data => {
                this.modelListDrivers = data as User[];
                if (!this.isNullOrUndefined(this.modelListDrivers)) {
                    this.orderId = id;
                    $('#modalListDriver').modal('show');
                } else {
                    this.showMessage('Atenção', 'Nenhum entregador disponível!', 'warning');
                }
                this.loading.hide();
            }, error: err => super.onError(err)
        });

    }

    actionSelectedDriver() {

        if (this.isNullOrUndefined(this.selectedDriverId)) {
            this.showMessage('Atenção', 'Selecione um entregador para a designação!', 'warning');
            return;
        }
        this.loading.show();
        this.orderService.newOrderDelivery(this.selectedDriverId, this.orderId).subscribe({
            next: data => {
                this.selectedDriverId = null;
                this.orderId = null;
                $('#modalListDriver').modal('hide');
                this.showMessage('Sucesso!', 'Entregador designado com sucesso!', 'success');
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    actionDelivery(id) {
        $('#modalDeliveryLog').modal('show');
        this.selectedRouterId = id;
        this.getLogs();
    }

    getLogs() {
        this.orderService.resultDelivery(this.selectedRouterId).subscribe({
            next: data => {
                this.modelDelivery = data as DeliveryResult;
            }, error: err => super.onError(err)
        });
    }
}
