import {Store} from "./store";

export class ResultStore {

    totalCount: number;
    pageSize: number;
    currentPage: number;
    totalPage: number;
    previousPage: boolean;
    nextPage: boolean;
    results: Store[];

    constructor() {
        this.results = [];
    }

}

