<section class="container">

    <div class="d-flex align-items-center ml-4 mt-4">
        <h1 class="title ">Saalve Bag<span class="subTitle ml-2"> Mapa de Entregadores</span></h1>
    </div>

    <div class="card mx-4 mt-4 py-3">
        <div class="row mx-0">
            <div class="col-lg-8 mb-4">
                <div id="map" style="min-height: 80vh; max-height: 80vh; min-width: 100%;"></div>
            </div>
            <div class="col-lg-4 overflow-auto" style="max-height: 80vh">
                <div class="col-sm-12 px-0 row align-items-center mx-0 mb-3">
                    <div class="input-material mb-0">
                        <input id="new_search" class="form-control" type="text" maxlength="100" [(ngModel)]="filter"
                               (ngModelChange)="filter" name="new_search" required/>
                        <label for="new_search">Busca</label>
                        <a href="javascript:void(0)" class="btn btn-icon-input" (click)="filter = ''"
                           *ngIf="!isNullOrUndefined(filter)">
                            <svg-icon class="svg-input" src="assets/svg/close.svg"></svg-icon>
                        </a>
                    </div>
                </div>

                <div class="card cursor-pointer p-2 mb-4" *ngFor="let d of filterFunction(modelMap.drive)"
                     (click)="selectedDriver(d)"
                     [ngClass]="{'border border-primary': (!isNullOrUndefined(selectedDriveId) && selectedDriveId) == d.id}">
                    <div class="row mx-0">
                        <div class="col-3 text-center mb-3 m-sm-0 px-1">
                            <img src="{{isNullOrUndefined(d.photo) ? 'assets/images/no-photo-available.png' : d.photo}}"
                                 class="rounded-circle img-thumbnail mb-2" height="60" width="60"
                                 alt="profile-image"/>
                            <div class="cursor-pointer" (click)="actionAvailability(d)">
                                <small class="badge pt-2 pb-1 pl-2 pr-2"
                                       [ngClass]="{'badge-success': d.availability === 'online',
                                        'badge-gray-text': d.availability === 'offline',
                                        'badge-info': d.availability === 'route'}">
                                    <b *ngIf="d.availability === 'online'">Online</b>
                                    <b *ngIf="d.availability === 'offline'">Offline</b>
                                    <b *ngIf="d.availability === 'route'">Em Rota</b>
                                </small>
                            </div>
                        </div>
                        <div class="col-sm-9 ">
                            <div class="text-left">
                                <p class="col text-muted pl-0 font-13 mb-0 d-inline-block text-truncate">
                                    <strong>Nome :</strong>
                                    <span class="ml-2 mb-0">{{d.name}}</span>
                                </p>

                                <p class="text-muted font-13 mb-1"><strong>Telefone :</strong>
                                    <span class="ml-2">{{d.phone | mask:'(00) 00000 - 0000 || (00) 0000 - 0000'}}</span>
                                </p>
                                <hr>
                                <p class="text-muted font-13 mb-0"><strong>Última atualização de posição:</strong>
                                    <span class="ml-2">{{d.lastDateUpdatedPosition | date:'dd/MM/yyyy HH:mm'}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
