import {Store} from './store';
import {Product} from './product';
import {Delivery} from './delivery';

export class Order {

  id: string;
  orderCode: string;
  status: string;
  name: string;
  phoneCode: string;
  phoneNumber: string;
  internalKey: string;
  dateTime: Date;
  observation: string;
  type: string;
  address: string;
  number: number;
  compl: string;
  region: string;
  postalCode: string;
  city: string;
  state: string;
  tax: number;
  paymentValue: number;
  referencePoint: string;
  payment: string;
  total: number;
  redirect: boolean;
  discount: number;
  rating: string;
  lat: string;
  lng: string;
  deliveryDistance: number;
  deliveryDistanceText: string;
  product: Product[];
  delivery: Delivery[];
  store: Store;

    constructor() {
      this.store = new Store();
      this.product = [];
      this.delivery = [];
    }
}
