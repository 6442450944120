import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {ResultUser} from '../../model/user/result-user';
import {UserService} from '../../service/user.service';
import {FilterUser} from '../../model/user/filter-user';
import {User} from '../../model/user/user';
import {validateText} from '../../util/validate-text';

declare var $: any;

@Component({
    selector: 'app-user-admin',
    templateUrl: './user-admin.component.html',
    styleUrls: ['./user-admin.component.scss']
})
export class UserAdminComponent extends BaseComponent implements OnInit, OnDestroy {

    modelFilter: FilterUser = new FilterUser();
    modelResult: ResultUser = new ResultUser();

    model: User = new User();

    currentPage = 1;
    labels: any = {
        previousLabel: ' ',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };
    confirmPassword: string;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public  userService: UserService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.modelFilter.profile = 'admin-attendance';
        this.actionSearch();
    }

    ngOnDestroy(): void {
        const elModalRemove = $('#modalUserDetail');
        if (elModalRemove[0]) {
            elModalRemove.remove();
        }
    }

    pageChanged(event) {
        this.currentPage = event;
        this.actionSearch();
    }

    actionSearch() {
        this.loading.show();
        this.userService.search(this.modelFilter, this.currentPage).subscribe({
            next: data => {
                this.modelResult = data as ResultUser;
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.name, text: 'Nome do Usuário*,<br>'},
            {value: this.model.email, text: 'Email*,<br>'},
            {value: this.model.profile, text: 'Perfil*,<br>'},
        ];

        if (super.isNullOrUndefined(this.model.id) && super.isNullOrUndefined(this.model.password)) {
            arrValidateFields.push({value: this.model.password, text: 'Senha*.<br>'});
        }

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        if (!super.isEMailValid(this.model.email)) {
            return;
        }

        if (this.isNullOrUndefined(this.model.id)) {
            // if (this.model.password.length < 6) {
            //     super.showMessage('Atenção', 'Sua senha deve conter no mínimo 6 caracteres.',  'warning');
            //     return;
            // }

            if (!validateText.validatePassword(this.model.password)) {
                super.showMessage('Atenção', 'A senha necessita ter ao menos um caracter maiúsculo, 1 minúsculo e 1 ' +
                    'caracter numérico, além de possuir no mínimo 8 caracteres!', 'warning');
                return;
            }

            if (this.model.password !== this.confirmPassword) {
                this.showMessage('Atenção', 'Senhas não são identicas!', 'warning');
                return;
            }

            this.create();
        } else {
            this.update();
        }

    }

    create() {
        this.loading.show();
        this.userService.create(this.model).subscribe({
            next: data => {
                this.model = new User();
                this.confirmPassword = null;
                this.actionSearch();
                $('#modalUserDetail').modal('hide');
                super.showMessage('Sucesso', 'Usuário cadastrado com sucesso!', 'success');
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    update() {
        this.loading.show();
        this.userService.update(this.model).subscribe({
            next: data => {
                this.model = new User();
                this.actionSearch();
                $('#modalUserDetail').modal('hide');
                super.showMessage('Sucesso', 'Usuário alterado com sucesso!', 'success');
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    handlerDetail(model): void {
        this.model = Object.assign({}, model);
        $('#modalUserDetail').modal('show');
    }

    handlerRegister(): void {
        this.model = new User();
        $('#modalUserDetail').modal('show');
    }

    actionRemove(id) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar este usuário?', () => {
            this.loading.show();
            this.userService.deleteAdmin(id).subscribe({
                next: data => {
                    this.actionSearch();
                    this.loading.hide();
                    super.showMessage('Sucesso', 'Usuário deletado com sucesso!', 'success');
                }, error: err => super.onError(err)
            });
        });
    }

    handlerStatus(event) {
        if (event.target.checked) {
            this.model.status = 'active';
        } else {
            this.model.status = 'inactive';
        }
    }
}
