<section class="container">

    <div class="d-sm-flex align-items-center justify-content-between mt-4 mx-4">
        <h1 class="title ">Saalve Bag<span class="subTitle ml-2"> Termos de Uso</span></h1>
        <a href="javascript:void (0)" (click)="modalRegister()" class="btn btn-success btn-ico btn-sm">
            <svg-icon class="svg" src="assets/svg/plus-circle.svg"></svg-icon>
            Novo Termo
        </a>
    </div>
    <div class="card mt-3 mx-2 bg-white p-3">
        <div class="table-responsive">
            <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                <thead>
                <tr style="background-color:#f8f9fc">
                    <th>Tipo</th>
                    <th>Criado em</th>
                    <th class="text-center">Opções</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="(listTerms.length === 0)">
                    <td colspan="7">
                        <p class="mt-5 mb-5 text-center">
                            Nenhum termo cadastrado!
                        </p>
                    </td>
                </tr>
                <tr *ngFor="let terms of listTerms">
                    <td>
                        <span *ngIf="terms.type == 'privacy-policy'">Política de privacidade</span>
                        <span *ngIf="terms.type == 'terms-of-user'">Termos de uso</span>
                        <span *ngIf="terms.type == 'terms-of-ranking'">Termos do ranking</span>
                        <span *ngIf="terms.type == 'test-terms-of-use'">Termos de Uso de Testes</span>
                    </td>
                    <td>{{terms.createdAt | date:'dd/MM/yyyy'}}</td>
                    <td class="text-center">
                        <a class="mx-2 btn btn-primary" (click)="onEdit(terms)">
                            <svg-icon src="assets/svg/edit.svg"></svg-icon>
                        </a>
                        <a class="mx-2 btn btn-danger" (click)="onRemove(terms.id)">
                            <svg-icon class="svg-white" src="assets/svg/trash.svg"></svg-icon>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</section>


<div class="modal fade bs-modal-sm in p-0" id="register" tabindex="-1" role="dialog" aria-hidden="true"
     data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content p-0">
            <div class="modal-header bg-primary text-white">
                <h4 class="modal-title">Termo</h4>
                <a href="javascript:void('Fechar');" (click)="oncloseModal()" class="close" data-dismiss="modal"
                   aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body">
                <div class="col-md-12 mb-4">
                    <span>Tipo</span>
                    <select class="form-control mt-1" [(ngModel)]="model.type">
                        <option [value]="''">Selecione</option>
                        <!--<option [value]="'privacy-policy'">Política de privacidade</option>-->
                        <option [value]="'terms-of-user'">Termos de uso</option>
                        <!--<option [value]="'term-of-ranking'">Termo do ranking</option>-->
                        <!--<option [value]="'test-terms-of-use'">Termos de Uso de Testes</option>-->
                    </select>
                </div>
                <div class="col-md-12">
                    <angular-editor [config]="editorConfig" [(ngModel)]="model.content"></angular-editor>
                </div>
            </div>
            <div class="modal-footer text-right">
                <div class="col-md-12">
                    <button type="button" class="btn btn-save btn-block" (click)="onSave()">
                        Salvar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
