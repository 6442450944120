import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {FaqComponent} from './views/help/faq/faq.component';
import {LogoutComponent} from './views/logout/logout.component';
import {RegisterComponent} from './views/register/register.component';
import {HomeComponent} from './views/home/home.component';
import {UserAdminComponent} from './views/user-admin/user-admin.component';
import {DriversComponent} from './views/drivers/drivers.component';
import {DeliveryComponent} from './views/delivery/delivery.component';
import {ContactUsComponent} from './views/contact-us/contact-us.component';
import {DriverChatComponent} from './views/driver-chat/driver-chat.component';
import {DriversDetailComponent} from './views/drivers-detail/drivers-detail.component';
import {LoginComponent} from './views/login/login.component';
import {AuthGuard} from './service/auth-guard.service';
import {RecoveryComponent} from './views/recovery/recovery.component';
import {DriverChatHistoryComponent} from './views/driver-chat-history/driver-chat-history.component';
import {DeliveryDetailComponent} from './views/delivery-detail/delivery-detail.component';
import {DriverPaymentComponent} from './views/driver-payment/driver-payment.component';
import {StoreComponent} from "./views/store/store.component";
import {TermComponent} from './views/term/term.component';
import {AuthGuardProfile} from './service/auth-guard-profile.service';
import {MapComponent} from './views/map/map.component';

const routes: Routes = [
    {path: 'logout', component: LogoutComponent},
    {path: 'login', component: LoginComponent},
    {path: 'recovery/:id', component: RecoveryComponent},
    {
        path: '', component: MainComponent,
        children: [
            {path: '', component: HomeComponent},
            {path: 'home', component: HomeComponent},
            {path: 'register', component: RegisterComponent, canActivate: [AuthGuardProfile]},
            {path: 'users', component: UserAdminComponent, canActivate: [AuthGuardProfile]},
            {path: 'term', component: TermComponent, canActivate: [AuthGuardProfile]},
            {path: 'faq', component: FaqComponent, canActivate: [AuthGuardProfile]},
            // OAKBERRY
            {path: 'drivers', component: DriversComponent, canActivate: [AuthGuardProfile]},
            {path: 'delivery', component: DeliveryComponent},
            {path: 'delivery-detail/:id', component: DeliveryDetailComponent},
            {path: 'contact-us', component: ContactUsComponent},
            {path: 'driver-detail/:id', component: DriversDetailComponent, canActivate: [AuthGuardProfile]},
            {path: 'driver-chat', component: DriverChatComponent},
            {path: 'driver-chat-history', component: DriverChatHistoryComponent},
            {path: 'driver-payment', component: DriverPaymentComponent, canActivate: [AuthGuardProfile]},
            {path: 'store', component: StoreComponent, canActivate: [AuthGuardProfile]} ,
            {path: 'map', component: MapComponent},
        ], canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
