export class Drive {

  profile: string;
  lastDateUpdatedPosition: Date;
  availability: string;
  status: string;
  id: string;
  name: string;
  photo: string;
  phone: string;
  lat: number;
  lng: number;

  constructor() {
  }
}
