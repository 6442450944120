import {BankAccount} from './bankAccount';
import {Document} from '../document';
import {Vehicle} from '../vehicle/vehicle';

export class User {

  id: string;
  user: string;
  name: string;
  email: string;
  photo: any;
  profile: string;
  status: string;
  document: string;
  phone: string;
  rating: string;
  ratingCount: string;
  sms: string;
  smsCode: string;
  platform: string;
  device: string;
  version: string;
  build: string;
  password: string;
  type: string;
  createdAt: Date;
  updatedAt: Date;
  bankAccount: BankAccount;
  documents: Document[];
  vehicles: Vehicle[];
  transferableBalance: string;
  withHeldBalance: string;
  balance: string;
  constructor() {
    this.documents = [];
    this.vehicles = [];
  }
}
