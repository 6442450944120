import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {OrderService} from '../../service/order.service';
import {Order} from '../../model/order/order';
import {environment} from '../../../environments/environment';
import {DomSanitizer} from "@angular/platform-browser";

declare var $: any;

@Component({
    selector: 'app-delivery-detail',
    templateUrl: './delivery-detail.component.html',
    styleUrls: ['./delivery-detail.component.scss']
})
export class DeliveryDetailComponent extends BaseComponent implements OnInit {

    modelDelivery: Order = new Order();

    production = environment.production;
    totalPrice = 0;
    qtd = 0;
    ratingURL;
    followURL;


    constructor(public router: Router,
                public route2: ActivatedRoute,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public orderService: OrderService,
                private sanitizer: DomSanitizer
    ) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.route2.params.subscribe(data => {
            if (!this.isNullOrUndefined(data.id)) {
                this.actionDetail(data.id);
            }
        });
    }

    actionDetail(id) {
        this.loading.show();
        this.orderService.getId(id).subscribe({
            next: data => {
                this.modelDelivery = data as Order;
                this.handlerSubTotal();

                this.ratingURL = this.sanitizer.bypassSecurityTrustResourceUrl(environment.base_socket_url + '/view/rating/' + this.modelDelivery.id);
                this.followURL = this.sanitizer.bypassSecurityTrustResourceUrl(environment.base_socket_url + '/view/follow/' + this.modelDelivery.id);

                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    handlerSubTotal() {
        this.modelDelivery.product.forEach(x => {
            const price = this.totalPrice + x.price;
            this.totalPrice = price;
            const qtd = this.qtd + x.qty;
            this.qtd = qtd;
        });
    }

    handlerParImpar(i) {
        if (i % 2 === 0) {
            return false;
        } else {
            return true;
        }
    }

    goBack() {
        window.history.back();
    }

    goToEvaluation() {
        $('#modalRating').modal('show');
        // window.open(environment.base_socket_url + '/view/rating/' + this.modelDelivery.id, '_blank');
    }

    goToFollow() {
        $('#modalFollow').modal('show');
        //window.open(environment.base_socket_url + '/view/rating/' + this.modelDelivery.id, '_blank');
    }

}
