import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DashboardService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    totalizer(model) {
        const url = `${this.BASE_URL}/Dashboard/totalizer`;
        return this.http.post(url, '');
    }

    charts(model) {
        const url = `${this.BASE_URL}/Dashboard`;
        return this.http.post(url, model);
    }
}
