// import {LatLng} from '../model/lat_lng';

import {Store} from '../model/map/store';
import {Drive} from '../model/map/drivers';

export class Util {
  static computeCentroidStore(list: Store[]) {

    list.sort((a, b) => {
      if (a.lat > b.lat) {
        return 1;
      }
      if (a.lat < b.lat) {
        return -1;
      }
      return 0;
    });

    let minLat = list[0].lat;
    const maxLat = list[list.length - 1].lat;

    list.sort((a, b) => {
      if (a.lng > b.lng) {
        return 1;
      }
      if (a.lng < b.lng) {
        return -1;
      }
      return 0;
    });

    let minLng = list[0].lng;
    const maxLng = list[list.length - 1].lng;

    let distLat = maxLat - minLat;
    distLat = distLat < 0 ? -1 * distLat : distLat;
    minLat += (distLat / 2);

    let distLng = maxLng - minLng;
    distLng = distLng < 0 ? -1 * distLng : distLng;
    minLng += (distLng / 2);

    const result = new Store();
    result.lat = minLat;
    result.lng = minLng;

    return result;
  }

  static computeCentroidDrive(list: Drive[]) {

    list.sort((a, b) => {
      if (a.lat > b.lat) {
        return 1;
      }
      if (a.lat < b.lat) {
        return -1;
      }
      return 0;
    });

    let minLat = list[0].lat;
    const maxLat = list[list.length - 1].lat;

    list.sort((a, b) => {
      if (a.lng > b.lng) {
        return 1;
      }
      if (a.lng < b.lng) {
        return -1;
      }
      return 0;
    });

    let minLng = list[0].lng;
    const maxLng = list[list.length - 1].lng;

    let distLat = maxLat - minLat;
    distLat = distLat < 0 ? -1 * distLat : distLat;
    minLat += (distLat / 2);

    let distLng = maxLng - minLng;
    distLng = distLng < 0 ? -1 * distLng : distLng;
    minLng += (distLng / 2);

    const result = new Drive();
    result.lat = minLat;
    result.lng = minLng;

    return result;
  }
}
