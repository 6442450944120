export class FilterStore {

    keyword: string;
    status: string;
    start: string;
    end: string;

    constructor() {
        this.status = '';
    }
}
