import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';

declare var $: any;

export class BaseComponent {

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService) {
    }

    setToken(token: string) {
        localStorage.setItem('token', token);
    }

    showModalChangePassword() {
        $('#changePassword').modal('show');
    }

    getBaseURL() {
        return environment.base_url;
    }

    switchLanguage(language: string) {
        if (language === 'pt') {
            language = 'pt';
        } else if (language === 'en') {
            language = 'en';
        } else if (language === 'es') {
            language = 'es';
        }
        this.translate.use(language);
        // console.log(this.translate.currentLang);
    }

    showMessage(t: string, m: string, type: any = null) {
        Swal.fire({
            title: t,
            html: m,
            icon: type,
            confirmButtonColor: '#ff5c00',
        });
    }

    confirmMessage(t: string, m: string, callback: any = null) {
        Swal.fire({
            title: t,
            text: m,
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            confirmButtonColor: '#ff5c00',
            cancelButtonColor: '#dc3545'
        }).then((result) => {
            if (!this.isNullOrUndefined(result.value) && result.value === true) {
                if (callback != null) {
                    callback();
                }
            }
        });
    }

    onError(error) {
        this.loading.hide();
        if (error.status === 401) {
            this.router.navigate(['/login']);
            return;
        }

        // const e = JSON.parse(error['error']);

        if (error.status === 400) {
            this.showMessage('Atenção', error.error.message, 'warning');
            return;
        }

        if (error.status === 500) {
            this.showMessage('Atenção', 'Ocorreu um erro com o servidor!.', 'warning');
            return;
        }
    }

    isNullOrUndefined(value) {
        return typeof value === 'undefined' || value == null || value.length === 0;
    }

    validateField(obj) {
        let strError = ``;
        obj.map((e) => {
            if (this.isNullOrUndefined(e.value)) {
                strError += `${e.text}`;
            }
        });
        return strError;
    }

    isEMailValid(strEmail) {
        const str = strEmail;
        const exp = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
        const testResult = exp.test(str);
        if (!testResult) {
            this.showMessage('Atenção', 'Informe um e-mail válido para prosseguir.', 'warning');
        }
        return testResult;
    }

    intersection(array1, array2) {
        const targetUser = (array1.length >= array2.lengh ? array1 : array2);
        const restUser = (targetUser === array1 ? array2 : array1);
        const foundItersections = targetUser.map(tu => {
            const mwminmax = restUser.map(ru => {
                const startMax = (tu[0] >= ru[0] ? tu[0] : ru[0]);
                const endMin = (tu[1] <= ru[1] ? tu[1] : ru[1]);
                if (startMax < endMin) {
                    const retarr = [].concat(startMax, endMin);
                    return retarr;
                } else {
                    return;
                }
            });
            const filteredmwminmax = mwminmax.filter(x => x !== undefined);
            return filteredmwminmax;
        });
        return foundItersections.flat();
    }

    handlerViewPassword() {
        const input = document.querySelector('#password');
        if (input.getAttribute('type') === 'password') {
            input.setAttribute('type', 'text');
        } else {
            input.setAttribute('type', 'password');
        }
    }

    handlerViewPasswordIcon() {
        const input = document.querySelector('#password');
        return input.getAttribute('type') === 'password';
    }

    objLocaleDateRangePicker() {
        return {
            customRangeLabel: 'Personalizar',
            format: 'DD/MM/YYYY',
            separator: ' - ',
            applyLabel: 'Aplicar',
            cancelLabel: 'Cancelar',
            daysOfWeek: [
                'Dom',
                'Seg',
                'Ter',
                'Qua',
                'Qui',
                'Sex',
                'Sab'
            ],
            monthNames: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro'
            ],
            firstDay: 1
        };
    }
}
