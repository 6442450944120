export class Store {

  id: string;
  name: string;
  address: string;
  status: string;
  lat: number;
  lng: number;
  externalId: string;
  saalveLogin: string;
  saalvePassword: string;
  createdAt: Date;
  updatedAt: Date;

  constructor() {
  }
}
