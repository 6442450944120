import {Component, OnInit} from '@angular/core';
import {User} from "../../model/user/user";
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../service/user.service";
import {Token} from "../../model/user/token";
import {Settings} from "../../settings";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {
    section: number = 0;
    model: User = new User();
    version = Settings.VERSION;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public  userService: UserService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.model.profile = 'admin';
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('login');
        body.classList.add('background-login');
    }

    nextSection(number: any) {
        this.section = number;
    }

    onForgot() {
        if (!super.isEMailValid(this.model.email)) return;

        this.loading.show();
        this.userService.forgot(this.model).subscribe({
            next: data => {
                super.showMessage('Sucesso', data['message'], 'success');
                this.nextSection(0);
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    onLogin() {
        const arrValidateFields = [
            {value: this.model.user, text: 'Email*,<br>'},
            {value: this.model.password, text: 'Senha*.<br>'},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        if (!super.isEMailValid(this.model.user)) return;

        this.loading.show();
        this.userService.getAuthenticate(this.model).subscribe({
            next: data => this.saveLocalUser(data)
            , error: err => super.onError(err)
        });
    }

    saveLocalUser(data) {
        this.loading.hide();
        const token = data as Token;
        this.setToken(token.authorizationToken);
        this.userService.updateUser(token.user);
        localStorage.setItem('profile', data.user.profile);
        this.router.navigate(['/']).then();
    }
}
