<section class="container">

    <div class="d-flex align-items-center ml-4 mt-4">
        <h1 class="title ">Saalve Bag<span class="subTitle ml-2">Histórico Chat</span></h1>
    </div>

    <div class="card mb-3">
        <div class="card-header pb-0">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link text-primary" href="javascript:void('chat');" [routerLink]="['/driver-chat']">Chat</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link active" href="javascript:void('history');">Histórico</a>
                </li>
            </ul>
        </div>
        <div class="card-body p-0 pb-3">
            <div class="card mt-3 bg-white mx-2 p-3">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-lg-6 col-xl-3">
                            <div class="input-material">
                                <input class="form-control js-daterangepicker" type="text" required id="period"/>
                                <label for="period">Período</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-6 col-xl-3">
                            <div class="input-material">
                                <input id="name" class="form-control" type="text" [(ngModel)]="modelFilter.name"
                                       required/>
                                <label for="name">Nome</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-6 col-xl-3">
                            <div class="input-material">
                                <input id="store" class="form-control" type="text" [(ngModel)]="modelFilter.store"
                                       required/>
                                <label for="store">Loja</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-6 col-xl-3">
                            <div class="input-material">
                                <select id="status" class="form-control" name="type" [(ngModel)]="modelFilter.status">
                                    <option [ngValue]="''">Todos</option>
                                    <option [ngValue]="'open'">Aberto</option>
                                    <option [ngValue]="'closed'">Finalizado</option>
                                </select>
                                <label for="status" class="mb-0 mr-3">Status</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-sm-12 text-right">
                            <a href="javascript:void('');" (click)="getExcel()" class="btn btn-ico btn-sm btn-success mr-xs-4 mb-3 mb-xs-0">
                                <svg-icon class="svg" src="assets/svg/excel.svg"></svg-icon>
                                Exportar Excel
                            </a>
                            <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mb-3 mb-xs-0" (click)="actionSearch()">
                                <svg-icon class="svg" src="assets/svg/search.svg"></svg-icon>
                                Buscar
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-3 mx-2 bg-white p-3">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th class="align-middle w-10" scope="col">Data/Hora</th>
                            <th class="align-middle" scope="col">Nome</th>
                            <th class="align-middle" scope="col">E-mail</th>
                            <th class="align-middle" scope="col">Loja</th>
                            <th class="align-middle w-10" scope="col">Status</th>
                            <th class="align-middle w-10" scope="col">Editar</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngIf="isNullOrUndefined(modelResult.results)">
                            <td colspan="7">
                                <p class="pt-5 pb-5 text-center">
                                    Nenhum registro encontrado.
                                </p>
                            </td>
                        </tr>
                        <tr *ngFor="let r of modelResult.results |
                            paginate: {itemsPerPage: modelResult.pageSize,
                             currentPage: modelResult.currentPage,
                             totalItems: modelResult.totalCount}">
                            <td>{{r.createdAt | date: 'dd/MM/yyyy HH:mm'}}</td>
                            <td>{{r.driver.name}}</td>
                            <td>{{r.driver.email}}</td>
                            <td>{{r.store.name}}</td>
                            <td>
                                <div class="badge badge-warning size-md" *ngIf="r.status === 'open'">Aberto</div>
                                <div class="badge badge-success size-md" *ngIf="r.status === 'closed'">Finalizado</div>
                            </td>
                            <td class="text-center">
                                <a href="javascript:void('Detalhes');" class="btn btn-sm btn-primary"
                                   (click)="actionDetail(r.id)">
                                    <svg-icon class="svg-secondary" src="assets/svg/eyes.svg"></svg-icon>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-center">
                    <pagination-controls
                            [previousLabel]="labels.previousLabel"
                            [nextLabel]="labels.nextLabel"
                            [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                            [screenReaderPageLabel]="labels.screenReaderPageLabel"
                            [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                            (pageChange)="pageChanged($event)"></pagination-controls>
                </div>
            </div>
        </div>
    </div>

</section>

<!--Modal-->
<div class="modal-custom modal" id="modalMessageDetail" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalContactDetail"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-0">
            <div class="modal-header bg-primary text-secondary">
                <div class="col-11">
                    <div class="row">
                        <div class="col-6">
                            <h6 class="mt-0 mb-1 text-truncate">{{model.driver.name}}</h6>
                            <p class="font-13 text-white mb-0">
                                <i class="mdi mdi-circle text-success mr-1 font-11"></i>
                                <span *ngIf="model.status == 'open'">Aberto</span>
                                <span *ngIf="model.status == 'closed'">Finalizado</span>
                            </p>
                        </div>
                        <div class="col-6 text-right">
                            <h6 class="mt-0 mb-1 text-truncate">{{model.store.name}}</h6>
                            <p class="font-13 text-white mb-0">
                                <span>{{model.order.orderCode}}</span>
                                <span class="mx-2">|</span>
                                <span>
                                    <b *ngIf="model.order.status === 'pending'">Pendente</b>
                                    <b *ngIf="model.order.status === 'accept'">Aceito</b>
                                    <b *ngIf="model.order.status === 'route'">Em Rota</b>
                                    <b *ngIf="model.order.status === 'deny'">Cancelado</b>
                                    <b *ngIf="model.order.status === 'fail'">Não <br>entregue</b>
                                    <b *ngIf="model.order.status === 'delivered'">Entregue</b>
                                    <b *ngIf="model.order.status === 'await'">Aguardando</b>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-1">
                    <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">X</span>
                    </a>
                </div>
            </div>
            <div class="modal-body modal-body--small p-0">
                <div class="conversation-list-card card">
                    <div class="card-body">
                        <div>
                            <div class="slimScrollDiv">
                                <ul class="conversation-list slimscroll" #scrollMe [scrollTop]="scrollMe.scrollHeight">
                                    <li *ngFor="let m of model.messages.slice().reverse()"
                                        [ngClass]="{'odd': !isNullOrUndefined(m.adminId)}">
                                        <div class="message-list">
                                            <!--<div class="chat-avatar">
                                                <img src="assets/images/users/user-2.jpg" alt="">
                                            </div>-->
                                            <div class="conversation-text">
                                                <div class="ctext-wrap">
                                                    <!--<span class="user-name">Margaret Clayton</span>-->
                                                    <p>{{m.message}}</p>
                                                </div>
                                                <span class="time">{{m.createdAt | date: 'HH:mm'}}</span>
                                            </div>
                                        </div>
                                    </li>

                                    <!--<li class="odd">
                                        <div class="message-list">
                                            <div class="conversation-text">
                                                <div class="ctext-wrap">
                                                    <span class="user-name">Nowak Helme</span>
                                                    <p>
                                                        Hi, How are you? What about our next meeting?
                                                    </p>
                                                </div>
                                                <span class="time">10:01</span>
                                            </div>
                                        </div>
                                    </li>  -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

