export class Store {

    id: string;
    name: string;
    address: string;
    lat: string;
    lng: string;

    constructor() {
    }
}
