<div class="d-block d-xl-flex">
    <div style="width: 100%;min-width: 60%" class="px-3">

        <div class="row mt-4 mb-3 px-3 px-md-0">
            <div class="col-2">
                <a href="javascript:$();" *ngIf="step != 0" (click)="handlerBack(step)">
                    <svg-icon src="assets/svg/arrow.svg"></svg-icon>
                </a>
            </div>
            <div class="col-7 col-lg-8 text-center">
                <span class="header-title">Novo Cadastro</span>
            </div>
            <div class="col-3 col-lg-2">
                <span class="header-date">Criado em <strong>10/03/2021</strong></span>
            </div>
        </div>

        <div class="py-3 bg-beige rounded pb-5">
            <div class="nav_step mx-auto mb-4">
                <ul class="nav d-flex justify-content-between" id="myTab" role="tablist">
                    <li class="text-center">
                        <a class="nav-link nav_step-item active" id="step1-tab" data-toggle="tab" href="#step1"
                           role="tab"
                           aria-controls="step1" aria-selected="true">
                            <span class="step">1</span>
                            <div class="position_item">
                                <span class="position_item-stage">Etapa</span>
                                <span class="position_item-name">Comercial Local</span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step2-tab" data-toggle="tab" href="#step2" role="tab"
                           aria-controls="step2" aria-selected="false">
                            <span class="step">2</span>
                            <div class="position_item">
                                <span class="position_item-stage">Etapa</span>
                                <span class="position_item-name">AR Local</span>
                            </div>
                        </a>
                    </li>
                    <li class=" text-center">
                        <a class="nav-link nav_step-item" id="step3-tab" data-toggle="tab" href="#step3" role="tab"
                           aria-controls="step3" aria-selected="false">
                            <span class="step">3</span>
                            <div class="position_item">
                                <span class="position_item-stage">Etapa</span>
                                <span class="position_item-name">AR International</span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step4-tab" data-toggle="tab" href="#step4" role="tab"
                           aria-controls="step4" aria-selected="false">
                            <span class="step">4</span>
                            <div class="position_item text-right">
                                <span class="position_item-stage">Etapa</span>
                                <span class="position_item-name">Artes</span>
                            </div>
                        </a>
                    </li>
                    <li class="text-center">
                        <a class="nav-link nav_step-item" id="step5-tab" data-toggle="tab" href="#step5" role="tab"
                           aria-controls="step5" aria-selected="false">
                            <span class="step">5</span>
                            <div class="position_item text-right">
                                <span class="position_item-stage">Etapa</span>
                                <span class="position_item-name">Cadastro</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>

        </div>

        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade" id="step1" role="tabpanel" aria-labelledby="step1-tab">
                <div class="mx-auto p-4" style="max-width: 442px;">
                    <div class="row mt-5">
                        <div class="col-12">
                            <div class="input-material">
                                <input class="form-control" id="name" type="text" required/>
                                <label for="name">Nome do projeto*</label>
                            </div>
                            <div class="input-material">
                                <input class="form-control" id="code" type="text" required/>
                                <label for="code">Código do modelo*</label>
                            </div>
                        </div>
                        <div class="col-12" style="margin-top: 14rem;">
                            <a href="javascript:void(0)" (click)="handlerNext(1)"
                               class="btn btn-block btn-warning"
                               type="button">Enviar</a>
                        </div>
                    </div>


                </div>
            </div>
            <div class="tab-pane fade" id="step2" role="tabpanel" aria-labelledby="step2-tab">
                <div class="p-4 px-0 px-md-5">
                    <div class="row px-0 px-md-5">
                        <div class="col-md-6">
                            <div class="input-material">
                                <input class="form-control" id="request" type="text" required/>
                                <label for="request">Motivo da soliciração</label>
                            </div>
                            <div class="input-material">
                                <select id="channel" class="form-control" name="type">
                                    <option>Selecione</option>
                                    <option>Selecione 2</option>
                                </select>
                                <label for="channel" class="mb-0 mr-3">Canal de distribuição</label>
                            </div>
                            <div class="input-material">
                                <select id="unity" class="form-control" name="type">
                                    <option>Selecione</option>
                                    <option>Selecione 2</option>
                                </select>
                                <label for="unity" class="mb-0 mr-3">Unidade de medida básica</label>
                            </div>
                        </div>
                        <div class="col-md-6 text-right">
                            <div class="text-left mb-2">
                                <span class="text-secondary">Descritivo</span>
                            </div>
                            <textarea class=" input-material" id="description" style="width: inherit; resize: none"
                                      rows="7"
                                      maxlength="40" required></textarea>
                            <label for="description">Máximo de 40 caracteres</label>
                        </div>
                    </div>
                    <div class="row px-0 px-md-5">
                        <div class="col-lg-6">
                            <div class="input-material">
                                <input class="form-control" id="register" type="text" required/>
                                <label for="register">Nome do detentor do registro</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material">
                                <input class="form-control" id="registerMinistry" type="text" required/>
                                <label for="registerMinistry">Registro Ministério da Saúde</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material">
                                <select id="Dispatched" class="form-control" name="type">
                                    <option>Selecione</option>
                                    <option>Selecione 2</option>
                                </select>
                                <label for="Dispatched" class="mb-0 mr-3">Expedido por</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material">
                                <input class="form-control" id="validity" type="text" required/>
                                <label for="validity">Validade do registro</label>
                            </div>
                        </div>
                    </div>
                    <div class="row px-0 px-md-5">
                        <div class="col-xl-4">
                            <div class="input-material">
                                <input class="form-control" id="material" type="text" required/>
                                <label for="material">Classificação fiscal do material</label>
                            </div>
                        </div>
                        <div class="col-xl-4">
                            <div class="input-material">
                                <input class="form-control" id="material2" type="text" required/>
                                <label for="material2">Classificação fiscal do material</label>
                            </div>
                        </div>
                        <div class="col-xl-4">
                            <div class="input-material">
                                <input class="form-control" id="group" type="text" required/>
                                <label for="group">Grupo de material 1</label>
                            </div>
                        </div>
                        <div class="col-xl-6 col-xxl-5">
                            <div class="input-material">
                                <input class="form-control" id="Manufacturer" type="text" required/>
                                <label for="Manufacturer">Fabricante para comprar produto ou Subcontratação</label>
                            </div>
                        </div>
                        <div class="col-xl-6 col-xxl-4">
                            <div class="input-material">
                                <select id="Hierarchy" class="form-control" name="type">
                                    <option>Selecione</option>
                                    <option>Selecione 2</option>
                                </select>
                                <label for="Hierarchy" class="mb-0 mr-3">Hierarquia</label>
                            </div>
                        </div>
                        <div class="col-xl-6 col-xxl-3">
                            <div class="input-material">
                                <select id="Deadline" class="form-control" name="type">
                                    <option>Selecione</option>
                                    <option>Selecione 2</option>
                                </select>
                                <label for="Deadline" class="mb-0 mr-3">Prazo de validade</label>
                            </div>
                        </div>
                    </div>
                    <div class="row px-0 px-md-5">
                        <div class="col-12 mx-auto" style="max-width: 400px">
                            <a href="javascript:void(0)" (click)="handlerNext(2)"
                               class="btn btn-block btn-warning"
                               type="button">Enviar</a>
                        </div>
                    </div>

                </div>

            </div>
            <div class="tab-pane fade show active" id="step3" role="tabpanel" aria-labelledby="step3-tab">
                <div class="p-4 px-0 px-md-5">
                    <div class="row px-0 px-md-5">
                        <div class="col-md-6">
                            <div class="input-material">
                                <input class="form-control" id="request2" type="text" required/>
                                <label for="request2">Motivo da soliciração</label>
                            </div>
                            <div class="input-material">
                                <select id="channel2" class="form-control" name="type">
                                    <option>Selecione</option>
                                    <option>Selecione 2</option>
                                </select>
                                <label for="channel2" class="mb-0 mr-3">Canal de distribuição</label>
                            </div>
                            <div class="input-material">
                                <select id="unity2" class="form-control" name="type">
                                    <option>Selecione</option>
                                    <option>Selecione 2</option>
                                </select>
                                <label for="unity2" class="mb-0 mr-3">Unidade de medida básica</label>
                            </div>
                        </div>
                        <div class="col-md-6 text-right">
                            <div class="text-left mb-2">
                                <span class="text-secondary">Descritivo</span>
                            </div>
                            <textarea class=" input-material" id="description2" style="width: inherit; resize: none"
                                      rows="7"
                                      maxlength="40" required></textarea>
                            <label for="description2">Máximo de 40 caracteres</label>
                        </div>
                    </div>
                    <div class="row px-0 px-md-5">
                        <div class="col-lg-6">
                            <div class="input-material">
                                <input class="form-control" id="register2" type="text" required/>
                                <label for="register2">Nome do detentor do registro</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material">
                                <input class="form-control" id="registerMinistry2" type="text" required/>
                                <label for="registerMinistry2">Registro Ministério da Saúde</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material">
                                <select id="Dispatched2" class="form-control" name="type">
                                    <option>Selecione</option>
                                    <option>Selecione 2</option>
                                </select>
                                <label for="Dispatched2" class="mb-0 mr-3">Expedido por</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material">
                                <input class="form-control" id="validity2" type="text" required/>
                                <label for="validity2">Validade do registro</label>
                            </div>
                        </div>
                    </div>
                    <div class="row px-0 px-md-5">
                        <div class="col-xl-4">
                            <div class="input-material">
                                <input class="form-control" id="material2.1" type="text" required/>
                                <label for="material2.1">Classificação fiscal do material</label>
                            </div>
                        </div>
                        <div class="col-xl-4">
                            <div class="input-material">
                                <input class="form-control" id="material2.2" type="text" required/>
                                <label for="material2.2">Classificação fiscal do material</label>
                            </div>
                        </div>
                        <div class="col-xl-4">
                            <div class="input-material">
                                <input class="form-control" id="group2" type="text" required/>
                                <label for="group2">Grupo de material 1</label>
                            </div>
                        </div>
                        <div class="col-xl-6 col-xxl-5">
                            <div class="input-material">
                                <input class="form-control" id="Manufacturer2" type="text" required/>
                                <label for="Manufacturer2">Fabricante para comprar produto ou Subcontratação</label>
                            </div>
                        </div>
                        <div class="col-xl-6 col-xxl-4">
                            <div class="input-material">
                                <select id="Hierarchy2" class="form-control" name="type">
                                    <option>Selecione</option>
                                    <option>Selecione 2</option>
                                </select>
                                <label for="Hierarchy2" class="mb-0 mr-3">Hierarquia</label>
                            </div>
                        </div>
                        <div class="col-xl-6 col-xxl-3">
                            <div class="input-material">
                                <select id="Deadline2" class="form-control" name="type">
                                    <option>Selecione</option>
                                    <option>Selecione 2</option>
                                </select>
                                <label for="Deadline2" class="mb-0 mr-3">Prazo de validade</label>
                            </div>
                        </div>
                    </div>
                    <div class="row px-0 px-md-5">
                        <div class="col-12 mx-auto" style="max-width: 400px">
                            <a href="javascript:void(0)" (click)="handlerNext(3)"
                               class="btn btn-block btn-warning"
                               type="button">Enviar</a>
                        </div>
                    </div>

                </div>
            </div>
            <div class="tab-pane fade" id="step4" role="tabpanel" aria-labelledby="step4-tab">
                <div class="mx-auto p-4" style="max-width: 442px;">
                    <div class="row mt-5">
                        <div class="col-12">
                            <div class="input-material">
                                <input class="form-control" id="EAN" type="text" required/>
                                <label for="EAN">EAN 13</label>
                            </div>
                            <div class="input-material">
                                <input class="form-control" id="DUM" type="text" required/>
                                <label for="DUM">DUM 14</label>
                            </div>
                        </div>
                    </div>
                    <hr style="margin-top: 6rem;">
                    <div class="row">
                    <span style="margin-bottom: 2rem;">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                        ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo.
                    </span>
                        <div class="col-12">
                            <a href="javascript:void(0)" (click)="handlerNext(4)"
                               class="btn btn-block btn-warning"
                               type="button">Enviar</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="step5" role="tabpanel" aria-labelledby="step5-tab">
                <div class="row">
                    <div class="col-md-7">
                        <div class="px-3 py-4 text-center">
                            <div class="card bg-warning border-0 shadow-none">
                                <div class="row p-3">
                                    <strong class="col size-md">Criado por</strong>
                                    <span class="col text-right size-md">09/03/2021</span>
                                    <span class="col-12 size-xm pl-4">Marta Saldanha de Puela</span>
                                </div>
                            </div>
                            <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
                                <div class="row p-3">
                                    <strong class="col-12">Comercial Local</strong>
                                    <div class="col-12 pl-4">
                                        <div class="row">
                                        <span class="col-12 mt-2">Código: <span
                                                class="text-primary">368514</span></span>
                                            <span class="col-12 mt-2">Descrição:</span>
                                            <span class="col-12 mt-2 text-primary">
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                                            tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo.
                                        </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
                                <div class="row p-3">
                                    <strong class="col-12">AR Local</strong>
                                    <div class="col-12 pl-4">
                                        <div class="row">
                                            <span class="col-12 mt-2">Motivo da solicitação: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary text-uppercase">CT</span>
                                            <span class="col-12 mt-2">Canal de distribuição: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary text-uppercase">CT</span>
                                            <span class="col-12 mt-2">Descrição: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary text-uppercase">DEXMEDETOMIDINA 100MCG INJ 5FA AL</span>
                                            <span class="col-12 mt-2">Unidade de medida básica: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary text-uppercase">CT</span>
                                            <span class="col-12 mt-2">Nome do detentor do registro: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary">Eurofarma Uruguay S.A.</span>
                                            <span class="col-12 mt-2">Expedido por: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary text-uppercase">Eurofarma</span>
                                            <span class="col-12 mt-2">Registro Ministério da Saúde: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary text-uppercase">Lorem ipsum dolor sit amet, consetetur</span>
                                            <span class="col-12 mt-2">Validade do registro: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary text-uppercase">25/06/2021</span>
                                            <span class="col-12 mt-2">Fabricante para comprar produto ou Subcontratação: </span>
                                            <span class="col-12 mt-2 pl-4 text-primary text-uppercase">Eurofarma Laboratorios S.A.</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-5">
                        <div class="pl-5 my-4 pt-4 pr-5 border-left border-gray-text">
                            <div class="input-material">
                                <input class="form-control" id="SAP" type="text" required/>
                                <label for="SAP">Códogo do SAP</label>
                            </div>
                            <div class="text-left mb-2">
                                <span class="text-secondary">Descritivo</span>
                            </div>
                            <textarea class="input-material mb-0" id="description3" style="width: 100%; resize: none"
                                      rows="7"
                                      maxlength="40" required></textarea>
                            <label class=" w-100 text-right" for="description3">Máximo de 40 caracteres</label>
                            <a href="javascript:void(0)" (click)="handlerConfirm()"
                               class="btn btn-block btn-warning mt-5"
                               type="button">Aprovar</a>
                            <a href="javascript:void(0)" (click)="handlerNext(0)"
                               class="btn btn-block btn-outline-primary mt-3"
                               type="button">Reprovar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-beige px-3 py-4" *ngIf="step==3" style="width: 100%;min-width: 40%;overflow: auto">
        <div class="card bg-warning border-0 shadow-none">
            <div class="row p-3">
                <strong class="col size-md">Criado por</strong>
                <span class="col text-right size-md">09/03/2021</span>
                <span class="col-12 size-xm pl-4">Marta Saldanha de Puela</span>
            </div>
        </div>
        <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
            <div class="row p-3">
                <strong class="col-12">Comercial Local</strong>
                <div class="col-12 pl-4">
                    <div class="row">
                        <span class="col-12 mt-2">Código: <span class="text-primary">368514</span></span>
                        <span class="col-12 mt-2">Descrição:</span>
                        <span class="col-12 mt-2 text-primary">
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                            tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo.
                        </span>
                    </div>
                </div>

            </div>
        </div>
        <div class="card bg-warning-variant mt-3 size-md border-0 shadow-none ">
            <div class="row p-3">
                <strong class="col-12">AR Local</strong>
                <div class="col-12 pl-4">
                    <div class="row">
                        <span class="col-12 mt-2">Motivo da solicitação: </span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">CT</span>
                        <span class="col-12 mt-2">Canal de distribuição: </span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">CT</span>
                        <span class="col-12 mt-2">Descrição: </span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">DEXMEDETOMIDINA 100MCG INJ 5FA AL</span>
                        <span class="col-12 mt-2">Unidade de medida básica: </span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">CT</span>
                        <span class="col-12 mt-2">Nome do detentor do registro: </span>
                        <span class="col-12 mt-2 pl-4 text-primary">Eurofarma Uruguay S.A.</span>
                        <span class="col-12 mt-2">Expedido por: </span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">Eurofarma</span>
                        <span class="col-12 mt-2">Registro Ministério da Saúde: </span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">Lorem ipsum dolor sit amet, consetetur</span>
                        <span class="col-12 mt-2">Validade do registro: </span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">25/06/2021</span>
                        <span class="col-12 mt-2">Fabricante para comprar produto ou Subcontratação: </span>
                        <span class="col-12 mt-2 pl-4 text-primary text-uppercase">Eurofarma Laboratorios S.A.</span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!--Modal-->
<div class="modal-custom modal fade" id="modalNoteResume" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body modal-body--small">
                <div class="row flex-column">
                    <div class="text-center mx-auto">
                        <svg-icon src="assets/svg/confirm.svg"></svg-icon>
                    </div>
                    <div class="text-center mx-auto font-weight-bold text-primary mt-5 size-xl" style="max-width: 369px;line-height: 0.9;">
                        Ok, seus dados foram salvos com sucesso.
                    </div>
                    <div class="text-center mx-auto size-xm text-gray-text mt-4" style="max-width: 404px;">
                        Um e-mail foi enviado para o próximo responsável, que continuara o processo de cadastro.
                    </div>
                    <div class="mx-auto">
                        <a href="javascript:$('#modalNoteResume').modal('hide')" [routerLink]="'/'" (click)="handlerConfirm()"
                           class="btn btn-block btn-warning mt-5"
                           type="button">fechar</a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

