import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {ResultContact} from '../../model/contact/result-contact';
import {FilterContact} from '../../model/contact/filter-contact';
import {ContactService} from '../../service/contact.service';
import {Contact} from '../../model/contact/contact';
import {environment} from '../../../environments/environment';

declare var $;
declare var moment;

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent extends BaseComponent implements OnInit {

    modelResult: ResultContact = new ResultContact();
    modelFilter: FilterContact = new FilterContact();
    model: Contact = new Contact();
    currentPage = 1;

    labels: any = {
        previousLabel: ' ',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public contactService: ContactService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.modelFilter.start = moment().subtract(15, 'days');
        this.modelFilter.end = moment();
        this.actionSearch();
        this.setDatePicker();
    }

    actionSearch() {
        this.loading.show();
        this.contactService.search(this.modelFilter, this.currentPage).subscribe({
            next: data => {
                this.modelResult = data as ResultContact;
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    pageChanged(event) {
        this.currentPage = event;
        this.actionSearch();
    }

    toggleModalContactDetail(model) {
        if (!this.isNullOrUndefined(model.id)) {
            $('#modalContactDetail').modal('show');
            this.model = Object.assign({}, model);
        } else {
            $('#modalContactDetail').modal('hide');
            this.model = new Contact();
        }
    }

    setDatePicker() {
        const data = moment().subtract(15, 'days').format('DD/MM/yyyy');
        $('.js-daterangepicker').daterangepicker({
            showDropdowns: true,
            startDate: data,
            locale: {
                format: 'DD/MM/YYYY',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                daysOfWeek: [
                    'Dom',
                    'Seg',
                    'Ter',
                    'Qua',
                    'Qui',
                    'Sex',
                    'Sab'
                ],
                monthNames: [
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                ],
                firstDay: 1
            }
        }, (start, end, label) => {
            this.modelFilter.start = start.utcOffset(0, true).format();
            this.modelFilter.end = end.utcOffset(0, true).format();
        });
    }

    actionAnswer() {
        if (super.isNullOrUndefined(this.model.answer)) {
            this.showMessage('Atenção', `Preencha a resposta para prosseguir!`, 'warning');
            return;
        }

        this.loading.show();
        this.contactService.update(this.model).subscribe({
            next: data => {
                this.model.status = 'answered';
                this.actionSearch();
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;
        const start = moment(this.modelFilter.start).format('YYYY-MM-DD');
        const end = moment(this.modelFilter.end).format('YYYY-MM-DD');

        let url = `${environment.base_url}/Support/search/excel?token=${token}`;

        if (!super.isNullOrUndefined(this.modelFilter.name)) {
            url += ('&name=' + this.modelFilter.name);
        }

        if (!super.isNullOrUndefined(this.modelFilter.email)) {
            url += ('&email=' + this.modelFilter.email);
        }

        if (!super.isNullOrUndefined(this.modelFilter.status)) {
            url += ('&status=' + this.modelFilter.status);
        }

        if (!super.isNullOrUndefined(start)) {
            url += ('&start=' + start);
        }

        if (!super.isNullOrUndefined(end)) {
            url += ('&end=' + end);
        }
        // console.log(encodeURI(url));
        window.open(encodeURI(url));
    }
}
