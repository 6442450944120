<section class="container">

    <div class="d-flex align-items-center ml-4 mt-4">
        <h1 class="title ">Saalve Bag<span class="subTitle ml-2"> Chat Entregador</span></h1>
    </div>

    <div class="card mb-3">
        <div class="card-header pb-0">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link active" href="javascript:void('chat');">Chat</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link text-primary" href="javascript:void('history');"
                       [routerLink]="['/driver-chat-history']">Histórico</a>
                </li>
            </ul>
        </div>
        <div class="card-body p-0">
            <div class="row my-2 mx-0 p-0">
                <div [ngClass]="{'col-12': isNullOrUndefined(model.id), 'col-xl-4 col-lg-5': !isNullOrUndefined(model.id)}">
                    <div class="card chat-list-card mb-3 mb-xl-0">
                        <div class="card-body">
                            <div class="media">
                                <!--<div class="mr-2 align-self-center">
                                    <img src="assets/images/users/user-1.jpg" alt="" class="rounded-circle avatar-sm">
                                </div>-->
                                <div class="media-body">
                                    <h5 class="mt-0 mb-1">Chat</h5>
                                    <!--<p class="font-13 text-muted mb-0">Fulano da Silva</p>-->
                                </div>
                            </div>

                            <hr class="my-3">

                            <div class="search-box chat-search-box d-flex">
                                <div class="col-10 p-0">
                                    <input type="text" name="search" class="form-control" placeholder="Buscar..."
                                           [(ngModel)]="modelFilter.name">
                                </div>
                                <div class="col-auto d-flex align-items-center">
                                    <button type="submit" class="btn btn-primary btn-block p-2"
                                            (click)="actionSearch()">
                                        <svg-icon class="svg-white" src="assets/svg/search.svg"></svg-icon>
                                    </button>
                                </div>
                            </div>

                            <hr class="my-3">

                            <div class="col-12 mt-5" *ngIf="isNullOrUndefined(modelResult.results)">
                                <p class="pt-5 pb-5 text-center">
                                    Nenhum registro encontrado.
                                </p>
                            </div>
                            <div class="">
                                <div class="slimScrollDiv">
                                    <ul class="list-unstyled chat-list slimscroll mb-0">
                                        <li *ngFor="let r of modelResult.results |
                                            paginate: {itemsPerPage: modelResult.pageSize,
                                             currentPage: modelResult.currentPage,
                                             totalItems: modelResult.totalCount}">
                                            <a href="javascript:void('');" (click)="actionDetail(r.id)" class="pl-0">
                                                <div class="media">
                                                    <div class="chat-user-img align-self-center mr-2"
                                                         [ngClass]="{'active': model.id == r.id}">
                                                        <img src="{{!isNullOrUndefined(r.driver.photo) ? r.driver.photo : 'https://via.placeholder.com/30'}}"
                                                             class="rounded-circle avatar-sm" alt="">
                                                    </div>

                                                    <div class="media-body overflow-hidden">
                                                        <h5 class="text-truncate text-primary font-14 mt-0 mb-1">{{r.driver.name}}</h5>
                                                        <p class="text-truncate mb-0">{{r.messages[r.messages.length - 1]?.message}}</p>
                                                    </div>
                                                    <div class="font-11 text-right">
                                                        {{handlerMin(r.messages[r.messages.length - 1]?.createdAt)}}
                                                        <br>
                                                        <small class="badge pt-2 pb-1 pl-2 pr-2 badge-warning"
                                                               *ngIf="isNullOrUndefined(r.admin)">
                                                            Novo
                                                        </small>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-8 col-lg-7" [ngClass]="{'d-none': isNullOrUndefined(model.id)}">
                    <div class="conversation-list-card card">
                        <div class="card-body">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-1 p-0">
                                        <div class="chat-user-img align-self-center">
                                            <img src="{{!isNullOrUndefined(model.driver.photo) ? model.driver.photo : 'https://via.placeholder.com/30'}}"
                                                 class="rounded-circle avatar-sm" alt="">
                                        </div>
                                    </div>
                                    <div class="col-5 px-2">
                                        <h6 class="mt-0 mb-1 text-truncate text-primary">{{model.driver.name}}</h6>
                                        <p class="font-13 text-primary mb-0">
                                            <i class="mdi mdi-circle text-success mr-1 font-11"></i>
                                            <span *ngIf="model.status == 'open'">Aberto</span>
                                            <span *ngIf="model.status == 'closed'">Finalizado</span>
                                        </p>
                                    </div>
                                    <div class="col-3 text-right px-2">
                                        <h6 class="mt-0 mb-1 text-truncate text-primary">{{model.store.name}}</h6>
                                        <p class="font-13 text-primary mb-0">
                                            <span>{{model.order.orderCode}}</span>
                                            <span class="mx-2">|</span>
                                            <span>
                                                <b *ngIf="model.order.status === 'pending'">Pendente</b>
                                                <b *ngIf="model.order.status === 'accept'">Aceito</b>
                                                <b *ngIf="model.order.status === 'route'">Em Rota</b>
                                                <b *ngIf="model.order.status === 'deny'">Cancelado</b>
                                                <b *ngIf="model.order.status === 'fail'">Não entregue</b>
                                                <b *ngIf="model.order.status === 'delivered'">Entregue</b>
                                                <b *ngIf="model.order.status === 'await'">Aguardando</b>
                                            </span>
                                        </p>
                                    </div>
                                    <div class="col-3 p-0 text-right">
                                        <a href="javascript:void('');" (click)="actionCloseChat()"
                                           class="btn btn-ico btn-sm btn-primary">
                                            <svg-icon class="svg-secondary" [svgStyle]="{width: 15, height: 15}" src="assets/svg/close.svg"></svg-icon>
                                            Finalizar chat
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <hr class="my-3">

                            <div>
                                <div class="slimScrollDiv">
                                    <ul class="conversation-list slimscroll" #scrollMe
                                        [scrollTop]="scrollMe.scrollHeight">
                                        <!--<li>
                                            <div class="chat-day-title">
                                                <span class="title">Today</span>
                                            </div>
                                        </li>-->
                                        <li *ngFor="let m of model.messages.slice().reverse()"
                                            [ngClass]="{'odd': !isNullOrUndefined(m.adminId)}">
                                            <div class="message-list">
                                                <div class="conversation-text">
                                                    <div class="ctext-wrap">
                                                        <p>{{m.message}}</p>
                                                    </div>
                                                    <span class="time">{{m.createdAt | date: 'HH:mm'}}</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="p-3 conversation-input border-top">
                            <div class="row">
                                <div class="col">
                                    <input type="text" class="form-control" [(ngModel)]="message"
                                           (keyup.enter)="actionSend()" placeholder="Digite uma mensagem...">
                                </div>
                                <div class="col-auto d-flex">
                                    <button type="submit" class="btn btn-primary btn-block p-2" (click)="actionSend()">
                                        <span class="d-none d-sm-inline-block mr-2">Enviar</span>
                                        <svg-icon class="svg-white" src="assets/svg/send.svg"></svg-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>


