<ngx-spinner
        bdColor="#ff5c00"
        size="medium"
        color="#fff"
        type="ball-spin"
>
</ngx-spinner>

<app-header></app-header>

<div class="app-body">

    <app-sidebar></app-sidebar>
    <div class="main px-0 pb-0">
        <router-outlet></router-outlet>
    </div>

    <div class="modal fade bs-modal-sm in" id="changePassword" tabindex="-1" role="dialog" aria-hidden="true"
         data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content p-0">
                <div class="modal-header bg-primary text-white">
                    <h4 class="modal-title">Alterar Senha</h4>
                    <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">X</span>
                    </a>
                </div>
                <div class="modal-body card-header">
                    <form autocomplete="off">
                        <div class="input-material">
                            <input type="password" class="form-control"
                                   name="password" id="password"
                                   [(ngModel)]="request.password" required/>
                            <label for="password">Senha Atual</label>
                        </div>
                        <div class="input-material">
                            <input type="password" class="form-control" name="newPassword" id="newPassword"
                                   maxlength="50"
                                   [(ngModel)]="request.newpassword" required/>
                            <label for="newPassword">Nova Senha</label>
                        </div>
                        <div class="input-material">
                            <input type="password" class="form-control"
                                   name="rePassword" id="rePassword"
                                   [(ngModel)]="request.confirmPassword" required/>
                            <label for="rePassword">Repetir Senha</label>
                        </div>
                    </form>
                    <hr>
                    <div class="col-md-12 col-lg-12 p-0">
                        <div class="mx-auto">
                            <a href="javascript:void(0)" class="btn btn-block btn-save mt-4 px-5" type="button"
                               (click)="onValidateNewPassword()">Mudar Senha</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

