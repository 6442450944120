// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // base_url: 'https://57323e81-8565-4891-bb3c-02096f436451.mock.pstmn.io/api/v1',
    base_url: 'https://saalve-bag-service.duka.digital/api/v1',
    base_socket_url: 'https://saalve-bag-service.duka.digital',
    // base_url: 'http://localhost:13172/api/v1',
    // base_socket_url: 'http://localhost:13172',
    client_id: 'fdb06246-8a6a-4ec1-a374-7975dd056323',
    version: 1,
    build: 1,
    firebase: {
        apiKey: "AIzaSyCaZe-KtDLaXLlti9aS2oZSJUbwZmBZMgM",
        authDomain: "saalve-8c9e6.firebaseapp.com",
        projectId: "saalve-8c9e6",
        storageBucket: "saalve-8c9e6.appspot.com",
        messagingSenderId: "232546397744",
        appId: "1:232546397744:web:3910f3e01f37882a1847f5",
        measurementId: "G-68FRPB92Y4"
    },
    googleKey: 'AIzaSyCaZe-KtDLaXLlti9aS2oZSJUbwZmBZMgM',
    googleUrlStaticMap: 'https://maps.googleapis.com/maps/api/staticmap?key='
    + 'AIzaSyCaZe-KtDLaXLlti9aS2oZSJUbwZmBZMgM' + '&zoom=15&size=800x500&maptype=roadmap&markers=color:red%7C',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
