<section class="container">

    <div class="d-flex align-items-center flex-wrap mx-2 mt-4 justify-content-between">
        <h1 class="title ">Saalve Bag <span class="subTitle ml-2">Delivery Detalhe</span></h1>

        <a *ngIf="!production" href="javascript:void('Avaliar')"
           (click)="goToEvaluation()"
           class="btn-primary back_button">
            AVALIAÇÃO
        </a>
        <a *ngIf="!production" href="javascript:void('Avaliar')" (click)="goToFollow()" class="btn-primary back_button">
            ACOMPANHAMENTO
        </a>
        <a href="javascript:void('Entregas');" (click)="goBack()" class="btn-primary back_button">VOLTAR</a>
    </div>

    <!--Informações do pedido-->
    <div class="align-items-center d-block d-sm-flex justify-content-between ml-4 mt-4">
        <h5 class="text-primary">
            Informações do Pedido
            <span class="pl-2 pr-3">nº {{modelDelivery.orderCode}}</span>
            <small class="badge pt-2 pb-1 px-2"
                   [ngClass]="{'badge-quaternary': modelDelivery.status === 'accept',
                                        'badge-success': modelDelivery.status === 'delivered',
                                        'badge-green-variant': modelDelivery.status === 'route',
                                        'badge-danger': modelDelivery.status === 'fail',
                                        'badge-info': modelDelivery.status === 'await',
                                        'badge-warning': modelDelivery.status === 'pending'}">
                <b *ngIf="modelDelivery.status === 'accept'">Aceito</b>
                <b *ngIf="modelDelivery.status === 'delivered'">Entregue</b>
                <b *ngIf="modelDelivery.status === 'route'">Saiu para entrega</b>
                <b *ngIf="modelDelivery.status === 'fail'">Cancelado</b>
                <b *ngIf="modelDelivery.status === 'await'">Aguardando</b>
                <b *ngIf="modelDelivery.status === 'pending'">Pendente</b>
            </small>
        </h5>

        <h6 class="text-muted">
            Data do Pedido:
            <span class="text-primary font-weight-bold pl-2 pr-3">
                {{modelDelivery.dateTime | date: 'dd/MM/yyyy HH:mm'}}
            </span>
        </h6>
    </div>

    <div class="row mx-2 d-flex justify-content-between">
        <div class="card mt-3 bg-white col-lg-3 p-0">
            <div class="row mx-0 p-3 bg-secondary rounded-top">
                <h5 class="text-primary mb-0 px-0 col-12 text-truncate">Loja</h5>
            </div>
            <div class="card-body">
                <h6 class="text-primary mb-2 font-weight-bold">{{modelDelivery.store?.name}}</h6>
                <div>
                    <p class="text-muted mb-1">Endereço</p>
                    <p class="mb-0 font-weight-bold text-primary col-12 p-0">
                        {{modelDelivery.store?.address}}
                    </p>
                </div>
            </div>
        </div>
        <div class="card mt-3 bg-white col-lg-3 p-0">
            <div class="row mx-0 p-3 bg-secondary rounded-top">
                <h5 class="text-primary mb-0 px-0 col-12 text-truncate">Cliente</h5>
            </div>
            <div class="card-body">
                <!--<h6 class="text-primary mb-2 font-weight-bold">{{modelDelivery.store?.name}}</h6>-->
                <div>
                    <p class="text-muted mb-1">Endereço</p>
                    <p class="mb-0 font-weight-bold text-primary col-12 p-0">
                        {{modelDelivery.address}}, {{modelDelivery.number}}
                        - {{modelDelivery.region}} - {{modelDelivery.city}}
                        /{{modelDelivery.state}}
                        <ng-container *ngIf="!isNullOrUndefined(modelDelivery.observation)">
                            - {{modelDelivery.observation}}</ng-container>
                        <ng-container *ngIf="!isNullOrUndefined(modelDelivery.referencePoint)">
                            - {{modelDelivery.referencePoint}}</ng-container>
                    </p>
                    <p class="text-muted mb-1">Distância de Entrega</p>
                    <p class="mb-0 font-weight-bold text-primary col-12 p-0">
                        {{modelDelivery.deliveryDistanceText}}
                    </p>
                </div>
            </div>
        </div>
        <div class="card mt-3 bg-white col-lg-5 p-0">
            <div class="row mx-0 p-3 bg-primary rounded-top">
                <h5 class="text-secondary mb-0 px-0 col-12 text-truncate">Pagamento</h5>
            </div>
            <div class="card-body">
                <div class="row mb-3">
                    <div class="col-6 px-2">
                        <p class="text-muted mb-1">Forma de Pagamento</p>
                        <h5 class="text-primary mb-0">{{modelDelivery.payment}}</h5>
                    </div>
                    <div class="col-6 px-2">
                        <p class="text-muted mb-1">Troco</p>
                        <h5 class="text-primary mb-0">{{(modelDelivery.paymentValue - modelDelivery.total) | currency :'BRL': true: '1.2-2': 'pt-BR'}}</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 px-2">
                        <p class="text-muted mb-1">Subtotal</p>
                        <h5 class="text-primary mb-0">{{(modelDelivery.total - modelDelivery.tax) | currency :'BRL': true: '1.2-2': 'pt-BR'}}</h5>
                    </div>
                    <div class="col-4 px-2 border-right">
                        <p class="text-muted mb-1">Taxa</p>
                        <h5 class="text-primary mb-0">{{modelDelivery.tax | currency :'BRL': true: '1.2-2': 'pt-BR'}}</h5>
                    </div>
                    <div class="col-4 px-2">
                        <p class="text-muted mb-1">Total</p>
                        <h5 class="text-primary mb-0">{{modelDelivery.total | currency :'BRL': true: '1.2-2': 'pt-BR'}}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Produtos-->
    <div class="align-items-center flex-wrap ml-4 mt-4">
        <h5 class="text-primary">
            Produtos
        </h5>
    </div>
    <div class="card mt-3 bg-white mx-2">
        <div class="card-body">
            <div class="mb-1 table-responsive">
                <table class="table table-striped">
                    <thead class="text-muted">
                    <tr>
                        <th class="w-25 text-truncate">Nome</th>
                        <th class="w-25 text-truncate text-center">Categoria</th>
                        <th class="w-25 text-truncate text-center">Subcategoria</th>
                        <th class="text-truncate text-center">Quantidade</th>
                        <th class="text-truncate text-center">Preço</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="text-muted" *ngFor="let p of modelDelivery.product; let i = index"
                        [ngClass]="{'bg-secondary': handlerParImpar(i)}">
                        <td class="w-25 text-truncate">{{p.name}}</td>
                        <td class="w-25 text-truncate text-center">{{p.groupName}}</td>
                        <td class="w-25 text-truncate text-center">{{p.subGroupName}}</td>
                        <td class="text-truncate text-center">{{p.qty}}</td>
                        <td class="text-truncate text-center">{{p.price | currency :'BRL': true: '1.2-2': 'pt-BR'}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <table class="table table-striped d-none">
                <thead>
                <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Categoria</th>
                    <th scope="col">SubCategoria</th>
                    <th scope="col" class="text-center">Quant.</th>
                    <th scope="col">Preço</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let p of modelDelivery.product">
                    <td>{{p.name}}</td>
                    <td>{{p.groupName}}</td>
                    <td>{{p.subGroupName}}</td>
                    <td class="text-center">{{p.qty}}</td>
                    <td>{{p.price | currency :'BRL': true: '1.2-2': 'pt-BR'}}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td colspan="3">Sub-Total</td>
                    <td class="text-center">{{qtd}}</td>
                    <td>{{totalPrice | currency :'BRL': true: '1.2-2': 'pt-BR'}}</td>
                </tr>
                </tfoot>
            </table>
        </div>
        <div class="row mx-0 p-3 bg-purple rounded-bottom justify-content-end">
            <div class="col-sm-6 col-lg-4 px-0">
                <div class="row text-primary mb-2 mx-0">
                    <div class="col-6 px-0">TOTAL DE ITENS</div>
                    <div class="col-6 text-right">{{qtd}}</div>
                </div>
                <div class="row text-primary mb-2 mx-0">
                    <div class="col-6 px-0">SUB-TOTAL</div>
                    <div class="col-6 text-right">{{(modelDelivery.total - modelDelivery.tax) | currency :'BRL': true: '1.2-2': 'pt-BR'}}</div>
                </div>
                <div class="row text-primary mb-2 mx-0">
                    <div class="col-6 px-0">TAXA DE ENTREGA</div>
                    <div class="col-6 text-right">{{modelDelivery.tax | currency :'BRL': true: '1.2-2': 'pt-BR'}}</div>
                </div>
                <hr class="bg-primary my-3 mx-0">
                <div class="row text-primary mx-0">
                    <div class="col-6 px-0 font-weight-bold">TOTAL</div>
                    <div class="col-6 text-right font-weight-bold">
                        {{modelDelivery.total | currency :'BRL': true: '1.2-2': 'pt-BR'}}</div>
                </div>
            </div>
        </div>
    </div>

    <!--Entregadores-->
    <div class="align-items-center flex-wrap ml-4 mt-4">
        <h5 class="text-primary">
            Entregadores
        </h5>
    </div>
    <div class="card my-3 bg-white mx-2">
        <div class="card-body">
            <ng-container *ngIf="isNullOrUndefined(modelDelivery.delivery)">
                <p class="my-3 text-center w-100">Nenhum entregador encontrado no momento!</p>
            </ng-container>
            <ng-container *ngIf="!isNullOrUndefined(modelDelivery.delivery)">
                <div class="col-12 p-2" *ngFor="let d of modelDelivery.delivery; let i = index"
                     [ngClass]="{'bg-secondary': handlerParImpar(i)}">
                    <div class="row mx-0">
                        <div class="badge px-1 pt-1 col-auto" [ngClass]="{
                                        'badge-quaternary': d.status === 'accept',
                                        'badge-danger': d.status === 'deny',
                                        'badge-warning': d.status === 'pending',
                                        'badge-green-variant': d.status === 'route',
                                        'badge-orange': d.status === 'fail',
                                        'badge-success': d.status === 'delivered',
                                        'badge-info': d.status === 'await'}">
                            <b style="vertical-align: sub" *ngIf="d.status === 'pending'">Pendente</b>
                            <b style="vertical-align: sub" *ngIf="d.status === 'accept'">Aceito</b>
                            <b style="vertical-align: sub" *ngIf="d.status === 'deny'">Cancelado</b>
                            <b style="vertical-align: sub" *ngIf="d.status === 'route'">Em Rota</b>
                            <b style="vertical-align: sub" *ngIf="d.status === 'await'">Aguardando</b>
                            <b style="vertical-align: sub" *ngIf="d.status === 'fail'">Não entregue</b>
                            <b style="vertical-align: sub" *ngIf="d.status === 'delivered'">Entregue</b>
                        </div>
                        <h6 class="m-0 text-truncate col-9 font-weight-bold">{{d.driver?.name}}</h6>
                    </div>
                    <hr class="my-2">
                    <div class="mb-1 table-responsive">
                        <table class="table table-striped">
                            <thead class="text-muted">
                            <tr>
                                <th class="w-25 text-truncate">Marca</th>
                                <th class="w-25 text-truncate text-center">Modelo</th>
                                <th class="w-25 text-truncate text-center">Ano</th>
                                <th class="text-truncate text-center">Placa</th>
                                <th class="text-truncate text-center">Cor</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="text-muted">
                                <td class="w-25 text-truncate">{{d.vehicle?.brand}}</td>
                                <td class="w-25 text-truncate text-center">{{d.vehicle?.model}}</td>
                                <td class="w-25 text-truncate text-center">{{d.vehicle?.year}}</td>
                                <td class="text-truncate text-center">{{d.vehicle?.board}}</td>
                                <td class="text-truncate text-center">{{d.vehicle?.color}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>


<!--Modal-->
<div class="modal-custom modal" id="modalRating" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header bg-primary text-white">
                <h3>AVALIAÇÃO</h3>
                <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <iframe [src]="ratingURL" [frameBorder]="0" width="470px" height="500px"></iframe>
            </div>
        </div>
    </div>
</div>

<div class="modal-custom modal " id="modalFollow" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header bg-primary text-white">
                <h3>ACOMPANHAMENTO</h3>
                <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <iframe [src]="followURL" [frameBorder]="0" width="470px" height="500px"></iframe>
            </div>
        </div>
    </div>
</div>
