import {User} from '../user/user';

export class Contact {

    id: string;
    createdBy: User;
    subject: string;
    message: string;
    status: string;
    answer: string;
    answerAt: string;
    answerUser: string;
    createdAt: Date;
    updatedAt: Date;

    constructor() {
    }
}
