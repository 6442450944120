<div class="mb-3 d-xl-flex align-items-center col-12 p-0" *ngIf="type == 1" style="position: initial !important;">
    <div class="input-material w-100">
        <input class="form-control" type="text" name="text" autocomplete="off" #autoComplete [(ngModel)]="selectName"
               (ngModelChange)="handlerKeyword()" (keyup)="actionAutoComplete($event)"
               *ngIf="!isNullOrUndefined(selectName) && type == 1"/>
        <input class="form-control" type="text" name="text" autocomplete="off" (keyup)="actionAutoComplete($event)" #autoComplete
               [(ngModel)]="keywordName" (blur)="handlerBlur($event)" (ngModelChange)="handlerKeyword()"
               *ngIf="isNullOrUndefined(selectName) && type == 1"/>
        <label>{{title}}</label>
    </div>
    <div class="form-autocomplete" *ngIf="!isList && !isNullOrUndefined(keywordName)">
        <ul>
            <li *ngIf="isNullOrUndefined(list)"><a href="javascript:void('Nenhum registro encontrado');">
                Nenhum registro encontrado.</a></li>
            <li *ngFor="let i of list">
                <a href="javascript:void('Selecionar');" (click)="selectEvent(i)">
          <span *ngFor="let p of param; let paramIndex = index">
            {{i[param[paramIndex]]}} {{ (param.length - 1) === paramIndex ? '' : separator }}
          </span>
                </a>
            </li>
        </ul>
    </div>
</div>

<div class=" d-xl-flex align-items-center p-0" *ngIf="type == 2">
    <div class=" align-items-center w-100">
        <!--    <label class="mb-0 mr-3 d-xl-none">{{title}}</label>-->
        <!--    {{selectName?.name}}-->
        <input class="form-control" type="text" name="selectName" #autoComplete [(ngModel)]="selectName"
               (ngModelChange)="handlerKeyword()" (keyup)="actionAutoComplete($event)"
               *ngIf="!isNullOrUndefined(selectName) && type == 2" placeholder="{{title}}"/>
        <input class="form-control" type="text" name="selectName" (keyup)="actionAutoComplete($event)" #autoComplete
               placeholder="{{title}}"
               [(ngModel)]="keywordName" (blur)="handlerBlur($event)" (ngModelChange)="handlerKeyword()"
               *ngIf="isNullOrUndefined(selectName) && type == 2"/>
    </div>
    <div class="form-autocomplete" *ngIf="!isList && !isNullOrUndefined(keywordName)">
        <ul>
            <li *ngIf="isNullOrUndefined(list)"><a href="javascript:void('Nenhum registro encontrado');">
                Nenhum registro encontrado.</a></li>
            <li *ngFor="let i of list">
                <a href="javascript:void('Selecionar');" (click)="selectEvent(i)">
          <span *ngFor="let p of param; let paramIndex = index">
            {{i[param[paramIndex]]}} {{ (param.length - 1) === paramIndex ? '' : separator }}
          </span>
                </a>
            </li>
        </ul>
    </div>
</div>
