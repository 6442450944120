<section class="container">

    <div class="d-flex align-items-center ml-4 mt-4">
        <h1 class="title ">Saalve Bag<span class="subTitle ml-2"> Pagamento</span></h1>
    </div>

    <div class="card mt-3 bg-white mx-2 p-3">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-12 col-lg-4 col-xl-4">
                    <div class="input-material mb-3 mb-lg-0">
                        <input id="name" class="form-control" type="text" [(ngModel)]="modelFilter.name" required/>
                        <label for="name">Nome</label>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-4 col-xl-4">
                    <div class="input-material mb-3 mb-lg-0">
                        <select id="unity" class="form-control" name="type" [(ngModel)]="modelFilter.status">
                            <option [ngValue]="''">Todos</option>
                            <option [ngValue]="'paid'">Pago</option>
                            <option [ngValue]="'pending'">Aguardando</option>
                        </select>
                        <label for="unity" class="mb-0 mr-3">Status</label>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-4 col-xl-4">
                    <div class="input-material mb-3 mb-lg-0">
                        <input id="period" class="form-control js-daterangepicker" type="text" required/>
                        <label for="period">Período</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-sm-12 text-right">
                    <a href="javascript:void('');" (click)="getExcel()" class="btn btn-ico btn-sm btn-success mr-2 mr-xs-4 mb-3 mb-xs-0">
                        <svg-icon class="svg" src="assets/svg/excel.svg"></svg-icon>
                        Exportar Excel
                    </a>
                    <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mb-3 mb-xs-0"
                       (click)="actionSearch()">
                        <svg-icon class="svg" src="assets/svg/search.svg"></svg-icon>
                        Buscar
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="card mt-3 mx-2 bg-white p-3" style="max-height: 500px; min-height: 500px;">
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th class="align-middle w-5" scope="col">
                        <div class="custom-control custom-checkbox mb-0">
                            <input type="checkbox" class="custom-control-input" id="all"
                                   (click)="handlerSelectPayment(null)" [checked]="handlerPaymentCheckedAll()">
                            <label class="custom-control-label pt-1" style="font-size: 13px;" for="all">Todos</label>
                        </div>
                    </th>
                    <th class="align-middle text-center" scope="col">Status</th>
                    <th class="align-middle" scope="col">Nome</th>
                    <th class="align-middle" scope="col">CPF</th>
                    <th class="align-middle" scope="col">Número do pedido</th>
                    <th class="align-middle text-center" scope="col">Valor<br> entrega</th>
                    <th class="align-middle text-center" scope="col">Valor<br> comissão</th>
                    <th class="align-middle text-center" scope="col">Data da<br> entrega</th>
                    <th class="align-middle text-center" scope="col">Data do<br> pagamento</th>
                    <th class="align-middle text-center" scope="col">Detalhe</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(modelList)">
                    <td colspan="9">
                        <p class="pt-5 pb-5 text-center">
                            Nenhum registro encontrado.
                        </p>
                    </td>
                </tr>
                <tr *ngFor="let p of modelList; let i = index;">
                    <td class="text-center">
                        <div class="custom-control custom-checkbox mb-0" [ngClass]="{'d-none': handlerPaymentDisabled(p.id)}">
                            <input type="checkbox" class="custom-control-input" id="check_{{i}}" (click)="handlerSelectPayment(p.id)"
                                   [checked]="handlerPaymentChecked(p.id)">
                            <label class="custom-control-label" for="check_{{i}}"></label>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="badge px-2 pt-2 pb-1"
                             [ngClass]="{'badge-success': p.paymentStatus === 'paid',
                                        'badge-info': p.paymentStatus === 'pending'}">
                            <b *ngIf="p.paymentStatus === 'paid'">Pago</b>
                            <b *ngIf="p.paymentStatus === 'pending'">Aguardando</b>
                        </div>
                    </td>
                    <td>{{p.driver.name}}</td>
                    <td>{{p.driver.document}}</td>
                    <td class="text-center">{{p.order.orderCode}}</td>
                    <td class="text-center">{{p.order.tax | currency :'BRL': true: '1.2-2': 'pt-BR'}}</td>
                    <td class="text-center">{{p.commission | currency :'BRL': true: '1.2-2': 'pt-BR'}}</td>
                    <td class="text-center">{{p.order.dateTime | date: 'dd/MM/yyyy'}}</td>
                    <td class="text-center">
                        <span *ngIf="!isNullOrUndefined(p.paymentAt)">{{p.paymentAt | date: 'dd/MM/yyyy'}}</span>
                        <span *ngIf="isNullOrUndefined(p.paymentAt)">Aguardando</span>
                    </td>
                    <td class="text-center">
                        <a href="javascript:void('Detalhes');" [routerLink]="['/delivery-detail/' + p.order.id]"
                           class="btn btn-sm btn-primary mb-2">
                            <svg-icon class="svg-secondary" src="assets/svg/eyes.svg"></svg-icon>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="card my-3 mx-2 bg-white p-3">
        <div class="d-flex">
            <div class="col-8 d-flex align-items-center">
                <h5 class="mb-0 text-primary">Total: {{handlerValueSelect() | currency :'BRL': true: '1.2-2': 'pt-BR'}}</h5>
                <div class="mx-3">|</div>
                <h5 class="mb-0 text-primary">Saldo Conta: {{modelBalance.transferableBalance | currency :'BRL': true: '1.2-2': 'pt-BR'}}</h5>
            </div>
            <div class="col-4 text-right">
                <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mb-3 mb-xs-0"
                   (click)="actionPayment()">
                    <svg-icon class="svg-white-path" src="assets/svg/dolar.svg"></svg-icon>
                    <span class="d-none d-sm-block">Efetuar Pagamento</span>
                </a>
            </div>
        </div>
    </div>

</section>
