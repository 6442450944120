<div class="single__page">
  <h1 class="mb-5">
    <img src="assets/images/logo_saalve.png" class="logo" height="250" alt="SAALVE" />
  </h1>
  <p>
    Você não está logado no Saalve Bag.
  </p>
  <p>
    <a href="javascript:void('Login');" [routerLink]="['/authenticate']">Clique aqui para <strong>ENTRAR!</strong></a>
  </p>
</div>
