export class FilterChat {

    name: string;
    store: string;
    status: string;
    start: Date;
    end: Date;

    constructor() {
        this.status = '';
    }
}
