import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent extends BaseComponent implements OnInit {

    @Input() title: string;
    @Input() type = 1;
    @Input() separator: string;
    @Input() param: any;
    @Input() list = [];
    @Output() keyword = new EventEmitter<string>();
    @Output() blur = new EventEmitter<string>();
    @Output() selected = new EventEmitter<void>();


    isList = false;
    keywordName: string;
    @Input() selectName: any;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
    }

    actionAutoComplete(event) {
        // console.log(event);
    }

    isTypeString(item) {
        return typeof item === 'string';
    }

    selectEvent(item) {
        this.selectName = item.name;
        this.isList = true;
        this.keywordName = null;
        this.selected.emit(item);
    }

    handlerKeyword() {
        if (!this.isNullOrUndefined(this.selectName)) {
            this.selectName = null;
            this.selected.emit();
            this.isList = true;
        }
        if (this.keywordName === '' || this.selectName === '') {
            this.keyword.emit(this.keywordName);
            this.selected.emit();
            this.isList = false;
        } else if (!this.isNullOrUndefined(this.keywordName)) {
            this.isList = false;
            this.keyword.emit(this.keywordName);
        }
    }

    handlerBlur(event: any) {
        this.blur.emit(event);
    }
}
