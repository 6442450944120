import {User} from '../user/user';
import {Store} from '../order/store';
import {Order} from '../order/order';
import {Messages} from './messages';

export class Chat {

    id: string;
    message: string;
    status: string;
    createdAt: Date;
    driver: User;
    admin: User;
    store: Store;
    order: Order;
    messages: Messages[];

    constructor() {
        this.driver = new User();
        this.admin = new User();
        this.store = new Store();
        this.order = new Order();
        this.messages = [];
    }
}
