export class DashboardCharts {
    deliveries: Delivery[];
    storeOrders: StoreOrder[];

    constructor() {
        this.deliveries = [];
        this.storeOrders = [];
    }
}

export class Delivery {
    status: string;
    values: number[];
    dates: string[];

    constructor() {
    }
}

export class StoreOrder {
    storeName: string;
    values: number[];
    status: string[];

    constructor() {
    }
}
