import {Store} from './store';

export class FilterOrder {

    keyword: string;
    status: string;
    driver: string;
    orderNumber: number;
    start: Date;
    end: Date;
    storeId: string;
    constructor() {
        this.status = '';
    }
}
