<a  href="javascript:void('chat');" (click)="actionChat()">
    <svg-icon class="bell-icon" src="assets/svg/bell.svg"></svg-icon>
    <a href="javascript:void('sino');" (click)="actionChat()">
        <span class="notification_number" *ngIf="lenght !== 0">{{lenght}}</span>
    </a>
</a>
<div class="header__expanded d-none">
    <ul>
        <li>
            <p><strong>Comercial Local finalizou</strong> Nova</p>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod</p>
        </li>
        <li>
            <p><strong>Comercial Local finalizou</strong> Nova</p>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod</p>
        </li>
        <li>
            <p><strong>Comercial Local finalizou</strong> Nova</p>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod</p>
        </li>
    </ul>
</div>
