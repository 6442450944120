export class SupportsDash {
    notAnswered: number;
    answered: number;
    total: number;

    constructor() {
        this.notAnswered = 0;
        this.answered = 0;
        this.total = 0;
    }
}

export class ChatsDash {
    notAnswered: number;
    answered: number;
    total: number;

    constructor() {
        this.notAnswered = 0;
        this.answered = 0;
        this.total = 0;
    }
}

export class DriversDash {
    active: number;
    blocked: number;
    pending: number;
    waitingAccept: number;

    constructor() {
        this.active = 0;
        this.blocked = 0;
        this.pending = 0;
        this.waitingAccept = 0;
    }
}

export class RatingsDash {
    _1: number;
    _2: number;
    _3: number;
    _4: number;
    _5: number;

    constructor() {
        this._1 = 0;
        this._2 = 0;
        this._3 = 0;
        this._4 = 0;
        this._5 = 0;
    }
}

export class DashboardTotalizer {
    supports: SupportsDash;
    chats: ChatsDash;
    drivers: DriversDash;
    ratings: RatingsDash;

    constructor() {
        this.supports = new SupportsDash();
        this.chats = new ChatsDash();
        this.drivers = new DriversDash();
        this.ratings = new RatingsDash();
    }

}

