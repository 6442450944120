import {Drive} from './drivers';
import {Store} from './store';

export class Map {

  drive: Drive[];
  store: Store[];

  constructor() {
    this.drive = [];
    this.store = [];
  }
}
