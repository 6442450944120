import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {Contact} from '../../model/contact/contact';
import {ResultChat} from '../../model/chat/result-chat';
import {FilterChat} from '../../model/chat/filter-chat';
import {ChatService} from '../../service/chat.service';
import {ResultContact} from '../../model/contact/result-contact';
import {Chat} from '../../model/chat/chat';
import {environment} from '../../../environments/environment';

declare var $: any;
declare var moment;

@Component({
    selector: 'app-driver-chat-history',
    templateUrl: './driver-chat-history.component.html',
    styleUrls: ['./driver-chat-history.component.scss']
})
export class DriverChatHistoryComponent extends BaseComponent implements OnInit {

    modelResult: ResultChat = new ResultChat();
    modelFilter: FilterChat = new FilterChat();
    model: Chat = new Chat();
    currentPage = 1;

    labels: any = {
        previousLabel: ' ',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public chatService: ChatService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.modelFilter.start = moment().subtract(15, 'days');
        this.modelFilter.end = moment();

        this.setDatePicker();
        this.actionSearch();
    }

    setDatePicker() {
        const data = moment().subtract(15, 'days').format('DD/MM/yyyy');
        $('.js-daterangepicker').daterangepicker({
            showDropdowns: true,
            startDate: data,
            locale: {
                format: 'DD/MM/YYYY',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                daysOfWeek: [
                    'Dom',
                    'Seg',
                    'Ter',
                    'Qua',
                    'Qui',
                    'Sex',
                    'Sab'
                ],
                monthNames: [
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                ],
                firstDay: 1
            }
        }, (start, end, label) => {
            this.modelFilter.start = start.utcOffset(0, true).format();
            this.modelFilter.end = end.utcOffset(0, true).format();
        });
    }

    actionSearch() {
        this.loading.show();
        this.chatService.searchHistory(this.modelFilter, this.currentPage).subscribe({
            next: data => {
                this.modelResult = data as ResultChat;
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    pageChanged(event) {
        this.currentPage = event;
        this.actionSearch();
    }

    actionDetail(id) {
        this.loading.show();
        this.chatService.getId(id).subscribe({
            next: data => {
                $('#modalMessageDetail').modal('show');
                this.model = data as Chat;
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }


    getExcel() {
        const token = localStorage.getItem('token') as string;
        const start = moment(this.modelFilter.start).format('YYYY-MM-DD');
        const end = moment(this.modelFilter.end).format('YYYY-MM-DD');

        let url = `${environment.base_url}/chat/history/excel?token=${token}`;

        if (!super.isNullOrUndefined(this.modelFilter.name)) {
            url += ('&name=' + this.modelFilter.name);
        }

        if (!super.isNullOrUndefined(this.modelFilter.store)) {
            url += ('&store=' + this.modelFilter.store);
        }

        if (!super.isNullOrUndefined(this.modelFilter.status)) {
            url += ('&status=' + this.modelFilter.status);
        }

        if (!super.isNullOrUndefined(start)) {
            url += ('&start=' + start);
        }

        if (!super.isNullOrUndefined(end)) {
            url += ('&end=' + end);
        }

        // console.log(encodeURI(url));
        window.open(encodeURI(url));
    }
}
