import {HttpClient} from '@angular/common/http';
import {ServiceBase} from './service.base';
import {Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {BehaviorSubject} from 'rxjs';
import {EmitEvent, EventBusService, Events} from '../service/event-bus.service';

@Injectable({
    providedIn: 'root'
})
export class FireBaseService {

    currentMessage = new BehaviorSubject(null);

    constructor(private angularFireMessaging: AngularFireMessaging,
                private eventbus: EventBusService) {
        this.angularFireMessaging.messages.subscribe({
            next: (messaging: AngularFireMessaging) => {
                console.log(messaging);
                messaging.onMessage = messaging.onMessage.bind(messaging);
                messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
            }
        });
    }

    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe({
            next: token => {
                console.log(token);
                this.receiveMessage();
            }, error: err => console.error('Unable to get permission to notify.', err)
        });
    }

    receiveMessage() {
        console.log('receiveMessage');
        this.angularFireMessaging.messages.subscribe({
            next: payload => {
                console.log(payload);
                // EVENT BUS
                // payload.data.type == 'chat'
                this.eventbus.emit(new EmitEvent(Events.chatMessage, payload));

                this.currentMessage.next(payload);
            }, error: err => console.error('Unable to subscribe to notify.', err)
        });
    }
}
