export class DeliveryResult {
    attempt: number;
    bikes: number;
    time: Time;
    results: Result[];
}

export class Time {
    ticks: number;
    days: number;
    hours: number;
    milliseconds: number;
    minutes: number;
    seconds: number;
    totalDays: number;
    totalHours: number;
    totalMilliseconds: number;
    totalMinutes: number;
    totalSeconds: number;
}

export class Result {
    id: string;
    orderId: string;
    log: string;
    createdAt: Date;
}

