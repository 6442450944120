<section class="container">

    <div class="d-flex align-items-center ml-4 mt-4">
        <h1 class="title ">Saalve Bag<span class="subTitle ml-2"> Entregadores</span></h1>
    </div>

    <div class="card mt-3 bg-white mx-2 p-3">
        <div class="card-body">
            <form autocomplete="off" class="row">
                <div class="col-sm-12 col-lg-6 col-xl-6 col-xxl-3">
                    <div class="input-material">
                        <input id="period" class="form-control js-daterangepicker" type="text" name="date" value="" required/>
                        <label for="period">Período</label>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-6 col-xxl-3">
                    <div class="input-material">
                        <input id="name" class="form-control" type="text" name="name" maxlength="100" required
                               [(ngModel)]="modelFilter.name"/>
                        <label for="name">Nome do Usuário</label>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-6 col-xxl-3">
                    <div class="input-material">
                        <input id="cpf" class="form-control" type="text" maxlength="14" mask="000.000.000-00"
                               [dropSpecialCharacters]="false" name="document"
                               required [(ngModel)]="modelFilter.document"/>
                        <label for="cpf">CPF</label>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-xl-6 col-xxl-3">
                    <div class="input-material">
                        <select id="status" name="status" class="form-control form-control-lg" [(ngModel)]="modelFilter.status">
                            <option [ngValue]="''">Todos</option>
                            <option [value]="'active'">Ativo</option>
                            <option [value]="'fail'">Reprovado</option>
                            <option [value]="'await'">Aguadando aprovação</option>
                            <option [value]="'pending'">Pendente</option>
                        </select>
                        <label for="status">Status</label>
                    </div>
                </div>
            </form>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-sm-12 text-right">
                    <a href="javascript:void('');" (click)="getExcel()"
                       class="btn btn-ico btn-sm btn-success mr-xs-4 mb-3 mb-xs-0">
                        <svg-icon class="svg" src="assets/svg/excel.svg"></svg-icon>
                        Exportar Excel
                    </a>
                    <a href="javascript:void('');" (click)="clearFilter()"
                       class="btn btn-ico btn-sm btn-danger mr-xs-4 mb-3 mb-xs-0">
                        <svg-icon class="svg" src="assets/svg/trash.svg"></svg-icon>
                        Limpar Filtros
                    </a>
                    <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mb-3 mb-xs-0"
                       (click)="actionSearch()">
                        <svg-icon class="svg" src="assets/svg/search.svg"></svg-icon>
                        Buscar
                    </a>
                </div>
            </div>
        </div>
    </div>


    <div class="row mx-0 pt-4">
        <div class="col-12 mt-5" *ngIf="isNullOrUndefined(modelResult.results)">
            <p class="pt-5 pb-5 text-center">
                Nenhum registro encontrado.
            </p>
        </div>

        <div class="col-xl-6 col-xxl-4" *ngFor="let r of modelResult.results |
                    paginate: {
                     itemsPerPage: modelResult.pageSize,
                     currentPage: modelResult.currentPage,
                     totalItems: modelResult.totalCount}">
            <div class="text-center card my-3 p-3">
                <div>
                    <div class="text-right mb-2">
                        <a href="javascript:void('Detalhes');" [routerLink]="['/driver-detail/' + r.id]"
                           class="btn btn-sm btn-primary mr-2">
                            <svg-icon class="svg-secondary" src="assets/svg/eyes.svg"></svg-icon>
                        </a>
                        <a href="javascript:void('Excluir');" (click)="actionDelete(r.id)" class="btn btn-sm btn-danger">
                            <svg-icon class="svg-secondary" src="assets/svg/trash.svg"></svg-icon>
                        </a>
                    </div>

                    <div class="row">
                        <div class="col-3 mb-3 m-sm-0 px-1">
                            <img src="{{isNullOrUndefined(r.photo) ? 'assets/images/no-photo-available.png' : r.photo}}"
                                 class="rounded-circle img-thumbnail mb-2"
                                 alt="profile-image"/>
                            <div class="">
                                <small class="badge pt-2 pb-1 pl-2 pr-2"
                                       [ngClass]="{'badge-success': r.status === 'active',
                                        'badge-danger': r.status === 'blocked'||r.status === 'fail',
                                        'badge-info': r.status === 'await',
                                        'badge-warning': handlerStatusPending(r.status)}">
                                    <b *ngIf="r.status === 'active'">Aprovado</b>
                                    <b *ngIf="r.status === 'fail'">Reprovado</b>
                                    <b *ngIf="r.status === 'blocked'">Bloqueado</b>
                                    <b *ngIf="r.status === 'await'">Aguardando <br><br>Aprovação</b>
                                    <b *ngIf="r.status === handlerStatusPending(r.status)">Pendente</b>
                                </small>

                            </div>
                        </div>
                        <div class="col-sm-9 ">
                            <div class="text-left">
                                <p class="col text-muted pl-0 font-13 mb-0 d-inline-block text-truncate"><strong>Nome
                                    Completo :</strong> <span
                                        class="ml-2 mb-0 ">{{r.name}}</span></p>

                                <p class="text-muted font-13 mb-1"><strong>Telefone :</strong><span
                                        class="ml-2">{{r.phone | mask:'(00) 00000 - 0000 || (00) 0000 - 0000'}}</span>
                                </p>

                                <p class="text-muted font-13 mb-1 text-truncate"><strong>Email :</strong> <span
                                        class="ml-2">{{r.email}}</span></p>

                                <p class="text-muted font-13 mb-1"><strong>CPF :</strong> <span
                                        class="ml-2">{{r.document}}</span></p>
                                <hr>
                                <p class="text-muted font-13 mb-1"><strong>Criado em (Data/Hora):</strong><span
                                        class="ml-2">{{r.createdAt | date:'dd/MM/yyyy HH:mm'}}</span>
                                </p>
                                <p class="text-muted font-13"><strong>Última atualização de posição:</strong><span
                                        class="ml-2">{{r.updatedAt | date:'dd/MM/yyyy HH:mm'}}</span>
                                </p>
                            </div>
                        </div>

                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-xs-6 mb-2 text-left">
                            <h2 class="subTitle size-xm mb-2">
                                Avaliação</h2>
                            <div class="">
                                <p class="text-muted font-13 mb-0"><strong>Nota :</strong> <span
                                        class="ml-2">{{r.rating}}</span></p>
                                <p class="text-muted font-13 mb-0"><strong>Qtde :</strong> <span
                                        class="ml-2">{{r.ratingCount}}</span></p>
                            </div>


                        </div>
                       <!-- <div class="col-xs-6 mb-2 text-left">
                            <h2 class="subTitle size-xm mb-2">
                                Envio de SMS</h2>
                            <div>
                                <p class="text-muted font-13 mb-0"><strong>Qtde :</strong> <span
                                        class="ml-2">{{r.sms}}</span></p>
                                <p class="text-muted font-13 mb-0"><strong>ùltimo código :</strong> <span
                                        class="ml-2">{{r.smsCode}}</span></p>
                            </div>

                        </div>-->
                        <div class="col-xs-6 mb-2 text-left">
                            <h2 class="subTitle size-xm mb-2">
                                Juno</h2>
                            <div>
                                <p class="text-muted font-13 mb-0"><strong>Saldo :</strong> <span
                                        class="ml-2">{{r.balance | currency: 'R$ '}}</span></p>
                                <p class="text-muted font-13 mb-0"><strong>Saldo à liberar :</strong> <span
                                        class="ml-2">{{r.withHeldBalance | currency: 'R$ '}}</span></p>
                                <p class="text-muted font-13 mb-0"><strong>Saldo Disponível :</strong> <span
                                        class="ml-2">{{r.transferableBalance | currency: 'R$ '}}</span></p>
                            </div>

                        </div>


                        <div class="col-xs-6 mb-2 text-left">
                            <h2 class="subTitle size-xm mb-2">
                                Dados do Aparelho</h2>
                            <div>
                                <p class="text-muted text-truncate font-13 mb-0"><strong>SO :</strong> <span
                                        class="ml-2">{{r.platform}}</span></p>
                                <p class="text-muted text-truncate font-13 mb-0"><strong>Aparelho :</strong> <span
                                        class="ml-2">{{r.device}}</span></p>
                            </div>
                        </div>
                        <div class="col-xs-6 mb-2 text-left">
                            <h2
                                    class="subTitle size-xm mb-2">
                                Dados do Aplicativo</h2>
                            <div>
                                <p class="text-muted text-truncate font-13 mb-0"><strong>Versão :</strong> <span
                                        class="ml-2">{{r.version}}</span></p>
                                <p class="text-muted text-truncate font-13 mb-0"><strong>Compilação :</strong> <span
                                        class="ml-2">{{r.build}}</span></p>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="text-center my-4" *ngIf="!isNullOrUndefined(modelResult.results)">
        <pagination-controls
                [previousLabel]="labels.previousLabel"
                [nextLabel]="labels.nextLabel"
                [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                [screenReaderPageLabel]="labels.screenReaderPageLabel"
                [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                (pageChange)="pageChanged($event)"></pagination-controls>
    </div>

</section>

