import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class OrderService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getId(id) {
        const url = `${this.BASE_URL}/order/${id}`;
        return this.http.get(url);
    }


    search(filter, page) {
        const url = `${this.BASE_URL}/order/search/${page}`;
        return this.http.post(url, filter);
    }

    simulation(orderNumber, externalId = '59eef635-b53c-4615-b26b-39f569d1e636') {
        const url = `${this.BASE_URL}/hook/new-order/${externalId}/${orderNumber}`;
        return this.http.post(url, {});
    }

    resetOrder(orderDeliveryId: string) {
        const url = `${this.BASE_URL}/route/reset-order-delivery/${orderDeliveryId}`;
        return this.http.get(url);
    }

    newOrderDelivery(driverId, orderId) {
        const url = `${this.BASE_URL}/route/new-order-delivery/${driverId}/${orderId}`;
        return this.http.get(url);
    }

    resultDelivery(orderId){
        const url = `${this.BASE_URL}/route/router-logs/${orderId}`;
        return this.http.get(url);
    }
}
