import {Order} from "./order";

export class ResultOrder {

  totalCount: number;
  pageSize: number;
  currentPage: number;
  totalPage: number;
  previousPage: number;
  nextPage: number;
  results: Order[];

  constructor() {
    this.results = [];
  }
}
