import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactService extends ServiceBase {

  constructor(http: HttpClient) {
    super(http);
  }

  search(filter, page) {
    const url = `${this.BASE_URL}/support/search/${page}`;
    return this.http.post(url, filter);
  }

  update(model) {
    const url = `${this.BASE_URL}/support`;
    return this.http.put(url, model);
  }
}
