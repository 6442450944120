import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {FilterStore} from '../../model/store/filter-store';
import {StoreService} from '../../service/store.service';
import {ResultStore} from '../../model/store/result-store';
import {Store} from 'src/app/model/store/store';
import {MapsPlaceService} from '../../service/maps-place.service';
import {AddressPlace} from '../../model/addressPlace';
import {OrderService} from '../../service/order.service';

declare var $;
declare var moment;

@Component({
    selector: 'app-store',
    templateUrl: './store.component.html',
    styleUrls: ['./store.component.scss']
})
export class StoreComponent extends BaseComponent implements OnInit {

    modelFilter: FilterStore = new FilterStore();
    modelResult: ResultStore = new ResultStore();

    model: Store = new Store();
    modelListAddress: AddressPlace[] = [];
    modelAddressPlace: AddressPlace = new AddressPlace();
    currentPage = 1;
    labels: any = {
        previousLabel: ' ',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    constructor(public loading: NgxSpinnerService,
                public router: Router,
                public orderService: OrderService,
                public translate: TranslateService,
                public storeService: StoreService,
                public mapsService: MapsPlaceService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.setDatePicker();
        this.actionSearch();
    }

    setDatePicker() {
        const data = moment().subtract(15, 'days');
        // this.modelFilter.start = data;
        // this.modelFilter.end = moment();
        $('.js-daterangepicker').daterangepicker({
            showDropdowns: true,
            startDate: data,
            locale: {
                format: 'DD/MM/YYYY',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                daysOfWeek: [
                    'Dom',
                    'Seg',
                    'Ter',
                    'Qua',
                    'Qui',
                    'Sex',
                    'Sab'
                ],
                monthNames: [
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                ],
                firstDay: 1
            }
        }, (start, end, label) => {
            // this.modelFilter.start = start.utcOffset(0, true).format();
            // this.modelFilter.end = end.utcOffset(0, true).format();
        });
    }

    actionSearch() {
        this.loading.show();
        this.storeService.search(this.modelFilter, this.currentPage).subscribe({
            next: data => {
                this.modelResult = data as ResultStore;
                console.log(this.modelResult);
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    pageChanged(event) {
        this.currentPage = event;
        this.actionSearch();
    }

    handlerDetail(model): void {
        this.model = new Store();
        this.model = Object.assign({}, model);
        this.modelAddressPlace.formatted_address = this.model.address;
        $('#modalUserDetail').modal('show');
    }

    actionRemove(id: string) {

    }

    handlerStatus(event) {
        if (event.target.checked) {
            this.model.status = 'active';
        } else {
            this.model.status = 'inactive';
        }
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.name, text: 'Nome da Loja*,<br>'},
            {value: this.model.address, text: 'Endereço*,<br>'},
            {value: this.model.externalId, text: 'Id Saalve*,<br>'},
            {value: this.model.bagLogin, text: 'Login Saalve*,<br>'},
            {value: this.model.bagPassword, text: 'Senha Saalve*,<br>'}
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        if (this.isNullOrUndefined(this.model.status)) {
            this.model.status = 'inactive';
        }

        if (this.isNullOrUndefined(this.model.id)) {
            this.create();
        } else {
            this.update();
        }
    }

    create() {
        this.loading.show();
        this.storeService.create(this.model).subscribe({
            next: data => {
                this.model = new Store();
                this.actionSearch();
                $('#modalUserDetail').modal('hide');
                super.showMessage('Sucesso', 'Loja cadastrada com sucesso!', 'success');
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    update() {
        this.loading.show();
        this.storeService.update(this.model).subscribe({
            next: data => {
                this.model = new Store();
                this.actionSearch();
                $('#modalUserDetail').modal('hide');
                super.showMessage('Sucesso', 'Loja alterada com sucesso!', 'success');
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    handlerRegister(): void {
        this.model = new Store();
        this.modelAddressPlace = new AddressPlace();
        $('#modalUserDetail').modal('show');
    }

    autocompleteMaps(keyword) {
        if (keyword.length >= 3) {
            this.mapsService.get(keyword).subscribe({
                next: data => {
                    // @ts-ignore
                    this.modelListAddress = data.predictions;
                }, error: err => super.onError(err)
            });
        } else {
            this.modelListAddress = [];
        }
    }

    actionPlaceId(placeId) {
        if (!this.isNullOrUndefined(placeId)) {
            this.mapsService.getPlaceId(placeId.place_id).subscribe({
                next: data => {
                    // @ts-ignore
                    this.modelAddressPlace = data.result;
                    this.model.address = this.modelAddressPlace.formatted_address;
                    this.model.lat = this.modelAddressPlace.geometry.location.lat;
                    this.model.lng = this.modelAddressPlace.geometry.location.lng;
                }, error: err => super.onError(err)
            });
        }
    }

    blurAutocomplete(event: any) {
        if (super.isNullOrUndefined(this.modelListAddress)) {
            event.currentTarget.value = '';
            $(event.currentTarget).parent().next().remove();
            this.model.address = null;
            this.modelAddressPlace.formatted_address = null;
        }
    }
}
