import {BehaviorSubject, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import * as signalR from '@microsoft/signalr';
import {ServiceBase} from '../base/service.base';
import {HttpClient} from '@angular/common/http';
import {ChatSocket} from '../model/chat/chat-socket';

enum HubCommand {
    chatChannel = 'adminChat'
}

@Injectable()
export class HubService extends ServiceBase {
    onNewMessage = new Subject<ChatSocket>();

    connection = new signalR.HubConnectionBuilder()
        .withUrl(environment.base_socket_url + '/hub', {
            skipNegotiation: true,
            /*accessTokenFactory: () => {
                return this.getToken();
            },*/
            transport: signalR.HttpTransportType.WebSockets
        })
        .withAutomaticReconnect()
        .build();

    constructor(http: HttpClient) {
        super(http);

    }

    checkConnection() {
        this.connection.serverTimeoutInMilliseconds = 240000;
        this.connection.start().then(a => {
            this.startHub();
            this.connection.on(HubCommand.chatChannel, (chat) => this.newMessage(chat));
        }).catch(err => {
            console.log(this.connection.state);
            console.log(err);
        });

        this.connection.onreconnected(a => {
            this.startHub();
        });
    }

    startHub() {
        this.connection.invoke('CreateChannel', 'adminChannel').then();
        console.log('hub started');
    }


    connectToChannel() {
        // this.connection.on(HubCommand.chatChannel, (chat) => this.newMessage(chat));
    }

    newMessage(chat: ChatSocket) {
        console.log('newMessage');
        this.onNewMessage.next(chat);
    }
}
