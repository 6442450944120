import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {User} from "../model/user/user";

@Injectable({
  providedIn: 'root'
})
export class VehicleService extends ServiceBase {

  constructor(http: HttpClient) {
    super(http);
  }

  statusUpdate(model) {
    const url = `${this.BASE_URL}/vehicle/${model.id}/status`;
    return this.http.put(url, model);
  }
}
