<nav id="sidebar">
    <ul class="list-unstyled components navbar-nav" id="accordionExample">

        <li class="header__mobile d-xl-none">
            <div class="header d-flex justify-content-center mt-4">
                <div class="header__btn ml-0">
                    <app-notification></app-notification>
                </div>
            </div>
        </li>

        <li class="user d-xl-none">
            <div class="text-center w-100 mb-2">
                <img src="{{!isNullOrUndefined(user.photo) ? user.photo : 'https://via.placeholder.com/30'}}" alt="">
            </div>
            <span> <strong>{{user.name}}</strong></span>
        </li>

        <ng-container *ngIf="user.profile === 'attendance';">
        </ng-container>
        <li *ngIf="user.profile === 'admin' " class="nav-item" [class.active]="handlerActiveItem('/')">
            <a href="javascript:void('Nota');" [routerLink]="['/']" class="nav-link"
               (click)="actionCloseCollapse()">
                <span class="ico"><svg-icon src="assets/svg/graficoicon.svg"></svg-icon></span>
                <span class="lbl"> Dashboard</span>
            </a>
        </li>
        <li *ngIf="user.profile === 'admin' " class="nav-item"
            [class.active]="handlerActiveItem('/drivers', '/driver-detail')">
            <a href="javascript:void('Nota');" [routerLink]="['/drivers']" class="nav-link"
               (click)="actionCloseCollapse()">
                <span class="ico"><svg-icon src="assets/svg/pessoasicon.svg"></svg-icon></span>
                <span class="lbl"> Entregadores</span>
            </a>
        </li>
        <li class="nav-item" [class.active]="handlerActiveItem('/delivery', '/delivery-detail')">
            <a href="javascript:void('Nota');" [routerLink]="['/delivery']" class="nav-link"
               (click)="actionCloseCollapse()">
                <span class="ico"><svg-icon src="assets/svg/motoicon.svg"></svg-icon></span>
                <span class="lbl"> Delivery</span>
            </a>
        </li>
        <li *ngIf="user.profile === 'admin' " class="nav-item" [class.active]="handlerActiveItem('/driver-payment')">
            <a href="javascript:void('Nota');" [routerLink]="['/driver-payment']" class="nav-link"
               (click)="actionCloseCollapse()">
                <span class="ico"><svg-icon src="assets/svg/money.svg"></svg-icon></span>
                <span class="lbl">Pagamento</span>
            </a>
        </li>
        <li *ngIf="user.profile === 'admin' " class="nav-item" [class.active]="handlerActiveItem('/map')">
            <a href="javascript:void('map');" [routerLink]="['/map']" class="nav-link"
               (click)="actionCloseCollapse()">
                <span class="ico"><svg-icon src="assets/svg/map.svg"></svg-icon></span>
                <span class="lbl">Mapa de Entregadores</span>
            </a>
        </li>
        <li class="nav-item" [class.active]="handlerActiveItem('/contact-us')">
            <a href="javascript:void('Nota');" [routerLink]="['/contact-us']" class="nav-link"
               (click)="actionCloseCollapse()">
                <span class="ico"><svg-icon src="assets/svg/telefoneicon.svg"></svg-icon></span>
                <span class="lbl"> Atendimento</span>
            </a>
        </li>
        <li class="nav-item" [class.active]="handlerActiveItem('/driver-chat', '/driver-chat-history')">
            <a href="javascript:void('Nota');" [routerLink]="['/driver-chat']" class="nav-link"
               (click)="actionCloseCollapse()">
                <span class="ico"><svg-icon src="assets/svg/chaticon.svg"></svg-icon></span>
                <span class="lbl"> Chat Entregador</span>
            </a>
        </li>
        <li *ngIf="user.profile === 'admin' " class="nav-item" [class.active]="handlerActiveItem('/term')">
            <a href="javascript:void('Nota');" [routerLink]="['/term']" class="nav-link"
               (click)="actionCloseCollapse()">
                <span class="ico"><svg-icon src="assets/svg/clip.svg"></svg-icon></span>
                <span class="lbl">Termos de Uso</span>
            </a>
        </li>
        <li *ngIf="user.profile === 'admin' " class="nav-item" [class.active]="handlerActiveItem('/store')">
            <a href="javascript:void('Nota');" [routerLink]="['/store']" class="nav-link"
               (click)="actionCloseCollapse()">
                <span class="ico"><svg-icon src="assets/svg/store.svg"></svg-icon></span>
                <span class="lbl"> Lojas</span>
            </a>
        </li>
        <li *ngIf="user.profile === 'admin' " class="nav-item" [class.active]="handlerActiveItem('/users')">
            <a href="javascript:void('Nota');" [routerLink]="['/users']" class="nav-link"
               (click)="actionCloseCollapse()">
                <span class="ico"><svg-icon src="assets/svg/adminicon.svg"></svg-icon></span>
                <span class="lbl"> Usuários Admin</span>
            </a>
        </li>
        <div class="bt-2">
            <p class="text-center text-primary">
                Versão: {{version}}
            </p>
        </div>

        <!--<li class="nav-item report-ts">
          <a href="javascript:void('Relatórios');" class="nav-link" data-toggle="collapse"
             data-target="#reports" aria-expanded="false"
             aria-controls="reports">
            <span class="ico"><svg-icon src="assets/svg/file-double.svg"></svg-icon></span>
            <span class="lbl"> Relatórios</span>
          </a>
          <div class="collapse" id="reports" data-parent="#accordionExample">
            <a href="javascript:void('Calibração');" [routerLink]="['/calibration']"
               class="collapse-item cursor text-white"><span class="ico empty"></span> Calibração</a>
            <a href="javascript:void('Motores');" [routerLink]="['/engines']" class="collapse-item cursor text-white">
              <span class="ico empty"></span>
              Motores</a>
          </div>
        </li>
        <li class="nav-item help-ts">
          <a href="javascript:void('Ajuda');" class="nav-link" data-toggle="collapse"
             data-target="#help" aria-expanded="false"
             aria-controls="help">
            <span class="ico"><svg-icon src="assets/svg/interrogation-circle.svg"></svg-icon></span>
            <span class="lbl"> Ajuda</span>
          </a>
          <div class="collapse" id="help" data-parent="#accordionExample">
            <a href="javascript:void('Treinamento');" [routerLink]="['/training']"
               class="collapse-item cursor text-white"><span class="ico empty"></span> Treinamento</a>
            <a href="javascript:void('Dúvidas');" [routerLink]="['/faq']" class="collapse-item cursor text-white"><span class="ico empty"></span>
              Dúvidas</a>
          </div>
        </li>-->
    </ul>
    <div class="logout d-lg-none">
        <a href="javascript:void('sair');" (click)="onLogout()">
            Sair
        </a>
    </div>

</nav>
