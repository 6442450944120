import {Component, OnInit} from '@angular/core';

declare var $;

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    step = 1;

    constructor() {
    }

    ngOnInit(): void {
        this.blockClickStep();
    }


    blockClickStep() {
        $('.nav-link').on('click', (e) => {
            if (!$(e.target).hasClass('complete')) {
                // console.log('block');
                e.preventDefault();
                e.stopImmediatePropagation();
            } else {
                // console.log('click');
            }
        });
    }

    // tslint:disable-next-line:typedef
    handlerNext(e) {
        this.step = e;

        // console.log(e);
        switch (e) {
            case 0:
                $('.nav_step').css({
                    background: 'white'
                });
                $('.nav a:eq(0)').tab('show');
                break;
            case 1:
                $('.nav_step').css({
                    background: 'linear-gradient(90deg, #FAE100  27%, #FFFFFF 27%)'
                });
                $('.nav a:eq(1)').tab('show');
                break;
            case 2:
                $('.nav_step').css({
                    background: 'linear-gradient(90deg, #FAE100  50%, #FFFFFF 27%)'
                });
                $('.nav a:eq(2)').tab('show');
                break;
            case 3:
                $('.nav_step').css({
                    background: 'linear-gradient(90deg, #FAE100 73%, #FFFFFF 27%)'
                });
                $('.nav a:eq(3)').tab('show');
                break;
            case 4:
                $('.nav_step').css({
                    background: 'linear-gradient(90deg, #FAE100 100%, #FFFFFF 27%)'
                });
                $('.nav a:eq(4)').tab('show');
                break;
            default:


        }

    }

    handlerBack(step) {
        const val = step - 1;
        this.handlerNext(val);
    }

    handlerConfirm() {
        $('#modalNoteResume').modal('show');
    }
}
