<section class="container">

    <div class="d-sm-flex align-items-center justify-content-between mt-4 mx-4">
        <h1 class="title ">Saalve Bag<span class="subTitle ml-2"> Usuários Admin</span></h1>
        <a href="javascript:void (0)" (click)="handlerRegister()" class="btn btn-success btn-ico btn-sm">
            <svg-icon class="svg" src="assets/svg/plus-circle.svg"></svg-icon>
            Novo Usuário
        </a>
    </div>

    <div class="card mt-3 bg-white mx-2 p-3">
        <div class="card-body">
            <form autocomplete="off" class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="input-material">
                        <input id="name" class="form-control" autocomplete="off" type="text" maxlength="200" required
                               [(ngModel)]="modelFilter.name"/>
                        <label for="name">Nome</label>
                    </div>
                </div>
                <!--<div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="input-material">
                        <input id="email" class="form-control" type="text" maxlength="200" required
                               [(ngModel)]="modelFilter.keyword"/>
                        <label for="email">E-mail</label>
                    </div>
                </div>-->
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="input-material">
                        <select id="unity" class="form-control" name="type" [(ngModel)]="modelFilter.status">
                            <option selected [ngValue]="''">Todos</option>
                            <option [ngValue]="'active'">Ativo</option>
                            <option [ngValue]="'inactive'">Inativo</option>
                        </select>
                        <label for="unity" class="mb-0 mr-3">Status</label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="input-material">
                        <select id="profile-filter" class="form-control" name="type" [(ngModel)]="modelFilter.profile">
                            <option selected [ngValue]="'admin-attendance'">Todos</option>
                            <option [ngValue]="'admin'">Admin</option>
                            <option [ngValue]="'attendance'">Atendimento</option>
                        </select>
                        <label for="profile-filter" class="mb-0 mr-3">Perfil</label>
                    </div>
                </div>
            </form>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-sm-12 text-right">
                    <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mb-3 mb-xs-0"
                       (click)="actionSearch()">
                        <svg-icon class="svg" src="assets/svg/search.svg"></svg-icon>
                        Buscar
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="card mt-3 mx-2 bg-white p-3">
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th class="align-middle" scope="col">Nome</th>
                    <th class="align-middle" scope="col">E-mail</th>
                    <th class="align-middle" scope="col">Status</th>
                    <th class="align-middle" scope="col">Perfil</th>
                    <th class="align-middle w-10" scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(modelResult.results)">
                    <td colspan="4">
                        <p class="pt-5 pb-5 text-center">
                            Nenhum registro encontrado.
                        </p>
                    </td>
                </tr>
                <tr *ngFor="let r of modelResult.results |
                    paginate: {
                     itemsPerPage: modelResult.pageSize,
                     currentPage: modelResult.currentPage,
                     totalItems: modelResult.totalCount}">
                    <td>{{r.name}}</td>
                    <td>{{r.email}}</td>
                    <td>
                        <span *ngIf="r.status == 'active'">Ativo</span>
                        <span *ngIf="r.status !== 'active'">Inativo</span>
                    </td>
                    <td>
                        <span *ngIf="r.profile == 'admin'">Admin</span>
                        <span *ngIf="r.profile !== 'admin'">Atendente</span>
                    </td>
                    <td class="text-center d-grid">
                        <a href="javascript:void('DETAIL');" (click)="handlerDetail(r)"
                           class="btn btn-sm btn-info mb-2">
                            <svg-icon class="svg-secondary" src="assets/svg/edit.svg"></svg-icon>
                        </a>
                        <a href="javascript:void('DELETE');" (click)="actionRemove(r.id)" class="btn btn-sm btn-danger">
                            <svg-icon class="svg-secondary" src="assets/svg/trash.svg"></svg-icon>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-center">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</section>

<!--Modal-->
<div class="modal-custom modal" id="modalUserDetail" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-0">
            <div class="modal-header bg-primary text-white">
                <h3 *ngIf="isNullOrUndefined(model.id)">USUÁRIO - CADASTRO</h3>
                <h3 *ngIf="!isNullOrUndefined(model.id)">USUÁRIO - DETALHE</h3>
                <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row mt-5">
                    <div class="col-md-12 col-lg-8 offset-lg-2">
                        <div class="input-material">
                            <input id="addUser" class="form-control" type="text" required maxlength="200"
                                   [(ngModel)]="model.name"/>
                            <label for="addUser">Nome do Usuário</label>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-8 offset-lg-2">
                        <div class="input-material">
                            <input id="addEmail" class="form-control" type="text" required maxlength="200"
                                   [(ngModel)]="model.email"/>
                            <label for="addEmail">Email</label>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-8 offset-lg-2">
                        <div class="input-material">
                            <select id="profile" class="form-control" name="type" [(ngModel)]="model.profile">
                                <option selected [ngValue]="undefined">Selecione</option>
                                <option [ngValue]="'admin'">Admin</option>
                                <option [ngValue]="'attendance'">Atendente</option>
                            </select>
                            <label for="profile" class="mb-0 mr-3">Perfil</label>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-8 offset-lg-2 mb-3">
                        <div class="bg-secondary p-2 rounded">
                            <div class="custom-control custom-switch mb-0">
                                <input type="checkbox" class="custom-control-input" id="customSwitch1"
                                       [checked]="model.status == 'active'"
                                       (change)="handlerStatus($event)">
                                <label class="custom-control-label" for="customSwitch1">Status:
                                    <span class="ml-1" *ngIf="model.status == 'active'"> Ativo</span>
                                    <span class="ml-1" *ngIf="model.status !== 'active'"> Inativo</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="isNullOrUndefined(model.id)">
                        <div class="col-md-12 col-lg-8 offset-lg-2">
                            <div class="input-material">
                                <input id="addPassword" class="form-control" type="password" required maxlength="200"
                                       [(ngModel)]="model.password"/>
                                <label for="addPassword">Senha</label>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-8 offset-lg-2">
                            <div class="input-material">
                                <input id="confirmPassword" class="form-control" type="password" required
                                       maxlength="200"
                                       [(ngModel)]="confirmPassword"/>
                                <label for="confirmPassword">Confirme Senha</label>
                            </div>
                        </div>
                    </ng-container>
                    <div class="col-md-12 col-lg-8 offset-lg-2">
                        <div class="mx-auto">
                            <a href="javascript:void(0)"
                               (click)="actionSave()"
                               class="btn btn-block btn-save mt-4 mb-5 px-5"
                               type="button">Salvar</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>



