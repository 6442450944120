import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {HubService} from '../../service/hub.service';
import {ResultChat} from '../../model/chat/result-chat';
import {ChatService} from '../../service/chat.service';
import {FilterChat} from '../../model/chat/filter-chat';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent extends BaseComponent implements OnInit {

    modelResult: ResultChat = new ResultChat();
    modelFilter: FilterChat = new FilterChat();
    lenght = 0;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public hubService: HubService,
                public chatService: ChatService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.actionSearch();
        this.hubService.onNewMessage.subscribe({
            next: data => {
                console.log(data);
                this.actionSearch();
            }
        });
    }

    actionSearch() {
        this.modelFilter.status = 'open';
        this.loading.show();
        this.chatService.search(this.modelFilter, 1).subscribe({
            next: data => {
                this.modelResult = data as ResultChat;
                const array =  this.modelResult.results;
                this.lenght = array.length;
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    actionChat() {
        this.lenght = 0;
        this.router.navigate(['/driver-chat']);
    }
}
