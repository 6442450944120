import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';
import {EventBusService, Events} from './event-bus.service';

@Injectable({
    providedIn: 'root'
})
export class ChatService extends ServiceBase {

    constructor(http: HttpClient,
                private eventbus: EventBusService) {
        super(http);
        this.eventbus.on(Events.chatMessage, (data) => console.log(data));
    }

    getId(id) {
        const url = `${this.BASE_URL}/chat/${id}`;
        return this.http.get(url);
    }

    search(filter, page) {
        const url = `${this.BASE_URL}/chat/search/${page}`;
        return this.http.post(url, filter);
    }

    searchHistory(filter, page) {
        const url = `${this.BASE_URL}/chat/history/${page}`;
        return this.http.post(url, filter);
    }

    send(model, id) {
        const url = `${this.BASE_URL}/chat/${id}`;
        return this.http.post(url, model);
    }

    messages(id: string, period: string, date: string) {
        const url = `${this.BASE_URL}/chat/${id}/${period}/${date}`;
        return this.http.get(url);
    }

    statusChat(id, status) {
        const url = `${this.BASE_URL}/chat/update-status/${id}/${status}`;
        return this.http.post(url, {});
    }
}
