import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import * as moment from 'moment';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';

declare var $: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  request = {
    id: '',
    password: '',
    newpassword: '',
    confirmPassword: ''
  };

  constructor(public router: Router,
              public loading: NgxSpinnerService,
              public translate: TranslateService,
              public userService: UserService) {
    super(router, loading, translate);
  }

  ngOnInit(): void {
    moment.locale('pt-br');

    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('background-login');

    this.userService.getMe();
    this.userService.user.subscribe({
      next: data => {
        this.request.id = data.id;
      }
    });
  }

  ngAfterViewInit(): void {
    $(document).on('show.bs.modal', '.modal', function() {
      $(this).appendTo('body');
    });
  }

  ngOnDestroy(): void {
    const elModalRemove = $('#changePassword');
    if (elModalRemove[0]) {
        elModalRemove.remove();
    }
  }

  onValidateNewPassword() {
    if (this.isNullOrUndefined(this.request.password)) {
      super.showMessage('Atenção', 'Preencha os campos corretamente.',  'warning');
      return;
    }

    if (this.request.newpassword.length < 6) {
      super.showMessage('Atenção', 'Sua senha deve conter no mínimo 6 caracteres.',  'warning');
      return;
    }
    if (this.request.newpassword !== this.request.confirmPassword) {
      super.showMessage('Atenção', 'As senhas não são idênticas.', 'warning');
      return;
    }

    this.loading.show();
    this.userService.passwordUpdate(this.request).subscribe({
      next: data => {
        super.showMessage('Sucesso', 'Senha alterada com sucesso!',  'success');
        $('#changePassword').modal('hide');
        this.loading.hide();
      }, error: (error) => super.onError(error)
    });
  }

}
