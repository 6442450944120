import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {User} from '../model/user/user';

@Injectable({
    providedIn: 'root'
})
export class UserService extends ServiceBase {

    private userDataSource = new BehaviorSubject<User>(new User());
    user = this.userDataSource.asObservable();

    constructor(http: HttpClient) {
        super(http);
    }

    getAuthenticate(model) {
        const url = `${this.BASE_URL}/user/authenticate`;
        return this.http.post(url, model);
    }

    getMe() {
        const url = `${this.BASE_URL}/user/me`;
        return this.http.get(url).subscribe({
            next: (data: User) => {
                localStorage.setItem('profile', data.profile);
                this.userDataSource.next(data as User);
            }
        });
    }

    search(filter, page) {
        const url = `${this.BASE_URL}/user/search/${page}`;
        return this.http.post(url, filter);
    }

    forgot(model) {
        const url = `${this.BASE_URL}/user/forgot`;
        return this.http.post(url, model);
    }

    getById(id) {
        const url = `${this.BASE_URL}/user/${id}`;
        return this.http.get(url);
    }

    statusUpdate(model) {
        const url = `${this.BASE_URL}/user/${model.id}/status`;
        return this.http.put(url, model);
    }

    passwordUpdate(model) {
        const url = `${this.BASE_URL}/user/password-update`;
        return this.http.put(url, model);
    }

    updateUser(user: User) {
        this.userDataSource.next(user);
    }

    validate(id) {
        const url = `${this.BASE_URL}/user/validate-reset-token/${id}`;
        return this.http.get(url);
    }

    reset(data) {
        const url = `${this.BASE_URL}/user/reset-password`;
        return this.http.post(url, data);
    }

    delete(id) {
        const url = `${this.BASE_URL}/user/${id}`;
        return this.http.delete(url);
    }

    // USER TAX

    getTax(id) {
        const url = `${this.BASE_URL}/user/${id}/delivery-areas`;
        return this.http.get(url);
    }

    createTax(id, model) {
        const url = `${this.BASE_URL}/user/${id}/delivery-areas`;
        return this.http.post(url, model);
    }

    deleteTax(id, taxId) {
        const url = `${this.BASE_URL}/user/${id}/delivery-areas/${taxId}`;
        return this.http.delete(url);
    }

    blockedTax(id) {
        const url = `${this.BASE_URL}/user/block/${id}`;
        return this.http.post(url, '{}');
    }

    availableDrivers(orderId) {
        const url = `${this.BASE_URL}/user/available-drivers/${orderId}`;
        return this.http.get(url);
    }

    getBalance() {
        const url = `${this.BASE_URL}/user/get-balance`;
        return this.http.get(url);
    }

    availableDriverStatus(id) {
        const url = `${this.BASE_URL}/user/change-status-offline/${id}`;
        return this.http.post(url, {});
    }

    // USER ADMIN

    create(model) {
        const url = `${this.BASE_URL}/admin/user`;
        return this.http.post(url, model);
    }

    update(model) {
        const url = `${this.BASE_URL}/admin/user`;
        return this.http.put(url, model);
    }

    deleteAdmin(id) {
        const url = `${this.BASE_URL}/admin/user/${id}`;
        return this.http.delete(url);
    }

}
