export class AddressPlace {

  place_id: string;
  description: string;
  structured_formatting: AddressResp;
  geometry: AddressResp;
  formatted_address: string;
  address_components: AddressResp[];

  constructor() {
    this.structured_formatting = new AddressResp();
    this.address_components = [];
    this.geometry = new AddressResp();
  }
}

export class AddressResp {

  main_text: string;
  secondary_text: string;
  long_name: string;
  short_name: string;
  location: AddressResp;
  lat: number;
  lng: number;

  constructor() {
  }
}


