<div class="container">
    <!-- Outer Row -->
    <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-12 col-md-9">
            <div class="card o-hidden border-0 shadow-lg my-5">
                <div class="card-body p-0">
                    <!-- Nested Row within Card Body -->
                    <div class="row">
                        <div class="col-lg-6 d-none d-lg-block">
                            <div class="text-center">
                                <img src="assets/images/logo_laranja.png" class="logo" alt="login">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="p-5">
                                <div class="text-center">
                                    <h1 class="h4 text-gray-900 mb-2">Digite sua nova senha</h1>
                                    <p class="mb-4">Basta digitar sua nova senha abaixo e prosseguir com a navegação!</p>
                                </div>
                                <form class="user">
                                    <div class="form-group mb-4">
                                        <input class="form-control form-control-user" type="password"
                                               autocomplete="off" placeholder="Senha"
                                               name="password" maxlength="100" #pass>
                                    </div>
                                    <div class="form-group mb-5">
                                        <input  class="form-control form-control-user" type="password" autocomplete="off"
                                                placeholder="Confirmar Senha" name="confirmPassword" #confirmPass>
                                    </div>
                                    <hr>
                                    <button type="submit" class="btn btn-primary btn-user btn-block"
                                            (click)="reset(pass.value, confirmPass.value)">
                                        Redefinir Senha
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
