import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {FilterUser} from '../../model/user/filter-user';
import {ResultUser} from '../../model/user/result-user';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {environment} from '../../../environments/environment';

declare var $;
declare var moment;

@Component({
    selector: 'app-drivers',
    templateUrl: './drivers.component.html',
    styleUrls: ['./drivers.component.scss']
})
export class DriversComponent extends BaseComponent implements OnInit {

    modelFilter: FilterUser = new FilterUser();
    modelResult: ResultUser = new ResultUser();

    currentPage = 1;
    labels: any = {
        previousLabel: ' ',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public userService: UserService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.setDatePicker();
        this.modelFilter.profile = 'driver';
        this.actionSearch();
    }

    setDatePicker() {
        // const data = moment().subtract(30, 'days');
        // this.modelFilter.start = data;
        // this.modelFilter.end = moment();
        $('.js-daterangepicker').daterangepicker({
            showDropdowns: true,
            autoUpdateInput: false,
            // startDate: data,
            locale: {
                format: 'DD/MM/YYYY',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                daysOfWeek: [
                    'Dom',
                    'Seg',
                    'Ter',
                    'Qua',
                    'Qui',
                    'Sex',
                    'Sab'
                ],
                monthNames: [
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                ],
                firstDay: 1
            }
        }, (start, end, label) => {
            this.modelFilter.start = start.utcOffset(0, true).format();
            this.modelFilter.end = end.utcOffset(0, true).format();
        });
        $('.js-daterangepicker').on('apply.daterangepicker', function(ev, picker) {
            $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
        });
    }

    pageChanged(event) {
        this.currentPage = event;
        this.actionSearch();
    }

    actionSearch() {
        this.loading.show();
        this.userService.search(this.modelFilter, this.currentPage).subscribe({
            next: data => {
                this.modelResult = data as ResultUser;
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;
        const start = moment(this.modelFilter.start).format('YYYY-MM-DD');
        const end = moment(this.modelFilter.end).format('YYYY-MM-DD');

        let url = `${environment.base_url}/user/search/excel?token=${token}`;

        if (!super.isNullOrUndefined(this.modelFilter.name)) {
            url += ('&name=' + this.modelFilter.name);
        }

        if (!super.isNullOrUndefined(this.modelFilter.document)) {
            url += ('&document=' + this.modelFilter.document);
        }

        if (!super.isNullOrUndefined(this.modelFilter.status)) {
            url += ('&status=' + this.modelFilter.status);
        }

        if (!super.isNullOrUndefined(this.modelFilter.profile)) {
            url += ('&profile=' + this.modelFilter.profile);
        }

        if (!super.isNullOrUndefined(start)) {
            url += ('&start=' + start);
        }

        if (!super.isNullOrUndefined(end)) {
            url += ('&end=' + end);
        }
        // console.log(encodeURI(url));
        window.open(encodeURI(url));
    }

    handlerStatusPending(status) {
        if (status === 'pending' || status === 'pending-license'
            || status === 'pending-license-check' || status === 'pending-criminal'
            || status === 'pending-vehicle' || status === 'pending-bank') {
            return true;
        }
        return false;
    }

    actionDelete(id: string) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar esse entregador ?', () => {
            this.loading.show();
            this.userService.delete(id).subscribe({
                next: data => {
                    this.actionSearch();
                    this.loading.hide();
                    this.showMessage('Sucesso', 'Entregador deletado com sucesso !', 'success');
                }, error: err => super.onError(err)
            });
        });
    }

    clearFilter() {
        this.modelFilter = new FilterUser();
        this.modelFilter.status = '';
        this.modelFilter.profile = 'driver';
        this.setDatePicker();
        $('.js-daterangepicker').val('');
        this.actionSearch();
    }
}
