import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {
    ApexAxisChartSeries,
    ApexChart,
    ChartComponent,
    ApexDataLabels,
    ApexPlotOptions,
    ApexYAxis,
    ApexLegend,
    ApexStroke,
    ApexXAxis,
    ApexFill,
    ApexTooltip
} from 'ng-apexcharts';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {DashboardService} from '../../service/dashboard.service';
import {DashboardTotalizer} from '../../model/dashboard/dashboard-totalizer';
import {DashboardCharts} from '../../model/dashboard/dashboard-charts';

declare var $;
declare var moment;

export type chartOptionsColumns = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    plotOptions: ApexPlotOptions;
    yAxis: ApexYAxis;
    xAxis: ApexXAxis;
    fill: ApexFill;
    tooltip: ApexTooltip;
    stroke: ApexStroke;
    legend: ApexLegend;
};

export type chartOptionsLine = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xAxis: ApexXAxis;
    stroke: ApexStroke;
    dataLabels: ApexDataLabels;
    markers: ApexMarkers;
    colors: string[];
    yAxis: ApexYAxis;
    grid: ApexGrid;
    legend: ApexLegend;
    title: ApexTitleSubtitle;
};

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit, AfterViewInit {
    @ViewChild('chart') chart: ChartComponent;
    public chartOptionsColumns: Partial<chartOptionsColumns>;
    public chartOptionsLine: Partial<chartOptionsLine>;
    modelTotalizer: DashboardTotalizer = new DashboardTotalizer();
    modelChart: DashboardCharts = new DashboardCharts();

    chartOptionsColumnsCustom = [];

    filter = {
        start: moment().subtract(15, 'days').utcOffset(0, true).format(),
        end: moment().utcOffset(0, true).format()
    };

    constructor(public router: Router,
                public dashboardService: DashboardService,
                public loading: NgxSpinnerService,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        // console.log('ngOnInit');
        this.setDatePicker();
        this.getTotalizer();
        this.getDahsboardChart();
    }

    ngAfterViewInit(): void {
        // console.log('AfterViewInit');
    }

    setDatePicker() {
        const data = this.isNullOrUndefined(this.filter.start) ? moment().format('DD/MM/yyyy') : moment(this.filter.start).format('DD/MM/yyyy');
        $('.js-daterangepicker').daterangepicker({
            showDropdowns: true,
            startDate: data,
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.filter.start = start.utcOffset(0, true).format();
            this.filter.end = end.utcOffset(0, true).format();
        });
    }

    getTotalizer() {
        this.loading.show();
        this.dashboardService.totalizer(this.filter).subscribe({
            next: data => {
                this.modelTotalizer = data as DashboardTotalizer;
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    getDahsboardChart() {
        this.loading.show();
        this.dashboardService.charts(this.filter).subscribe({
            next: data => {
                this.modelChart = data as DashboardCharts;

                this.chartOptionsColumns = {
                    series: [],
                    chart: {
                        type: 'bar',
                        height: 300,
                        id: 'chartColumn'
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xAxis: {
                        categories: this.chartOptionsColumnsCustom
                    },
                    yAxis: {
                        title: {
                            text: 'Pedidos'
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                };

                this.chartOptionsLine = {
                    series: this.makeDataLine(),
                    chart: {
                        height: 250,
                        type: 'line',
                        id: 'chartLine',
                        zoom: {
                            enabled: false,
                        },
                        dropShadow: {
                            enabled: true,
                            color: '#000',
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 0.2
                        },
                        toolbar: {
                            show: false
                        }
                    },
                    colors: ['#77B6EA', '#545454'],
                    dataLabels: {
                        enabled: true
                    },
                    stroke: {
                        curve: 'straight',
                    },
                    grid: {
                        borderColor: '#e7e7e7',
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        }
                    },
                    markers: {
                        size: 1
                    },
                    xAxis: {
                        categories: this.makeDataLineCategories(),
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'right',
                        floating: true,
                        offsetY: -25,
                        offsetX: -5
                    }
                };

                this.makeDataColumns();
                // this.makeDataLine();
                this.updateQuarterChartCategories('chartLine');
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    makeDataColumns() {
        let arr = [];
        const finish = [];
        const open = [];

        // tslint:disable-next-line:forin
        for (const array in this.modelChart.storeOrders) {
            finish.push(this.modelChart.storeOrders[array].values[0]);
            open.push(this.modelChart.storeOrders[array].values[1]);
            this.chartOptionsColumnsCustom.push(this.modelChart.storeOrders[array].storeName);
        }

        arr = [
            {
                name: 'Entregues',
                data: finish,
                color: '#1b2e5b'
            },
            {
                name: 'Cancelado',
                data: open,
                color: '#fae100'
            },
        ];
        this.chartOptionsColumns.series = arr;
    }

    makeDataLine() {
        const arr = [];
        // tslint:disable-next-line:forin
        for (const array in this.modelChart.deliveries) {
            arr.push({
                name: this.modelChart.deliveries[array].status,
                data: this.modelChart.deliveries[array].values,
            });
        }
        return arr;
    }

    makeDataLineCategories() {
        let arr = [];

        for (const [index, item] of this.modelChart.deliveries.entries()) {
            if (index === 0) {
                arr = Object.assign([], item.dates);
            }
        }
        return arr;
    }

    updateQuarterChartCategories(destChartIDToUpdate) {
        return window.ApexCharts.exec(destChartIDToUpdate, 'updateOptions', {
            series: this.makeDataLine(),
            xAxis: {categories: this.makeDataLineCategories()}
        });
    }

}
