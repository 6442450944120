export class Term {

    id: string;
    content: string;
    type: string;
    createdAt: string;
    deletedAt: string;

    constructor() {
        this.type = '';
    }
}
